.lnb{
    h2{
        height: 150px;
        padding-top: 35px;
        text-align: center;
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -1.25px;
        line-height: 1;
        border-radius: 4px;
        color: $white;
        background: url(/static/kogl_alright/img/common/lnb-bg.png) 0 0 no-repeat;
    }
    &__menu{
        margin-bottom: 50px;
        >li{
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: 39px;
                left: 10px;
                width: 5px;
                height: 5px;
                border-radius: 100%;
                background-color: #c1c1c1;
            }
            &.active {
                &::before {background-color: $point2;}
                >a{
                    border-bottom-width: 3px;
                    border-bottom-color: #e65a1d;
                    color: $point2;
                }
                .depth2 {
                    padding: 10px 0;
                    &::before {
                        content: '';
                        color: #e65a1d;
                    }
                    >li {
                        display: block;
                    }
                }
            }
            >a{
                height: 79px;
                line-height: 79px;
                display: block;
                padding-left: 35px;
                font-size: 20px;
                color: #222;
                font-weight: 400;
                letter-spacing: -0.45px;
                border-bottom: 1px solid #ddd;
            }
            .depth2 {
                position: relative;
                background-color: #f6f6f6;
                &::before { 
                    @include la;
                    content: '';
                    color: #c1c1c1;
                    font-size: $body-font-size;
                    position: absolute;
                    right: 28px;
                    top: -49px;
                }
                >li {
                    display: none;
                    position: relative;
                    padding: 5px 10px 5px 45px;
                    line-height: 2;                    
                    &::before {
                        content: '';
                        position: absolute;
                        top: 22px;
                        left: 30px;
                        width: 5px;
                        height: 5px;
                        border-radius: 2px;
                        background-color: #222;
                    }
                    &.active {
                        >a{
                            color: $point2;
                            box-shadow: inset 0 -1px 0 $point2;
                        }
                        &::before {
                            background-color: $point2;
                        }
                    }
                    >a{
                        display: inline-block;
                        color: rgb(105, 105, 105);
                        font-size: $body-font-size;
                        font-weight: 400;
                        letter-spacing: -0.45px;                        
                        word-break: break-all;
                    }
                }
            }
        }
    }
}