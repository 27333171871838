// font size
$browser-context: 16; // Default html font size 설정
$html-font-size	: 16px; // html font size 설정
$body-font-size : 1.125rem; // 18px
$fz-xs			: 0.875rem; // 14px
$btn-font-size  : 18px;  // 18px
$form-height    : 45px; // 폼, 폼에 들어가는 버튼 높이


$xs                 : max-width 360px;
$sm                 : max-width 767px;
$md                 : max-width 1199px;
$mdw             	: 768px;
$mdf                : 768px 1023px;
$lg                 : 1200px;
$xl                 : max-width 1275px;
$mw					: 1276px;

// board list color
$bd-topwidth : 2px;

// input
$form-height : 40px;
// button
$btn-height : $form-height;



$m1:24px;
$m2:34px;
// input
$formHeight         : 40px;
$btnHeight          : 35px;


$font-set		: 'Noto Sans KR', sans-serif, "AppleGothic","돋움","dotum",Helvetica,Arial,sans-serif;
$heading-font	: '';
$font-eng	    : 'Roboto', sans-serif, "AppleGothic","맑은고딕","malgun gothic";