//text color
$body-font-color        : #222;
$html-font-color        : #333;
$form-color             : #000;
$link-color             : #666;
$placeholder-color      : #515557;
$point                  : #f65626;
$point2                 : #e65a1d;
$skyblue                : #0c9ebd;
$blue                   : #0c35bd;
$red                    : #e24d4d;
$green                  : #29a400;
$orange                 : #ffc400;
$gray                   : #999;
$white                  : #fff;
$black                  : #000;
// background-color

// btn
$btn-default         : #222;
$btn-dark            : $black;
$btn-point           : $point;
$btn-point2          : $green;
$btn-point3          : $orange;
$btn-point4          : $gray;
// board
$bd-color               : #ddd;
$bd-top-color           : #777;
$bd-list-head-color     : #777;
$bd-list-th-bg-color    : #f1f1f1;
// table
$th-bg-color            : #f0f0f0;
// board view color

// faq
$faq-Q-dt-bgcolor       : #02b294;
$faq-Q-bgcolor          : #02b294;
// box
$box-bd-color           : #333;


// sh
$sh-bg-color        : rgba(246, 86, 38, 0.1);

$grayblue:'';
$trgray:'';
$muddyWaters:'';

.point{color: $point;}
.red{color: $red;}