.sh {
	$sh-form-height : 40px;
	padding: 20px 40px;
	background-color: $sh-bg-color;
	&__order {
		border-width: 0px;
		padding: 0;
		button {
			min-width: 48px;
			background-color: #666;
			color: $white;
			font-size: $html-font-size;
		}
	}
	label {
		line-height: $sh-form-height;
		color: #222222;
		font-weight: 500;
	}
	select,
	input[type=text]{
		width: 100%;
	}
	>div {
		&+div{
			margin-top: 8px;
		}
	}
	.sh-set{
		display: flex;
		flex-wrap: wrap
	}
	.select-group{
		min-width: 264px;
		display: flex;
		flex-direction: row;
		align-items: center;
		.label,
		label{
			padding-right: 20px;
		}
		select{
			max-width: 154px;
			min-width: 166px;
		}
		select + select{margin-left: 5px;}
		&+.select-group{
			margin-left: 30px;
		}
	}
	.input-group {
		min-width: 364px;
	}
	.button-group{
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: center;
		margin-top: 2px;
		button{
			margin-right: 5px;
			margin-bottom: 5px;
			&:focus {
				outline: 1px dashed #fff;
				outline-offset: -3px;
			}
		}
		@include breakpoint($sm) {
			width: 100%;
			display: inline-block;
			margin-top: 5px;
			button{margin-right: 0;}
		}
	}
	&__button{
		min-width: 86px;
		height: 40px;
		line-height: 1.4;
		text-align: center;
		font-weight: 500;
		color: $white;
		border-radius: 3px;
		background-color: #666;
		&.checked{
			background-color: #222;
		}
		@include breakpoint($sm){
			height: 35px;
			width: calc(100% / 4 - 5px);
			min-width: auto;
		}
	}
	.inp-sh-set{
		display: flex;
		select{
			max-width: 154px;
			&+input[type="text"]{
				margin-left: 4px;
			}
		}
		input[type="text"]{margin-right: 5px;}
		// select-group
		.select-group{
			width: 270px;
		}
		.b-reset{
			padding: 0;
			margin-left: 10px;
		}
		@include breakpoint($sm){
			display: block;
			select {
				max-width: inherit;
				margin-bottom: 5px;
				&+input[type="text"] {
					margin-left: 0;
				}
			}
			input[type="text"] {				
				margin-right: 0;
				width: calc(100% - 60px);
			}
			.b-sh{margin-left: -6px;}
			&.reset-type{
				input[type="text"] {
					width: calc(100% - 121px);
				}
				.b-reset{
					margin-left: 0;
					line-height: 20px;
				}
			}
		}
	}
	// 검색 한줄
	&.l{
		display: flex;
		align-items: center;
		flex-direction: row;
		.select-group{
			min-width: 267px;
			select{
				min-width: 154px;
				max-width: 100%;
			}
			&+.inp-sh-set {
				margin-left: 20px;
				width: calc(100% - 264px);
			}
			&.md{
				min-width: 313px;
				select {
					min-width: 166px;
					max-width: 100%;
				}
			}
		}
		.inp-sh-set{
			width: 100%;
		}
		.search-detail__dateset {
			display: inline-block;
			&+.inp-sh-set {
				width: calc(100% - 428px);
				@include breakpoint($xl) {
					width: 100%;
					margin-left: 0;
				}
			}
			@include breakpoint($md) {
				&+.inp-sh-set {
					width: 100%;
				}
				.label {
					width: 83px;
				}
			}
			@include breakpoint($sm) {
				.label {
					width: 50px;
				}
			}
			@media (max-width: 500px) {
				.label {
					display: block;
				}
				#startDate,
				#endDate{
					width: calc(50% - 11px);
				}
			}
		}
		@include breakpoint($xl){
			display: block;
			.select-group {
				&+.inp-sh-set{
					width: 100%;
					margin-left: 0;
					margin-top: 10px;
				}
			}
			.inp-sh-set {
				width: 100%;
			}
		}
	}
	//bd-list sh
	&__group{
		display: inline-block;
		+.sh__group{
			margin-left: 15px;
			@include breakpoint($sm) {
				margin-left: 0;
			}
		}
	}
	&__group,
	&__select-input {
		.select-group {
			display: inline-block;
			min-width: 158px;
			select{width: 100%;}
		}
		input[type="text"] {
			width: 240px;
		}
		.btn-boardsearch,
		.b-sh,
		.b-view {
			min-width: 60px;
			background-color: #666666;
			padding: 8px 10px;
			font-size: $html-font-size;
			&:focus {
				outline: 1px dashed #fff;
				outline-offset: -3px;
			}
		}
		@include breakpoint($sm){
			.select-group {
				min-width: 98px;
				max-width: 98px;
			}
			.btn-boardsearch,
			.b-sh,
			.b-view {
				min-width: 40px;
			}
		}
		@include breakpoint($xs) {
			.select-group {
					min-width: 73px;
				select {
					font-size: 14px;
				}
			}
		}
	}
	@include breakpoint($md){
		margin: 0 -20px 30px;
		padding: 20px;
	}
}
.search-detail {
	&__dateset {
		#startDate,
		#endDate {
			width: calc(50% - 15px);
			max-width: 145px;
			&::placeholder {
				color: rgb(34, 34, 34);
				letter-spacing: -0.6px;
				font-weight: 300;
			}
		}
		dd+& {
			width: calc(100% - 450px);
		}
		// 검색
		.sh & {
			margin-bottom: 2px;
			margin-right: 20px;
			min-width: 408px;
			.label {
				font-weight: 500;
				width: 90px;
				display: inline-block;
			}
			&+.inp-sh-set {
				width: calc(100% - 428px);
				@include breakpoint($md) {
					width:100%;
					margin-top: 10px;
				}
			}
			@include breakpoint($sm){
				margin-right: 0;
				min-width:100%;
				.label {
					width: 50px;
				}
				#startDate,
				#endDate {
					width: calc(50% - 14px - 25px);
					max-width: 100%;
				}
			}
			@media (max-width: 500px) {
				.label {
					display: block;
				}			
				#startDate,
				#endDate {
					width: calc(50% - 11px);
				}
			}
		}
	}
}