@use "sass:math";
// 제목
@mixin subject() {
	&:before {
		display: none;
	}
	margin: 0;
	padding: 10px 20px;
	font-size: 1.25rem;
	letter-spacing: normal;
	line-height: 1.8125rem;
	letter-spacing: -0.028rem;
	color: $black;
	word-break: break-all;
	text-align: center;
	border-bottom: 1px solid $point;
	@include breakpoint($sm) {
		padding: 10px;
	}
	.badge {
		vertical-align: text-top;
	}
}
// 글 보기
.bd-view {
	border-bottom: 1px solid #dadada;
	&__title{
		padding-bottom: 25px;
		border-bottom: 2px solid $black;
		.subject{
			@include bu1;
			border-bottom-width: 0px;
			padding-left: 10px;
			padding-bottom: 0;
			font-size: 20px;
			font-weight: 700;
			display: inline-block;
			width: calc(100% - 360px);
			&::before {
				top: 7px;
			}
		}
		.subject-sub{
			display: inline-block;
			width: 350px;
			text-align: right;
			span{
				display: inline-block;
				vertical-align: middle;
				font-weight: 700;
				line-height: 1.5;
				letter-spacing: -0.4px;
				&+ span{
					&::before {
						content: '';
						display: inline-block;
						margin: -2px 10px;
						width: 1px;
						height: 15px;
						background-color: rgb(102, 102, 102);
					}
				}
			}
		}
		@include breakpoint($md){
			padding-top: 10px;
			padding-bottom: 10px;
			border-top-width: 0;
			.subject,
			.subject-sub {
				display: block;
				width: 100%;
			}
			.subject{
				font-size: 20px;
				&::before {
					top: 6px;
				}
			}
			.subject-sub{margin-top: 10px;}
		}
		@include breakpoint($sm){
			.subject-sub{
				span{
					font-size: 15px;
					&+span::before {
						margin: -2px 5px;
					}
				}
			}
		}
	}
	&__table-dl{
		.file-list{
			display: flex;
			flex-direction: row;
			dt {
				width: 140px;
				background: #f8f8f8;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #333333;
				font-weight: 700;
			}
			dd {
				display: inline-block;
				width: calc(100% - 140px);
				padding: 30px;
				li {
					line-height: 1.5;
					padding-left: 27px;
					text-indent: -15px;
					&::before{
						content: '';
						@include la;
						font-size: 25px;
						vertical-align: middle;
						margin-right: 5px;
					}
					a {
						color: #333333;
						font-size: 16px;
						font-weight: 300;
						box-shadow: inset 0 -1px 0 $black;
						word-break: break-all;
						display: inline;
					}
					+li{margin-top: 10px;}
				}
			}
		}
		&-list{
			padding: 24px;
			li + li{margin-top: 5px;}
			.tit{
				color: #333333;
				font-weight: 700;
				font-size: $body-font-size;
				margin-right: 20px;
				&::before{
					@include la;
					vertical-align: middle;
					font-size: 25px;
					margin-right: 10px;
				}
				&.date::before {content: '';}
				&.win::before {content: '';}
				&.way::before {content: '';}
				&+span{
					font-weight: 300;
					padding-left: 20px;
					position: relative;
					&::before{
						content: '';
						position: absolute;
						left: 0;
						top: 5px;
						width: 1px;
						height: 15px;
						background-color: rgb(102, 102, 102);
					}
				}
			}
		}
	}
	.dbdata{
		border-top:1px solid #dadada;
		min-height: 200px;
		padding: 50px 40px 20px;
		word-break: break-all;
	}
	.box__blue{
		margin: 0 40px 60px;
	}
	@include breakpoint($sm) {
		&__table-dl{
			.file-list {
				dt {
					width: 90px;
				}
				dd {
					width: calc(100% - 90px);
					padding: 20px;
				}
			}
		}
		.dbdata{
			padding: 20px 10px;
		}
	}
}
.sns-link{
	text-align: right;
	padding: 20px 0;
	span{
		width: 41px;
		height: 40px;
		border-radius: 100%;
		display: inline-block;
		a{
			display: block;
		}
		&.facebook{
			background-image: url(/static/commons/img/icon-facebook.png);
		}
		&.twitter{
			background-image: url(/static/commons/img/icon-twitter.png);
		}
		&.kakao{
			background-image: url(/static/commons/img/icon-kakao.png);
		}
	}
}
// 뷰 네비게이션
.bd-view-nav {
	margin: 50px 0;
	border-width: 1px 0;
	border-style: solid;
	border-color: #666666;
	@include clearfix;
	dl + dl {
		border-top: 1px solid $bd-color;
	}
	dl {
		padding: 7px 0;
		height: 50px;
		display: table;
		table-layout: fixed;
		width: 100%;
		> * {
			display: table-cell;
			vertical-align: top;
			line-height: 36px;
		}
		dt {
			position: relative;
			width: 140px;
			color: $black;
			font-size: 16px;
			font-weight: 500;
			letter-spacing: -0.24px;
			padding-left: 53px;
			&::before {
				content: '';
				position: absolute;
				top: 50%;
				margin-top: -3.5px;
				left: 34px;
				background: url(/static/kogl/img/common/icon-bd-view-nav.png);
				width: 10px;
				height: 5px;
			}
		}
		dd {
			letter-spacing: -0.24px;
			padding-left: 20px;
			span {
				@include ellipsis;
				width: 100%;
				vertical-align: middle;
				font-weight: 300;
			}
			a {
				@include ellipsis;
				display: block;
				width: 100%;
			}
		}
		&.next {
			dt{
				&::before {
					transform: scaleY(-1);
				}
			}
		}
		@include breakpoint($sm) {
			width: 100%;
			padding: 16px 0;
			dt{
				width: 90px;
				padding-left: 23px;
				border-right: 1px solid #e6e6e6;
				&::before {
					left: 6px;
					top: 17px;
					margin-top: 0;
				}
			}
			dd{
				padding-left: 15px;
				span {
					white-space: inherit;
					line-height: 1.5;
				}
				a {
					white-space: inherit;
					line-height: 1.5;
				}
			}
			&.next {
				border-top: 1px solid #dddddd;
			}
		}
	}
}
.bd-reg {
	border-top: 2px solid $bd-top-color;
	border-top-width: 2px;
	margin-bottom: 30px;
	input[type="text"] {
		+ span {
			line-height: 40px;
			color: #888888;
			padding-left: 10px;
		}
		@include breakpoint($mdw) {
			&.w-name {
				width: 200px;
			}
		}
	}
	input[type="date"] {
		width: 100%;
		&.date {
			@include breakpoint($mdw) {
				width: 140px;
			}
		}
		&.md {
			width: 220px;
		}
	}
	input[type="number"] {
		width: 100%;
		&.membercount {
			@include breakpoint($mdw) {
				width: 200px;
			}
		}
	}
	select.location {
		@include breakpoint($mdw) {
			min-width: 200px;
		}
	}
	textarea {
		height: 80px;
		width: 100%;
		&.h {
			height: 300px;
		}
	}
	.subject-sub {
		margin: 0 !important;
		padding: 12px 0;
		color: #000 !important;
		font-size: 18px;
		font-weight: 400;
		letter-spacing: -0.45px;
		line-height: 1.3;
		text-align: center;
		border-bottom: 1px solid $bd-color;
		&::before {
			display: none;
		}
	}
	> dl {
		border-bottom: 1px solid $bd-color;
		@include breakpoint($mdw) {
			display: table;
			width: 100%;
			table-layout: fixed;
			> * {
				display: table-cell;
				vertical-align: middle;
			}
		}
		&.n {
			> dt {
				padding-top: 10px;
			}
			> dd {
			}
		}
		> dt {
			position: relative;
			text-align: center;
			padding: 20px 0 10px;
			font-size: 1.125rem;
			color: #222222;
			font-size: 16px;
			font-weight: 500;
			letter-spacing: -0.24px;
			vertical-align: top;
			.dt-lg & {
				width: 210px;
			}
			@include breakpoint($mdw) {
				width: 160px;
				padding: 13px 0;
			}
			label {
				font-weight: inherit;
			}
			@include breakpoint($sm) {
				width: auto;
				text-align: left;
			}
			@include breakpoint($lg) {
				background-color: $th-bg-color;
			}
		}
		&:first-child {
			border-top: 0;
		}
		> dd {
			padding-bottom: 10px;
			position: relative;
			@include breakpoint($mdw) {
				padding: 5px 20px;
			}
			.deco {
				&::before {
					content: "";
					display: inline-block;
					margin-right: 10px;
					vertical-align: middle;
				}
				// color: $muddyWaters;
				font-size: 1.125rem;
				font-weight: 700;
				letter-spacing: normal;
				line-height: 1.44;
				text-indent: -15px;
				padding-left: 15px;
			}
			&.dd-payment,
			&.dd-payment form {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				@include breakpoint($sm) {
					align-items: flex-start;
					.radio-group {
						label {
							display: block;
							& + label {
								margin-left: 0;
								margin-top: 5px;
							}
						}
					}
				}
			}
			&.dd-dl {
				padding: 0;
				> dl {
					@include breakpoint($mdw) {
						display: table;
						width: 100%;
						table-layout: fixed;
						border-left: 1px solid $point;
						> * {
							display: table-cell;
							vertical-align: middle;
						}
					}
					dt {
						background-color: #f8fbfd;
						text-align: left;
						padding: 15px 10px 10px;
						font-size: 1.125rem;
						@include breakpoint($mdw) {
							width: 130px;
						}
						color: #222222;
						font-size: 16px;
						font-weight: 500;
						letter-spacing: -0.24px;
						vertical-align: top;
						@include breakpoint($sm) {
							width: auto;
						}
					}
					dd {
						padding: 0 20px;
						@include breakpoint($mdw) {
						}
					}
					& + dl {
						border-top: 1px solid #ddd;
					}
				}
			}
		}
	}
	select,
	input[type="text"],
	input[type="password"] {
		height: 40px;
	}
	.inp-txt1 {
		width: 100%;
		max-width: 400px;
	}
	.deco {
		padding: 4px 0;
	}
	&.w-dt dt {
		@include breakpoint($mdw) {
			width: 300px;
		}
	}
	&.w-dt-md dt {
		@include breakpoint($mdw) {
			width: 220px;
		}
	}
	// 개인정보수집동의
	&.w-dt-md2 {
		dt {
			width: 220px;
		}
		@include breakpoint($md) {
			dt,
			dd {
				display: block;
				text-align: left;
			}
			dt {
				width: auto;
			}
			dd {
				padding: 0 0 10px;
			}
		}
	}
	.dialogs & {
		input.w50p {
			width: 50% !important;
		}
		.date-set {
			input[type="date"] {
				width: calc(50% - 8px);
			}
		}
		.call-set {
			input[type="text"] {
				width: calc(33.33% - 8px);
			}
		}
	}
	&.box {
		padding: 0;
		margin-top: 40px;
	}
	@include breakpoint($mdw) {
		&.dt-r1 {
			dt {
				border-right: 1px solid $bd-color;
			}
		}
	}
}
//  입력 도움말
.help-star {
	color: red;
	text-indent: -18px;
	padding-left: 18px;
	&::before {
		content: '※ ';
	}
}
// input+button
.inp-group {
	input[type='text'] {
		border-right-width: 0;
	}
	[class^='b-'] {
	}
	@include breakpoint($sm) {
		input[type='text'] {
			width: calc(100% - 80px);
		}
		[class^='b-'] {
			width: 80px;
		}
	}
}
// 레이블 리스트
.label-list {
	label{
	}
	label.etc {
		span {
			margin-right: 10px;
		}
		& + input[type='text'] {
			width: 150px;
		}
	}
	@include breakpoint($sm){
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;
		label{
			margin: 5px;
			white-space:nowrap;
		}
	}
}
// 우편번호
.post-set {
	max-width: 900px;
	@include clearfix();
	span {
		display: block;
		&:nth-child(1) {
			margin-bottom: 10px;
			@include clearfix();
			input {
				float: left;
				margin-right: 10px;
				@include breakpoint($sm) {
					width: calc(100% - 170px);
				}
			}
		}
		&:nth-child(2) {
			float: left;
			$a: math.div(4, 9) * 100%;
			width: $a;
			@include breakpoint($sm) {
				width: 100%;
			}
		}
		&:nth-child(3) {
			float: left;
			width: math.div(5, 9) * 100%;
			padding-left: 10px;
			@include breakpoint($sm) {
				width: 100%;
				padding-left: 0;
				margin-top: 10px;
			}
		}
	}
	.post {
		width: 200px;
	}
	.post-search {
		display: inline-block;
		vertical-align: top;
		height: 50px;
		line-height: 48px;
		text-align: center;
		border: 1px solid #464646;
		background-color: #ffffff;
		width: 160px;
		background-color: #ffffff;
		.ico-post {
			width: 17px;
			height: 16px;
			display: inline-block;
			vertical-align: middle;
			margin-right: 9px;
			// background: url(/static/portal/img/common/ico-post.png) center center no-repeat;
		}
	}
	.post-default {
		width: 100%;
	}
	.post-detail {
		width: 100%;
	}
}
// 전화번호
.tel-set {
	max-width: 450px;
	> * {
		float: left;
		vertical-align: top;
	}
	@include clearfix();
	.tel1[type="text"],
	select {
		width: calc(40% - 40px);
		@include breakpoint($lg) {
			width: calc(40% - 52px);
		}
	}
	input.tel {
		width: 30%;
	}
	i {
		text-align: center;
		// line-height: 50px;
		line-height: ($form-height - 2px);
		@include breakpoint($md) {
			width: 20px;
		}
		@include breakpoint($lg) {
			width: 26px;
		}
	}
	@include breakpoint($md) {
		// padding-bottom: 5px;
		.tel::nth-child(3) {
		}
	}
}
// 필수입력 안내
.req-infor {
	color: $black;
	text-align: right;
	margin-bottom: 10px;
}
.req {
	color: #525d64;
	font-size: 15px;
	font-weight: 400;
	font-size: smaller;
	&::before {
		content: "＊ ";
		color: red;
		display: inline-block;
		vertical-align: top;
		margin-right: 3px;
		position: relative;
	}
	span {
		position: absolute;
		width: 1px;
		height: 1px;
		margin: -1px;
		padding: 0;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		border: 0;
	}
}
// 동의
.agg-set {
	border-top: 2px solid $bd-top-color;
	border-bottom: 1px solid $bd-color;
	background-color: #f6f6f6;
	padding: 40px;
	h3 {
		margin-bottom: 10px;
		&::before {
			content: "";
			width: 3px;
			height: 20px;
			background-color: #a27b4c;
			border-radius: 1px;
			display: inline-block;
			margin-right: 14px;
			vertical-align: middle;
		}
	}
	.ovy {
		margin: 15px 0;
		border: 0.063rem solid #ccc;
		background-color: #fff;
		padding: 20px 18px;
		max-height: 293px;
	}
}
// 파일추가
/* .file-add {
	float: left;
	width: calc(100% - 106px - 20px);
	@include breakpoint($sm) {
		width: calc(100% - 66px - 20px);
	}
	[class^="b-"] {
		height: 40px;
		// line-height: 40px;
	}
	.file-add-plus {
		float: right;
		button {
			width: 80px !important;
		}
	}
	.b-add {
		float: right;
		width: 106px !important;
		min-width: 0;
		padding: 0;
	}
	.item {
		margin-bottom: 5px;
		& + .item {
		}
		@include clearfix();
		input[type="file"] {
			float: left;
			width: calc(100% - 106px - 5px);
			height: 40px;
			border: 1px solid $bd-color;
		}
		.b-del {
			float: right;
			width: 106px !important;
			min-width: 0;
			padding: 0;
		}
	}
} */

// 카테고리 상세
.board-view {
	h2 {
		@include clamp;
		@include bu1;
		position: relative;
		padding-left: 20px;
		padding-bottom: 0;
		max-height: 80px;
		font-size: 28px;
		font-weight: 700;
		line-height: 1.43;
		letter-spacing: -0.7px;
		border-bottom-width: 0px;
		&::before {
			height: 25px;
		}
	}
	&__inner {
		display: flex;
		justify-content: space-between;
		.swiper{
			max-width: 620px;
			width: calc(52% - 20px);
			height: auto;
			margin: 0;
		}
		.board-view__info{
			width: calc(48% - 14px);
		}
		>h2{display: none;}
		@include breakpoint($xl){
			display: block;
			.swiper{
				width: 100%;
				margin: auto;
			}		
			.board-view__info {
				width: 100%;
				>h2{display: none;}
			}
			>h2 {
				display: block;
				height: auto;
				font-size: 20px;
				margin-bottom: 20px;
				&::before{
					height: 18px;
					top: 6px;
				}
			}
		}
		@include breakpoint($md) {
			.swiper{
				max-width: 100%;
			}
		}
	}
	.swiper {
		.swiper-slide {
			border: 1px solid rgb(221, 221, 221);
			display: inline-block;
			overflow: hidden;
			.img{
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
			}
			&.swiper-slide-thumb-active {
				opacity: 1;
				// border-width: 2px;
			}
			&:focus {
				outline: 1px dashed;
				outline-offset: -1px;
			}
		}
	}
	&__swiper {
		position: relative;
		.swiper-slide .img {
			@include aspectRatio(620, 620);
		}
		.enlarge-btn {
			width: 50px;
			height: 50px;
			background: $black url(/static/kogl_alright/img/sub/icon-enlarge.png) center no-repeat;
			z-index: 10;
			position: absolute;
			bottom: 0;
			right: 0;
			&:focus {
				outline-color: #fff;
				outline-offset: -3px;
			}
		}
	}
	&__thumbsSlider {
		margin: 30px 30px 0;
		overflow: hidden;
		.swiper-slide{
			opacity: 0.6;
			// max-width: calc(100% / 3);
			.img {
				@include aspectRatio(172, 172);
			}
		}
	}
	&__swiper-ctrl {
		position: relative;
		button{
			position: absolute;
			bottom: 75px;
			width: 17px;
			height: 25px;
			background-repeat: no-repeat;
			background-size: contain;
			@include breakpoint($sm){
				bottom: 45px;
			}
			@include breakpoint($xs){
				bottom: 25px;
			}
			&:focus {
				outline-offset: -1px;
			}
		}
		&-button-prev {
			left: 0;
			background-image: url(/static/kogl_alright/img/sub/prev.png);
		}
		&-button-next {
			right: 0;
			background-image: url(/static/kogl_alright/img/sub/next.png);
		}
	}
	&__info {
	}
	&__status {
		margin: 10px 0;
		p + p{
			padding-left: 15px;
			margin-left: 10px;
			&::before{
				content: '';
				position: absolute;
				top: 5px;
				left: 0;
				width: 1px;
				height: 15px;
				background-color: #666;
			}
		}
		p{
			position: relative;
			display: inline-block;
			span{
				color: #222;
				font-weight: 500;
				line-height: 1.4;
				&.tit{
					&::after{
						content: ':';
						padding: 0 5px;
					}
				}
			}
		}
	}
	&__agency-title{
		font-size: 22px;
		font-weight: 700;
		margin-top: 130px;
	}
	&__dl{
		border-top: 2px solid #ddd;
		border-bottom: 1px solid #ddd;
		padding: 28px 0 30px;
		margin-top: 40px;
		margin-bottom: 60px;
		dl{
			font-size: $body-font-size;
			display: table;
			table-layout: fixed;
			width: 100%;
			dt{
				display: table-cell;
				width: 132px;
				font-weight: 500;
				padding: 5px 0;
				border-right: 1px solid rgba(204, 204, 204, 0.3);
			}
			dd{
				display: table-cell;
				word-break: break-all;
				color: $point;
				font-weight: 400;
				padding: 5px 0 5px 38px;
			}
		}
	}
	&__download{
		width: 100%;
		background-color: #222;
		color: $white;
		border-radius: 25px;
		text-align: center;
		font-size: 20px;
		font-weight: 700;
		padding: 10px 0;
		&:focus {
			outline: 1px dashed #fff;
			outline-offset: -3px;
		}
	}
	.box__gray{
		margin-top: 20px;
		margin-bottom: 20px;
		text-align: center;
		.tel-info{
			font-size: 20px;
			padding: 13px 0 12px;
			&::before{
				content: '';
				@include la;
				vertical-align: middle;
				font-size: 30px;
				color: $point;
				padding-right: 10px;
				padding-bottom: 3px;
			}
		}
	}
	&__content-db-detail {
		margin-top: 60px;
		border: 1px solid #dddddd;
		padding: 20px 60px 60px;
		h3[class^=bu-]{
			margin-top: 30px;
		}
	}
	.btn-set{text-align: center;}
	@include breakpoint($xl){
		&__agency-title {
			margin-top: 20px;
		}
		&__status {
			border-bottom: 1px solid #ddd;
			padding: 20px 0;
		}
		&__dl {
			padding: 25px 0 25px;
			margin-top: 20px;
			margin-bottom: 20px;
			dl{
				dt{width: 100px;}
				dd{
					padding-left: 19px;
				}
			}
		}
		&__status {
			p:nth-child(3){
				display: block;
				padding-left: 0;
				margin-left: 0;
				margin-top: 10px;
				&::before {content: none;}
			}
		}
	}
	@include breakpoint($md) {
		&__content-db-detail {
			margin-top: 30px;
			padding: 10px 20px 20px;
			h3[class^=bu-] {
				margin-top: 20px;
			}
		}
	}
	@include breakpoint($sm) {
		&__agency-title {
			font-size: 20px;
		}
		&__download {
			font-size: 16px;
		}
		.box__gray {
			.tel-info {
				font-size: 15px;
			}
		}
		&__content-db-detail {
			.b-down{
				width: 100%;
				padding: 14px 0 13px;
			}
		}
	}
	@include breakpoint($lg){
		display: grid;
		.btn-set{
			text-align: right;
			margin-top: 0;
			grid-row: 2/2;
		}
	}
}