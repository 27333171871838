@charset "UTF-8";
@import url("/static/commons/css/common.css");
@import url("/static/kogl_alright/css/default.css");
@import url("/static/jslibrary/bootstrap-datepicker-1.9.0-dist/css/bootstrap-datepicker3.standalone.css");
.point {
color: #f65626;
}

.red {
color: #e24d4d;
}

#content > h2,
.hx > h2,
.bu1 {
position: relative;
padding-left: 25px;
padding-bottom: 10px;
font-size: 28px;
font-weight: 700;
letter-spacing: -0.55px;
border-bottom: 2px solid #dddddd;
margin-bottom: 10px;
}
#content > h2::before,
.hx > h2::before,
.bu1::before {
content: "";
width: 3px;
height: 18px;
background-color: #f65626;
position: absolute;
top: 13px;
left: 0;
}
#content > h2.bdnone,
.hx > h2.bdnone,
.bu1.bdnone {
border-bottom-width: 0px;
}
#content > h2.bdbcolor,
.hx > h2.bdbcolor,
.bu1.bdbcolor {
border-bottom-color: #666;
}
@media (max-width: 767px) {
#content > h2,
.hx > h2,
.bu1 {
font-size: 20px;
}
#content > h2::before,
.hx > h2::before,
.bu1::before {
top: 6px;
}
}
#content > h2 > span,
.hx > h2 > span,
.bu1 > span {
font-size: 16px;
font-weight: normal;
font-family: "Noto Sans KR", sans-serif, "AppleGothic", "돋움", "dotum", Helvetica, Arial, sans-serif;
}
#content > h2 > a,
.hx > h2 > a,
.bu1 > a {
font-weight: normal;
}
#content > h2.no-bull::before,
.hx > h2.no-bull::before,
.bu1.no-bull::before {
display: none;
}

/* 2 */
#content > h3,
.hx > h3,
.bu2 {
position: relative;
padding-left: 22px;
padding-bottom: 20px;
color: #000;
font-size: 20px;
font-weight: 700;
}
#content > h3::before,
.hx > h3::before,
.bu2::before {
content: "";
width: 5px;
height: 5px;
background-color: #000;
border-radius: 2px;
position: absolute;
top: 12px;
left: 0;
}
.row #content > h3,
.row .hx > h3,
.row .bu2 {
margin-top: 0;
}
#content > h3 > span,
.hx > h3 > span,
.bu2 > span {
font-size: 16px;
font-weight: normal;
font-family: "Noto Sans KR", sans-serif, "AppleGothic", "돋움", "dotum", Helvetica, Arial, sans-serif;
}
#content > h3.point,
.hx > h3.point,
.bu2.point {
color: #f65626;
}
#content > h3.bu-icon,
.hx > h3.bu-icon,
.bu2.bu-icon {
font-size: 28px;
font-weight: 500;
padding-left: 24px;
margin-top: 60px;
}
#content > h3.bu-icon::before,
.hx > h3.bu-icon::before,
.bu2.bu-icon::before {
content: none;
}
@media (max-width: 767px) {
#content > h3.bu-icon,
.hx > h3.bu-icon,
.bu2.bu-icon {
font-size: 20px;
background-position: 0 8px;
margin-top: 30px;
}
}

.bu-icon {
background-image: url(/static/kogl_alright/img/sub/bu-icon.png);
background-repeat: no-repeat;
background-position: 0 15px;
line-height: 1.43;
letter-spacing: -0.7px;
font-size: 28px;
font-weight: 500;
padding-left: 24px;
margin-top: 60px;
margin-bottom: 20px;
}
@media (max-width: 767px) {
.bu-icon {
font-size: 22px;
background-position: 0 10px;
margin-top: 30px;
margin-bottom: 10px;
}
}

p.bu2 {
color: #333;
font-weight: 400;
font-size: 1.125rem;
}

/* 3 */
#content > h4,
.hx > h4,
.bu3 {
position: relative;
padding-left: 10px;
font-size: 16px;
font-weight: 400;
}
#content > h4::before,
.hx > h4::before,
.bu3::before {
position: absolute;
top: 0;
left: 0;
content: "- ";
}
#content > h4 + .table,
.hx > h4 + .table,
.bu3 + .table {
margin-top: 0;
}

/* 4 */
/* #content h5,
.bu4 {
	@include bu4;
} */
/* 5 */
#content h6 {
font-size: 1em;
padding-left: 38px;
}

.list > li {
position: relative;
padding-left: 22px;
padding-bottom: 20px;
color: #000;
font-size: 20px;
font-weight: 700;
font-weight: 400;
padding-bottom: 0;
font-size: 20px;
/* > strong {
	color: $point;
} */
}
.list > li::before {
content: "";
width: 5px;
height: 5px;
background-color: #000;
border-radius: 2px;
position: absolute;
top: 12px;
left: 0;
}
.list > li.mt {
margin-top: 40px;
}
@media (max-width: 767px) {
.list > li {
font-size: 18px;
}
.list > li.mt {
margin-top: 10px;
}
}
.list > li > em {
font-weight: 700;
}
.list > li > ul {
margin-top: 5px;
}
.list > li > ul > li {
position: relative;
padding-left: 10px;
font-size: 16px;
font-weight: 400;
margin-left: 0;
font-size: 1.125rem;
}
.list > li > ul > li::before {
position: absolute;
top: 0;
left: 0;
content: "- ";
}
@media (max-width: 767px) {
.list > li > ul > li {
font-size: 16px;
}
}
.list > li > ul > li > ul li,
.list > li > ul > li > ol li {
text-indent: -12px;
padding-left: 12px;
}
.list > li > ul > li > ul li:before,
.list > li > ul > li > ol li:before {
content: "· ";
display: inline;
}
.list > li > ul.none li:before {
display: none;
}
.list > li > ul.number li {
text-indent: -20px;
padding-left: 20px;
}
.list > li > ul.number li:before {
display: none;
}
.list > li + li {
margin-top: 5px;
}
.list.sm {
margin-bottom: 40px;
}
@media (min-width: 1276px) {
.list.sm {
margin-bottom: 80px;
}
}
.list.sm > li {
font-size: 16px;
}
.list.sm > li::before {
top: 10px;
}
@media (max-width: 767px) {
.list.sm > li {
font-size: 16px;
}
}

.list-bu3 > li {
position: relative;
padding-left: 10px;
font-size: 16px;
font-weight: 400;
}
.list-bu3 > li::before {
position: absolute;
top: 0;
left: 0;
content: "- ";
}

[class^=bu-]::before {
font-family: "Line Awesome Free";
font-weight: 900;
-webkit-font-smoothing: antialiased;
display: inline-block;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1;
font-size: 30px;
vertical-align: middle;
padding-bottom: 3px;
padding-right: 10px;
}
[class^=bu-].bu-precaution {
border-bottom: 1px solid #ddd;
font-size: 20px;
padding-bottom: 5px;
margin-bottom: 10px;
color: #f65626;
}
[class^=bu-].bu-precaution::before {
content: "\f071";
color: #f65626;
}
[class^=bu-].bu-way {
border-bottom: 1px solid #ddd;
font-size: 20px;
padding-bottom: 5px;
margin-bottom: 10px;
}
[class^=bu-].bu-way::before {
content: "\f059";
}
[class^=bu-].bu-info {
border-bottom: 1px solid #ddd;
font-size: 20px;
padding-bottom: 5px;
margin-bottom: 10px;
}
[class^=bu-].bu-info::before {
content: "\f05a";
}

.lnb h2 {
height: 150px;
padding-top: 35px;
text-align: center;
font-size: 32px;
font-weight: 700;
letter-spacing: -1.25px;
line-height: 1;
border-radius: 4px;
color: #fff;
background: url(/static/kogl_alright/img/common/lnb-bg.png) 0 0 no-repeat;
}
.lnb__menu {
margin-bottom: 50px;
}
.lnb__menu > li {
position: relative;
}
.lnb__menu > li::before {
content: "";
position: absolute;
top: 39px;
left: 10px;
width: 5px;
height: 5px;
border-radius: 100%;
background-color: #c1c1c1;
}
.lnb__menu > li.active::before {
background-color: #e65a1d;
}
.lnb__menu > li.active > a {
border-bottom-width: 3px;
border-bottom-color: #e65a1d;
color: #e65a1d;
}
.lnb__menu > li.active .depth2 {
padding: 10px 0;
}
.lnb__menu > li.active .depth2::before {
content: "\f106";
color: #e65a1d;
}
.lnb__menu > li.active .depth2 > li {
display: block;
}
.lnb__menu > li > a {
height: 79px;
line-height: 79px;
display: block;
padding-left: 35px;
font-size: 20px;
color: #222;
font-weight: 400;
letter-spacing: -0.45px;
border-bottom: 1px solid #ddd;
}
.lnb__menu > li .depth2 {
position: relative;
background-color: #f6f6f6;
}
.lnb__menu > li .depth2::before {
font-family: "Line Awesome Free";
font-weight: 900;
-webkit-font-smoothing: antialiased;
display: inline-block;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1;
content: "\f107";
color: #c1c1c1;
font-size: 1.125rem;
position: absolute;
right: 28px;
top: -49px;
}
.lnb__menu > li .depth2 > li {
display: none;
position: relative;
padding: 5px 10px 5px 45px;
line-height: 2;
}
.lnb__menu > li .depth2 > li::before {
content: "";
position: absolute;
top: 22px;
left: 30px;
width: 5px;
height: 5px;
border-radius: 2px;
background-color: #222;
}
.lnb__menu > li .depth2 > li.active > a {
color: #e65a1d;
box-shadow: inset 0 -1px 0 #e65a1d;
}
.lnb__menu > li .depth2 > li.active::before {
background-color: #e65a1d;
}
.lnb__menu > li .depth2 > li > a {
display: inline-block;
color: rgb(105, 105, 105);
font-size: 1.125rem;
font-weight: 400;
letter-spacing: -0.45px;
word-break: break-all;
}

.tab-list {
position: relative;
margin-bottom: 40px;
margin-top: -2px;
}
.tab-list ul {
display: flex;
flex-wrap: wrap;
width: 100%;
}
.tab-list ul li {
flex: auto;
}
.tab-list ul li a,
.tab-list ul li button {
width: 100%;
position: relative;
color: #666666;
font-size: 16px;
font-weight: 400;
line-height: 1.3;
padding: 20px 0px;
text-align: center;
}
.tab-list ul li a::before,
.tab-list ul li button::before {
content: "";
width: 100%;
height: 100%;
border: 1px solid #666666;
position: absolute;
top: 0;
left: 0;
}
.tab-list ul li a.on, .tab-list ul li a[aria-selected=true],
.tab-list ul li button.on,
.tab-list ul li button[aria-selected=true] {
color: #222222;
font-weight: 500;
}
.tab-list ul li a.on::before, .tab-list ul li a[aria-selected=true]::before,
.tab-list ul li button.on::before,
.tab-list ul li button[aria-selected=true]::before {
border-bottom: none;
border-width: 2px;
border-color: #222222;
}
.tab-list ul li a.on::after, .tab-list ul li a[aria-selected=true]::after,
.tab-list ul li button.on::after,
.tab-list ul li button[aria-selected=true]::after {
content: "";
position: absolute;
top: 2px;
left: 50%;
transform: translateX(-50%);
border-top: 7px solid #222;
border-left: 5px solid transparent;
border-right: 5px solid transparent;
}
.tab-list ul li a.on span, .tab-list ul li a[aria-selected=true] span,
.tab-list ul li button.on span,
.tab-list ul li button[aria-selected=true] span {
display: inline-block;
position: relative;
}
.tab-list ul li a.on span::after, .tab-list ul li a[aria-selected=true] span::after,
.tab-list ul li button.on span::after,
.tab-list ul li button[aria-selected=true] span::after {
content: "";
position: absolute;
bottom: -7px;
left: 0;
height: 1px;
width: 100%;
background-color: #222;
}
.tab-list.select {
display: none;
}
@media (max-width: 767px) {
.tab-list {
display: none;
}
.tab-list.select {
display: block;
margin-top: 20px;
margin-bottom: 30px;
}
.tab-list.select select {
width: 100%;
height: 52px;
font-size: 18px;
padding-left: 20px;
padding-right: 40px;
background-size: auto 8px;
background-position: right 20px center;
}
}

.tab-list2 {
margin: 30px 15%;
}
.tab-list2.lg {
margin: 30px 5%;
}
.tab-list2 ul {
flex-wrap: wrap;
justify-content: center;
text-align: center;
}
.tab-list2 ul li {
margin: 5px 10px;
}
.tab-list2 ul li a,
.tab-list2 ul li button {
display: block;
width: 216px;
border-radius: 25px;
border: 1px solid rgb(218, 218, 218);
background-color: #fff;
padding: 10px 0 11px;
}
.tab-list2 ul li a span,
.tab-list2 ul li button span {
color: rgb(34, 34, 34);
font-size: 1.125rem;
font-weight: 400;
}
.tab-list2 ul li a.on, .tab-list2 ul li a[aria-selected=true], .tab-list2 ul li a:hover, .tab-list2 ul li a:active, .tab-list2 ul li a:focus,
.tab-list2 ul li button.on,
.tab-list2 ul li button[aria-selected=true],
.tab-list2 ul li button:hover,
.tab-list2 ul li button:active,
.tab-list2 ul li button:focus {
border: 1px solid rgb(0, 165, 137);
}
.tab-list2 ul li a.on span, .tab-list2 ul li a[aria-selected=true] span, .tab-list2 ul li a:hover span, .tab-list2 ul li a:active span, .tab-list2 ul li a:focus span,
.tab-list2 ul li button.on span,
.tab-list2 ul li button[aria-selected=true] span,
.tab-list2 ul li button:hover span,
.tab-list2 ul li button:active span,
.tab-list2 ul li button:focus span {
font-weight: 500;
color: #f65626;
position: relative;
padding-bottom: 2px;
}
.tab-list2 ul li a.on span::after, .tab-list2 ul li a[aria-selected=true] span::after, .tab-list2 ul li a:hover span::after, .tab-list2 ul li a:active span::after, .tab-list2 ul li a:focus span::after,
.tab-list2 ul li button.on span::after,
.tab-list2 ul li button[aria-selected=true] span::after,
.tab-list2 ul li button:hover span::after,
.tab-list2 ul li button:active span::after,
.tab-list2 ul li button:focus span::after {
position: absolute;
bottom: 0;
left: 0;
width: 100%;
height: 1px;
background-color: #f65626;
content: "";
}
.tab-list2.select {
display: none;
}
@media (max-width: 767px) {
.tab-list2 {
display: none;
}
.tab-list2.select {
display: block;
margin-top: 20px;
margin-bottom: 30px;
margin-left: 0;
margin-right: 0;
}
.tab-list2.select select {
width: 100%;
}
}

.tab-nav2 {
margin: 30px 0 36px;
}
.tab-nav2 a {
text-align: center;
padding: 15px 0;
font-size: 16px;
}
.tab-nav2 ul {
display: flex;
flex-flow: row wrap;
justify-content: left;
border: 1px solid #ddd;
}
.tab-nav2 ul li {
text-align: center;
flex: 1;
margin-right: -1px;
}
.tab-nav2 ul li.on > a::after {
content: "";
display: inline-block;
position: absolute;
top: 0;
left: 50%;
transform: translate(-4px, 0);
position: absolute;
content: "";
border-left: 8px solid transparent;
border-right: 8px solid transparent;
border-top: 8px solid #000;
color: #f65626;
}
.tab-nav2 ul li a {
position: relative;
display: block;
text-align: center;
line-height: 19px;
background-position: 100% 50%;
}
.tab-nav2 ul li a span {
display: inline-block;
position: relative;
}
.tab-nav2 ul li a span::after {
content: "";
position: absolute;
width: 100%;
bottom: -6px;
left: 0;
border-bottom: 2px solid #f65626;
transform: scaleX(0);
transform-origin: 0 0;
transition: transform 0.3s ease-out;
}
.tab-nav2 ul li a:focus span::after, .tab-nav2 ul li a:hover span::after {
content: "";
position: absolute;
transform: scaleX(1);
}
.tab-nav2 ul li a.on span::after {
transform: scaleX(1);
font-weight: 700;
}
@media (max-width: 1275px) {
.tab-nav2 ul li a {
font-size: 1rem;
padding: 5px 10px 6px;
}
}
@media (min-width: 768px) and (max-width: 1023px) {
.tab-nav2 ul li a {
font-size: 0.875rem;
padding: 5px 10px 6px;
}
}
@media (max-width: 767px) {
.tab-nav2 ul li a {
display: block;
}
}

.tablist {
position: relative;
margin-bottom: 40px;
}
.tablist.mt {
margin-top: -33px;
}
.tablist ul {
display: flex;
flex-wrap: wrap;
width: 100%;
}
.tablist ul li {
flex: auto;
position: relative;
background-color: #fff;
}
.tablist ul li::before {
content: "";
width: 100%;
height: 100%;
border: 1px solid #666666;
position: absolute;
top: 0;
left: 0;
}
.tablist ul li a {
position: relative;
width: 100%;
color: #666666;
font-size: 16px;
font-weight: 400;
line-height: 1.3;
padding: 20px 0px;
text-align: center;
}
.tablist ul li.on::before {
border-bottom: none;
border-width: 2px;
border-color: #222222;
}
.tablist ul li.on::after {
content: "";
position: absolute;
top: 2px;
left: 50%;
transform: translateX(-50%);
border-top: 7px solid #222;
border-left: 5px solid transparent;
border-right: 5px solid transparent;
}
.tablist ul li.on a {
color: #222222;
font-weight: 500;
}
.tablist ul li.on a span {
display: inline-block;
position: relative;
}
.tablist ul li.on a span::after {
content: "";
position: absolute;
bottom: -7px;
left: 0;
height: 1px;
width: 100%;
background-color: #222;
}
.tablist span.selected {
display: none;
}
@media (max-width: 767px) {
.tablist {
display: inline-block;
position: relative;
z-index: 10;
width: 100%;
height: 52px;
margin-bottom: 30px;
border-radius: 5px;
border: 1px solid #ccc;
background-size: auto 8px;
background-position: right 20px center;
background-color: #fff;
background-repeat: no-repeat;
background-image: url(/static/kogl/img/common/select-arr.png);
}
.tablist span.selected {
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
display: inline-block;
display: block;
height: 52px;
padding: 12px 40px 12px 15px;
font-size: 1.125rem;
}
.tablist ul {
background-color: #fff;
border: 1px solid #000;
z-index: 30;
position: absolute;
top: 52px;
left: 0;
display: none;
width: 100%;
padding-left: 5px;
padding-right: 5px;
}
.tablist ul.show {
display: block;
}
.tablist ul li::before {
content: none;
}
.tablist ul li.on, .tablist ul li[aria-selected=true] {
display: block;
}
.tablist ul li.on::before, .tablist ul li.on::after, .tablist ul li[aria-selected=true]::before, .tablist ul li[aria-selected=true]::after {
content: none;
}
.tablist ul li.on a span::after, .tablist ul li[aria-selected=true] a span::after {
content: none;
}
.tablist ul li span {
text-align: left;
font-size: 15px;
}
.tablist ul li a {
text-align: left;
padding: 4px 0;
}
}

[class^=icon-],
.icon {
display: inline-block;
vertical-align: middle;
}

.icon-complete {
width: 120px;
height: 120px;
background: url(/static/kogl/img/sub/bg-confirm.png) center 0 no-repeat;
}

.icon-hwp {
width: 26px;
height: 26px;
background: url(/static/commons/img/icon-hwp.png) 0 0 no-repeat;
}

.icon-down {
width: 30px;
height: 30px;
background: url(/static/kogl_alright/img/common/icon-down.png) 0 0 no-repeat;
}

.sh {
padding: 20px 40px;
background-color: rgba(246, 86, 38, 0.1);
}
.sh__order {
border-width: 0px;
padding: 0;
}
.sh__order button {
min-width: 48px;
background-color: #666;
color: #fff;
font-size: 16px;
}
.sh label {
line-height: 40px;
color: #222222;
font-weight: 500;
}
.sh select,
.sh input[type=text] {
width: 100%;
}
.sh > div + div {
margin-top: 8px;
}
.sh .sh-set {
display: flex;
flex-wrap: wrap;
}
.sh .select-group {
min-width: 264px;
display: flex;
flex-direction: row;
align-items: center;
}
.sh .select-group .label,
.sh .select-group label {
padding-right: 20px;
}
.sh .select-group select {
max-width: 154px;
min-width: 166px;
}
.sh .select-group select + select {
margin-left: 5px;
}
.sh .select-group + .select-group {
margin-left: 30px;
}
.sh .input-group {
min-width: 364px;
}
.sh .button-group {
display: flex;
flex-wrap: wrap;
flex-direction: row;
align-items: center;
margin-top: 2px;
}
.sh .button-group button {
margin-right: 5px;
margin-bottom: 5px;
}
.sh .button-group button:focus {
outline: 1px dashed #fff;
outline-offset: -3px;
}
@media (max-width: 767px) {
.sh .button-group {
width: 100%;
display: inline-block;
margin-top: 5px;
}
.sh .button-group button {
margin-right: 0;
}
}
.sh__button {
min-width: 86px;
height: 40px;
line-height: 1.4;
text-align: center;
font-weight: 500;
color: #fff;
border-radius: 3px;
background-color: #666;
}
.sh__button.checked {
background-color: #222;
}
@media (max-width: 767px) {
.sh__button {
height: 35px;
width: calc(25% - 5px);
min-width: auto;
}
}
.sh .inp-sh-set {
display: flex;
}
.sh .inp-sh-set select {
max-width: 154px;
}
.sh .inp-sh-set select + input[type=text] {
margin-left: 4px;
}
.sh .inp-sh-set input[type=text] {
margin-right: 5px;
}
.sh .inp-sh-set .select-group {
width: 270px;
}
.sh .inp-sh-set .b-reset {
padding: 0;
margin-left: 10px;
}
@media (max-width: 767px) {
.sh .inp-sh-set {
display: block;
}
.sh .inp-sh-set select {
max-width: inherit;
margin-bottom: 5px;
}
.sh .inp-sh-set select + input[type=text] {
margin-left: 0;
}
.sh .inp-sh-set input[type=text] {
margin-right: 0;
width: calc(100% - 60px);
}
.sh .inp-sh-set .b-sh {
margin-left: -6px;
}
.sh .inp-sh-set.reset-type input[type=text] {
width: calc(100% - 121px);
}
.sh .inp-sh-set.reset-type .b-reset {
margin-left: 0;
line-height: 20px;
}
}
.sh.l {
display: flex;
align-items: center;
flex-direction: row;
}
.sh.l .select-group {
min-width: 267px;
}
.sh.l .select-group select {
min-width: 154px;
max-width: 100%;
}
.sh.l .select-group + .inp-sh-set {
margin-left: 20px;
width: calc(100% - 264px);
}
.sh.l .select-group.md {
min-width: 313px;
}
.sh.l .select-group.md select {
min-width: 166px;
max-width: 100%;
}
.sh.l .inp-sh-set {
width: 100%;
}
.sh.l .search-detail__dateset {
display: inline-block;
}
.sh.l .search-detail__dateset + .inp-sh-set {
width: calc(100% - 428px);
}
@media (max-width: 1275px) {
.sh.l .search-detail__dateset + .inp-sh-set {
width: 100%;
margin-left: 0;
}
}
@media (max-width: 1199px) {
.sh.l .search-detail__dateset + .inp-sh-set {
width: 100%;
}
.sh.l .search-detail__dateset .label {
width: 83px;
}
}
@media (max-width: 767px) {
.sh.l .search-detail__dateset .label {
width: 50px;
}
}
@media (max-width: 500px) {
.sh.l .search-detail__dateset .label {
display: block;
}
.sh.l .search-detail__dateset #startDate,
.sh.l .search-detail__dateset #endDate {
width: calc(50% - 11px);
}
}
@media (max-width: 1275px) {
.sh.l {
display: block;
}
.sh.l .select-group + .inp-sh-set {
width: 100%;
margin-left: 0;
margin-top: 10px;
}
.sh.l .inp-sh-set {
width: 100%;
}
}
.sh__group {
display: inline-block;
}
.sh__group + .sh__group {
margin-left: 15px;
}
@media (max-width: 767px) {
.sh__group + .sh__group {
margin-left: 0;
}
}
.sh__group .select-group, .sh__select-input .select-group {
display: inline-block;
min-width: 158px;
}
.sh__group .select-group select, .sh__select-input .select-group select {
width: 100%;
}
.sh__group input[type=text], .sh__select-input input[type=text] {
width: 240px;
}
.sh__group .btn-boardsearch,
.sh__group .b-sh,
.sh__group .b-view, .sh__select-input .btn-boardsearch,
.sh__select-input .b-sh,
.sh__select-input .b-view {
min-width: 60px;
background-color: #666666;
padding: 8px 10px;
font-size: 16px;
}
.sh__group .btn-boardsearch:focus,
.sh__group .b-sh:focus,
.sh__group .b-view:focus, .sh__select-input .btn-boardsearch:focus,
.sh__select-input .b-sh:focus,
.sh__select-input .b-view:focus {
outline: 1px dashed #fff;
outline-offset: -3px;
}
@media (max-width: 767px) {
.sh__group .select-group, .sh__select-input .select-group {
min-width: 98px;
max-width: 98px;
}
.sh__group .btn-boardsearch,
.sh__group .b-sh,
.sh__group .b-view, .sh__select-input .btn-boardsearch,
.sh__select-input .b-sh,
.sh__select-input .b-view {
min-width: 40px;
}
}
@media (max-width: 360px) {
.sh__group .select-group, .sh__select-input .select-group {
min-width: 73px;
}
.sh__group .select-group select, .sh__select-input .select-group select {
font-size: 14px;
}
}
@media (max-width: 1199px) {
.sh {
margin: 0 -20px 30px;
padding: 20px;
}
}

.search-detail__dateset #startDate,
.search-detail__dateset #endDate {
width: calc(50% - 15px);
max-width: 145px;
}
.search-detail__dateset #startDate::placeholder,
.search-detail__dateset #endDate::placeholder {
color: rgb(34, 34, 34);
letter-spacing: -0.6px;
font-weight: 300;
}
dd + .search-detail__dateset {
width: calc(100% - 450px);
}
.sh .search-detail__dateset {
margin-bottom: 2px;
margin-right: 20px;
min-width: 408px;
}
.sh .search-detail__dateset .label {
font-weight: 500;
width: 90px;
display: inline-block;
}
.sh .search-detail__dateset + .inp-sh-set {
width: calc(100% - 428px);
}
@media (max-width: 1199px) {
.sh .search-detail__dateset + .inp-sh-set {
width: 100%;
margin-top: 10px;
}
}
@media (max-width: 767px) {
.sh .search-detail__dateset {
margin-right: 0;
min-width: 100%;
}
.sh .search-detail__dateset .label {
width: 50px;
}
.sh .search-detail__dateset #startDate,
.sh .search-detail__dateset #endDate {
width: calc(50% - 14px - 25px);
max-width: 100%;
}
}
@media (max-width: 500px) {
.sh .search-detail__dateset .label {
display: block;
}
.sh .search-detail__dateset #startDate,
.sh .search-detail__dateset #endDate {
width: calc(50% - 11px);
}
}

colgroup col.no {
width: 100px;
}
colgroup col.count {
width: 120px;
}
colgroup col.files,
colgroup col.names {
width: 120px;
}
colgroup col.depts,
colgroup col.day {
width: 110px;
}
colgroup col.days {
width: 190px;
}
colgroup col.processing {
width: 120px;
}
colgroup col.public {
width: 120px;
}
colgroup col.btns {
width: 130px;
}
@media (max-width: 767px) {
colgroup {
display: none;
}
}

.bd-list {
margin-top: 20px;
border-top: 2px solid #777;
border-bottom: 1px solid #ddd;
font-weight: 300;
}
.bd-list__title {
display: flex;
justify-content: space-between;
align-items: flex-end;
margin-top: 50px;
}
.bd-list__title + .link-list {
margin-top: 20px;
border-top: 2px solid #777;
}
@media (max-width: 767px) {
.bd-list__title {
margin-top: 27px;
align-items: flex-start;
flex-direction: column-reverse;
}
}
.bd-list__total {
color: #000;
font-size: 16px;
letter-spacing: -0.4px;
text-align: right;
}
.bd-list__total.bdbtm {
padding-top: 40px;
padding-bottom: 20px;
border-bottom: 2px solid rgb(102, 102, 102);
}
.bd-list__total.page-total {
margin-top: 40px;
}
@media (max-width: 767px) {
.bd-list__total {
position: relative;
padding-left: 25px;
padding-bottom: 10px;
font-size: 28px;
font-weight: 700;
letter-spacing: -0.55px;
border-bottom: 2px solid #dddddd;
border-bottom-width: 0;
font-weight: 500;
text-align: left;
}
.bd-list__total::before {
content: "";
width: 3px;
height: 18px;
background-color: #f65626;
position: absolute;
top: 13px;
left: 0;
}
.bd-list__total.bdnone {
border-bottom-width: 0px;
}
.bd-list__total.bdbcolor {
border-bottom-color: #666;
}
}
@media (max-width: 767px) and (max-width: 767px) {
.bd-list__total {
font-size: 20px;
}
.bd-list__total::before {
top: 6px;
}
}
@media (max-width: 767px) {
.bd-list__total.page-total {
margin-top: 30px;
padding-bottom: 0;
}
}
@media (max-width: 767px) {
.bd-list__sh .sh__select-input input {
width: calc(100% - 169px);
}
.bd-list__sh .b-sh {
min-width: 56px;
padding: 0 15px;
}
.bd-list__sh .b-sh::before {
content: none;
}
}
.bd-list a:focus, .bd-list a:hover, .bd-list a:active {
color: #f65626;
}
.bd-list + .nodata {
margin-top: -1px;
}
.bd-list table {
width: 100%;
text-align: center;
table-layout: fixed;
}
@media (max-width: 767px) {
.bd-list table {
text-align: left;
display: block;
}
}
@media (max-width: 767px) {
.bd-list table thead {
display: none;
}
}
.bd-list table tr {
height: 59px;
font-size: 16px;
}
.bd-list table th {
background-color: #fff;
color: #333;
font-size: 1.125rem;
font-weight: 700;
}
.bd-list table tbody {
color: #000;
}
@media (max-width: 767px) {
.bd-list table tbody {
display: block;
}
}
@media (max-width: 767px) {
.bd-list table tbody tr {
position: relative;
padding: 16px 0;
height: auto;
display: block;
border-top: 1px solid #ddd;
}
.bd-list table tbody tr:first-child {
border-top: none;
}
.bd-list table tbody tr.nodata {
display: block;
text-align: center;
}
.bd-list table tbody tr.nodata td {
display: inline-block;
}
}
.bd-list table tbody tr.notice {
background-color: rgb(248, 248, 248);
}
.bd-list table tbody tr.notice .no i {
width: 20px;
height: 18px;
background: url(/static/kogl_alright/img/common/icon-notice.png) 0 0 no-repeat;
}
.bd-list table tbody tr.notice .title a {
color: #f65626;
}
.bd-list table tbody td {
padding-bottom: 3px;
}
@media (max-width: 767px) {
.bd-list table tbody td.title2 {
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
display: inline-block;
width: 100%;
}
}
.bd-list table tbody td.title {
text-align: left;
}
.bd-list table tbody td.title a,
.bd-list table tbody td.title p {
display: block;
margin: 0;
text-align: left;
font-size: 16px;
font-weight: 400;
line-height: normal;
}
.bd-list table tbody td.title a:hover, .bd-list table tbody td.title a:focus, .bd-list table tbody td.title a:active,
.bd-list table tbody td.title p:hover,
.bd-list table tbody td.title p:focus,
.bd-list table tbody td.title p:active {
color: #222;
text-decoration: underline;
}
@media (min-width: 1200px) {
.bd-list table tbody td.title a,
.bd-list table tbody td.title p {
padding-left: 20px;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
}
}
@media (min-width: 768px) {
.bd-list table tbody td.title a.md-tac,
.bd-list table tbody td.title p.md-tac {
max-width: 280px;
padding-left: 0;
text-align: center;
}
}
@media (max-width: 767px) {
.bd-list table tbody td.title a,
.bd-list table tbody td.title p {
line-height: 1.2;
font-size: 14px;
}
}
.bd-list table tbody td.title a .round-box,
.bd-list table tbody td.title p .round-box {
vertical-align: middle;
height: 34px;
line-height: 34px;
}
.bd-list table tbody td.title .blidetxt {
display: block;
margin: 0;
text-align: left;
color: #666;
font-size: 1rem;
font-weight: 400;
letter-spacing: -0.033rem;
line-height: normal;
}
@media (min-width: 1200px) {
.bd-list table tbody td.title .blidetxt {
padding-left: 20px;
}
}
@media (min-width: 1200px) {
.bd-list table tbody td.bd-l {
padding-left: 10px;
border-left: 1px solid #ddd;
}
}
@media (min-width: 768px) {
.bd-list table tbody td {
border-top: 1px solid #ddd;
}
}
@media (max-width: 767px) {
.bd-list table tbody td {
padding-left: 5px;
padding-right: 5px;
padding-bottom: 5px;
display: block;
}
.bd-list table tbody td.no, .bd-list table tbody td.num {
display: none;
}
.bd-list table tbody td.td-dib {
display: inline-block;
}
.bd-list table tbody td.td-dib-last {
display: inline-block;
width: 50%;
}
}
.bd-list table tbody .file {
display: inline-block;
margin-top: 8px;
width: 30px;
height: 30px;
background: url(/static/kogl_alright/img/common/icon-down.png) 0 0 no-repeat;
background-size: contain;
}
@media (max-width: 767px) {
.bd-list table tbody .file {
margin-top: 0;
width: 20px;
height: 18px;
vertical-align: middle;
}
}
.bd-list .status {
font-weight: 500;
}
.bd-list .status.submit {
color: #446da5;
}
.bd-list .status.ing {
color: #00b194;
}
.bd-list .status.use {
color: #000;
}
.bd-list .status.return {
color: #e24d4d;
}
.bd-list .sub-type {
display: inline-block;
text-align: center;
padding: 0 0 1px;
min-width: 64px;
color: #ffffff;
border-radius: 3px;
margin-right: 10px;
}
.bd-list .sub-type.type1 {
background-color: #222222;
}
.bd-list .sub-type.type2 {
background-color: #f65626;
}
.bd-list .sub-type.type3 {
background-color: #29a400;
}
.bd-list .title {
text-align: left;
}
.bd-list .title a,
.bd-list .title p {
position: relative;
vertical-align: middle;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
display: inline-block;
}
@media (max-width: 767px) {
.bd-list .title a,
.bd-list .title p {
width: 100%;
}
}
.bd-list .title a .new,
.bd-list .title p .new {
position: absolute;
display: inline-block;
background-color: #e24d4d;
border-radius: 3px;
width: 6px;
height: 6px;
line-height: 19px;
vertical-align: top;
margin-left: 5px;
}
@media (min-width: 768px) {
.bd-list .title a.nomal,
.bd-list .title p.nomal {
overflow: visible;
width: auto;
white-space: normal;
padding-right: 0;
}
}
@media (min-width: 768px) {
.bd-list .title a.nomal .new,
.bd-list .title p.nomal .new {
position: static;
margin: 0;
margin-left: 5px;
vertical-align: middle;
display: inline-block;
}
}
.bd-list .title .new {
position: absolute;
display: inline-block;
background-color: #e24d4d;
border-radius: 3px;
width: 6px;
height: 6px;
line-height: 19px;
vertical-align: top;
margin-left: 5px;
}
.bd-list__item {
display: flex;
width: 100%;
border-bottom: 1px solid #ddd;
padding: 30px 0;
}
.bd-list__item a:hover, .bd-list__item a:active, .bd-list__item a:focus {
text-decoration: underline;
color: #000;
}
.bd-list__thumbnail {
position: relative;
display: inline-block;
vertical-align: top;
min-width: 240px;
position: relative;
overflow: hidden;
margin-right: 30px;
border: 1px solid #dddddd;
background-repeat: no-repeat;
background-size: cover;
background-position: center;
}
.bd-list__thumbnail::before {
content: "";
display: block;
height: 0;
padding-top: 100%;
}
.bd-list__thumbnail.on .bd-list__layer {
display: inline-block;
}
.bd-list__thumbnail:focus {
outline: 1px dashed #000;
outline-offset: 1px;
}
.bd-list__layer {
z-index: 20;
display: none;
position: absolute;
bottom: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(34, 34, 34, 0.8);
}
.bd-list__layer button {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
}
.bd-list__layer button.b-preview {
min-width: 184px;
height: 40px;
border-radius: 3px;
color: #000;
font-size: 18px;
font-weight: 700;
letter-spacing: -0.45px;
padding-left: 45px;
padding-top: 6px;
background: #fff url(/static/kogl/img/common/icon-preview.png) 45px center no-repeat;
}
.bd-list__layer button:focus {
outline: 1px dashed #000;
outline-offset: -3px;
}
.bd-list__info {
width: calc(100% - 240px - 70px);
display: inline-flex;
flex-direction: column;
}
.bd-list__info .date {
margin-top: auto;
text-align: right;
}
.bd-list__agency span {
display: inline-block;
background-color: rgba(246, 86, 38, 0.1);
color: #f65626;
font-weight: 700;
text-align: center;
padding: 3px 20px;
}
.bd-list__tit {
margin: 30px 0 0;
min-height: 60px;
}
.bd-list__tit a {
color: #000;
font-size: 20px;
font-weight: 700;
letter-spacing: -0.5px;
width: 100%;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
display: inline-block;
}
.bd-list__tit.icon {
position: relative;
padding-left: 55px;
padding-top: 10px;
padding-bottom: 9px;
background: url(/static/kogl/img/common/icon-linktitle.png) 0 center no-repeat;
}
.bd-list__text {
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
white-space: unset;
overflow: hidden;
color: #333333;
font-size: 18px;
font-weight: 400;
line-height: 1.67;
height: 56px;
letter-spacing: -0.45px;
margin-top: 10px;
}
@media (max-width: 767px) {
.bd-list__item {
display: block;
}
.bd-list__thumbnail {
width: 100%;
margin-right: 0;
}
.bd-list__info {
width: 100%;
padding-top: 20px;
}
.bd-list__info .date {
text-align: left;
margin-top: 20px;
}
.bd-list__tit {
margin-top: 15px;
}
.bd-list__text {
margin-top: 0;
}
}
.bd-list__url {
color: #000;
font-size: 1.125rem;
font-weight: 500;
}
.bd-list__url a {
color: #000;
font-size: 1.125rem;
font-weight: 500;
display: inline;
}
.bd-list#agencyList .agency__item.open .agency__btn {
background-color: #e65a1d;
}
.bd-list#agencyList .agency__item.open .agency__btn::before {
content: "\f0d8";
line-height: 1.5;
}
.bd-list#agencyList .agency__item.open .agency__content {
display: block;
}
.bd-list#agencyList .agency__item .agency__content {
display: none;
}
.bd-list#agencyList .agency__thunmblist {
position: relative;
}
.bd-list#agencyList .agency__btn {
position: absolute;
right: 0;
top: -35px;
border-radius: 3px;
background-color: #666;
width: 72px;
height: 35px;
}
.bd-list#agencyList .agency__btn::before {
content: "\f0d7";
font-family: "FontAwesome", "dotum", Helvetica, Arial, sans-serif;
color: #fff;
}
.bd-list#agencyList .agency__content {
background-color: rgba(230, 90, 29, 0.05);
border-bottom: 1px solid #dadada;
padding: 20px;
}
@media (min-width: 1500px) {
.bd-list#agencyList .agency__content {
padding: 50px 60px;
}
}
@media (max-width: 360px) {
.bd-list#agencyList .agency__content {
padding: 20px 10px;
}
}
.bd-list#agencyList .agency__swiper {
overflow: hidden;
max-height: 200px;
margin-left: 20px;
margin-right: 20px;
text-align: center;
}
@media (min-width: 1500px) {
.bd-list#agencyList .agency__swiper {
margin-left: 50px;
margin-right: 50px;
}
}
.bd-list#agencyList .agency__swiper-button-prev, .bd-list#agencyList .agency__swiper-button-next {
position: absolute;
top: 50%;
margin-top: -15px;
width: 15px;
height: 25px;
background: url(/static/kogl_alright/img/sub/agency-swiper-btn.png) 0 0 no-repeat;
}
.bd-list#agencyList .agency__swiper-button-prev {
left: 0;
}
.bd-list#agencyList .agency__swiper-button-next {
right: 0;
transform: scaleX(-1);
}
.bd-list#agencyList .agency__swiper .bd-list__item {
display: block;
padding: 0;
border-bottom-width: 0;
}
.bd-list#agencyList .agency__swiper .bd-list__thumbnail {
position: relative;
overflow: hidden;
margin-right: 0;
background-color: #fff;
background-size: contain;
}
.bd-list#agencyList .agency__swiper .bd-list__thumbnail::before {
content: "";
display: block;
height: 0;
padding-top: 66.6666666667%;
}
@media (max-width: 767px) {
.bd-list#agencyList .agency__swiper .bd-list__thumbnail {
width: auto;
height: auto;
min-width: 200px;
}
}
.bd-list#agencyList .agency__swiper .bd-list__tit {
text-align: center;
margin-top: 10px;
}
.bd-list#agencyList .agency__swiper .bd-list__tit a {
color: #333;
font-size: 1.125rem;
font-weight: 500;
}
.bd-list#agencyList .bd-list__thumbnail {
background-size: contain;
}
.bd-list#agencyList .bd-list__info {
padding: 10px 0;
}
.bd-list#agencyList .bd-list__agency a {
color: #000;
font-size: 22px;
font-weight: 700;
}
.bd-list#agencyList .bd-list__infotext {
color: #000;
font-size: 1.125rem;
font-weight: 400;
}
.bd-list#agencyList .bd-list__url {
margin-top: auto;
}
@media (max-width: 767px) {
.bd-list#agencyList .bd-list__thumbnail {
height: 200px;
}
.bd-list#agencyList .bd-list__info {
padding: 20px 0;
}
.bd-list#agencyList .bd-list__infotext {
padding: 20px 0;
}
.bd-list#agencyList .bd-list__url .url-link {
word-break: break-all;
}
}
.bd-list#faqList .q .question {
position: relative;
padding-right: 66px;
word-break: break-word;
text-align: left;
font-weight: 300;
cursor: pointer;
}
.bd-list#faqList .q .question::after {
content: "\f107";
font-family: "Line Awesome Free";
font-weight: 900;
-webkit-font-smoothing: antialiased;
display: inline-block;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1;
color: #000;
font-size: 1.125rem;
position: absolute;
top: 20px;
right: 40px;
}
.bd-list#faqList .q .question:focus {
outline: 1px dashed #000;
outline-offset: -2px;
}
.bd-list#faqList .q.open td {
color: #f65626;
}
.bd-list#faqList .q.open .question {
font-weight: 500;
}
.bd-list#faqList .q.open .question::after {
content: "\f106";
color: #f65626;
}
.bd-list#faqList .a {
display: none;
}
.bd-list#faqList .a.open {
display: table-row;
background-color: rgb(248, 248, 248);
}
.bd-list#faqList .a td {
padding: 40px 5% 40px 8%;
text-align: left;
}
.bd-list#faqList .a td:focus {
outline: 1px dashed #000;
outline-offset: -3px;
}
@media (max-width: 767px) {
.bd-list#faqList .only-m {
display: none;
}
.bd-list#faqList tr {
display: block;
}
.bd-list#faqList tr td {
display: inline-block;
}
.bd-list#faqList .q td {
width: 30px;
display: inline-block;
}
.bd-list#faqList .q .question {
width: calc(100% - 40px);
padding-right: 30px;
}
.bd-list#faqList .q .question::after {
top: 50%;
margin-top: -10px;
right: 0;
}
.bd-list#faqList .a.open {
display: block;
}
}

.link-list__item {
display: flex;
width: 100%;
border-bottom: 1px solid #ddd;
padding: 30px 0;
}
.link-list__item a:hover, .link-list__item a:active, .link-list__item a:focus {
text-decoration: underline;
}
.link-list__info {
width: 100%;
}
.link-list__thumbnail {
position: relative;
display: inline-block;
vertical-align: top;
min-width: 240px;
position: relative;
overflow: hidden;
margin-right: 30px;
border: 1px solid #dddddd;
background-repeat: no-repeat;
background-size: cover;
background-position: center;
}
.link-list__thumbnail::before {
content: "";
display: block;
height: 0;
padding-top: 83.3333333333%;
}
.link-list__thumbnail + .link-list__name {
width: calc(100% - 240px - 40px);
padding: 20px 10px 0 0;
}
.link-list__thumbnail.on .link-list__layer {
display: inline-block;
}
.link-list__layer {
z-index: 20;
display: none;
position: absolute;
bottom: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(34, 34, 34, 0.8);
}
.link-list__layer button {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
}
.link-list__layer button.b-preview {
min-width: 184px;
height: 40px;
border-radius: 3px;
color: #000;
font-size: 18px;
font-weight: 700;
letter-spacing: -0.45px;
padding-left: 45px;
background: #fff url(/static/kogl/img/common/icon-preview.png) 45px center no-repeat;
}
.link-list__layer button.b-preview:focus {
outline: 1px dashed #000;
outline-offset: -3px;
}
.link-list__name {
display: inline-flex;
flex-direction: column;
padding: 10px 10px 10px 0;
height: 100%;
min-height: 192px;
width: 100%;
}
.link-list__title a {
color: #000;
font-size: 20px;
font-weight: 700;
letter-spacing: -0.5px;
width: 100%;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
display: inline-block;
}
.link-list__title.icon {
position: relative;
padding-left: 55px;
padding-top: 10px;
padding-bottom: 9px;
background: url(/static/kogl/img/common/icon-linktitle.png) 0 center no-repeat;
}
.link-list__text {
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
white-space: unset;
overflow: hidden;
color: #333333;
font-size: 18px;
font-weight: 400;
line-height: 1.67;
height: 56px;
letter-spacing: -0.45px;
margin-top: 10px;
}
.link-list__tag {
margin-top: auto;
}
.link-list__tag span {
display: inline-block;
font-size: 16px;
}
.link-list__tag span.tag {
margin-top: 5px;
min-width: calc(25% - 10px);
width: auto;
}
.link-list__tag span.tag .dt {
background-color: rgba(246, 86, 38, 0.1);
color: #f65626;
font-weight: 700;
text-align: center;
padding: 3px 20px;
}
.link-list__tag span.tag .dt + .dd {
margin-left: 30px;
margin-right: 30px;
}
.link-list__tag span.tag .dd {
color: #000;
font-weight: 500;
}
@media (max-width: 991px) {
.link-list__item {
display: block;
padding: 20px 0;
}
.link-list__info {
width: 100%;
}
.link-list__subinfo {
display: block;
width: 100%;
min-height: auto;
padding: 20px 0 0;
margin: 0;
}
.link-list__subinfo::before {
height: 2px;
width: 100%;
background: url(/static/kogl/img/common/dot-line2.png) 0 0 repeat-x;
}
.link-list__name {
padding: 0 0 20px;
}
.link-list__thumbnail {
height: 320px;
min-width: 100%;
margin-right: 0;
}
.link-list__thumbnail + .link-list__name {
width: 100%;
padding: 20px 0 20px 0;
}
.link-list__title {
/* a {
	display: block;
	white-space: inherit;
} */
}
}

.board-list__item {
border-bottom: 1px solid #ddd;
padding: 30px 20px;
}
.board-list__title {
color: #000;
font-size: 20px;
font-weight: 700;
}
.board-list__title .board-type {
display: inline-block;
vertical-align: top;
font-size: 20px;
font-weight: 700;
}
.board-list__title a {
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
display: inline-block;
color: #000;
}
@media (max-width: 1199px) {
.board-list__title a {
display: block;
}
}
.board-list__text {
overflow: hidden;
text-overflow: ellipsis;
display: block;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
color: #333333;
font-size: 18px;
font-weight: 400;
line-height: 1.67;
letter-spacing: -0.45px;
margin-top: 5px;
}
.ie .board-list__text {
display: block;
height: 18px;
overflow: hidden;
}
.board-list__bottom {
margin-top: 20px;
display: flex;
justify-content: space-between;
align-items: flex-end;
}
.board-list__bottom .date {
margin-bottom: 5px;
}
@media (max-width: 767px) {
.board-list__item {
padding: 20px 0;
}
.board-list__bottom {
display: block;
}
.board-list.type2 .board-list__item a {
display: flex;
flex-wrap: wrap;
}
.board-list.type2 .board-list__thumnail {
min-width: 100%;
margin-right: 0;
}
.board-list.type2 .board-list__thumnail + .board-list__name {
width: 100%;
padding: 8px 10px 6px 0;
}
}

.date {
font-size: 16px;
font-weight: 500;
color: #000;
}
.date::before {
content: "\f017";
font-family: "Line Awesome Free";
font-weight: 900;
-webkit-font-smoothing: antialiased;
display: inline-block;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1;
font-size: 25px;
margin-right: 5px;
margin-bottom: 3px;
vertical-align: middle;
}

.bd-thumbnail {
padding-top: 38px;
margin-top: 20px;
border-top: 2px solid #777;
}
.bd-thumbnail__list {
margin: 0 -20px;
}
.bd-thumbnail__list li {
display: inline-block;
width: calc(33.3333333333% - 40px);
margin: 0 17px 40px;
position: relative;
border: 1px solid #ddd;
}
.bd-thumbnail__status {
position: absolute;
top: 0;
left: 0;
z-index: 10;
min-width: 77px;
padding-top: 1px;
padding-left: 14px;
padding-right: 14px;
height: 30px;
color: rgb(255, 255, 255);
font-size: 1.125rem;
font-weight: 500;
background-color: #f65626;
text-align: center;
}
.end .bd-thumbnail__status {
background-color: #000;
}
.bd-thumbnail__img {
display: block;
}
#eventList .bd-thumbnail__img {
position: relative;
overflow: hidden;
}
#eventList .bd-thumbnail__img::before {
content: "";
display: block;
height: 0;
padding-top: 42.5%;
}
#eventList .bd-thumbnail__img img {
position: absolute;
left: 0;
top: 50%;
transform: translateY(-50%);
width: 100%;
vertical-align: top;
}
#promotionList .bd-thumbnail__img {
min-width: 400px;
position: relative;
overflow: hidden;
}
#promotionList .bd-thumbnail__img::before {
content: "";
display: block;
height: 0;
padding-top: 87.5%;
}
#promotionList .bd-thumbnail__img img {
position: absolute;
left: 0;
top: 50%;
transform: translateY(-50%);
width: 100%;
vertical-align: top;
}
#tipList .bd-thumbnail__img {
min-width: 400px;
position: relative;
overflow: hidden;
}
#tipList .bd-thumbnail__img::before {
content: "";
display: block;
height: 0;
padding-top: 87.5%;
}
#tipList .bd-thumbnail__img img {
position: absolute;
left: 0;
top: 50%;
transform: translateY(-50%);
width: 100%;
vertical-align: top;
}
.end .bd-thumbnail__img {
-webkit-filter: grayscale(100%) brightness(50%);
filter: grayscale(100%) brightness(50%);
filter: grayscale(100%);
filter: gray;
/* IE6-9 */
-webkit-filter: grayscale(1);
}
.bd-thumbnail__subject {
padding: 0 30px 30px;
}
.bd-thumbnail__date {
display: inline-block;
width: 100%;
text-align: center;
background-color: rgba(0, 165, 137, 0.1);
padding: 20px 0;
margin-top: 30px;
}
.bd-thumbnail__date .tit {
display: inline-block;
padding-left: 32px;
color: #f65626;
font-weight: 700;
background: url(/static/kogl/img/common/icon-clock-color.png) 0 center no-repeat;
}
.bd-thumbnail__date .date {
display: block;
}
.end .bd-thumbnail__date {
background-color: #f6f6f6;
}
.end .bd-thumbnail__date .tit {
color: #000;
background-image: url(/static/kogl/img/sub/icon-clock.png);
}
.bd-thumbnail__title {
margin-top: 20px;
}
.bd-thumbnail__title span {
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
white-space: unset;
overflow: hidden;
color: #333333;
height: 50px;
font-size: 1.125rem;
}

.only-m {
display: none;
}
@media (max-width: 767px) {
.only-m {
display: inline-block;
vertical-align: top;
padding-right: 8px;
position: relative;
color: #767676;
}
.only-m:after {
content: "";
width: 1px;
height: 10px;
background-color: #ddd;
position: absolute;
right: 4px;
top: 6px;
}
}

.bd-view {
border-bottom: 1px solid #dadada;
}
.bd-view__title {
padding-bottom: 25px;
border-bottom: 2px solid #000;
}
.bd-view__title .subject {
position: relative;
padding-left: 25px;
padding-bottom: 10px;
font-size: 28px;
font-weight: 700;
letter-spacing: -0.55px;
border-bottom: 2px solid #dddddd;
border-bottom-width: 0px;
padding-left: 10px;
padding-bottom: 0;
font-size: 20px;
font-weight: 700;
display: inline-block;
width: calc(100% - 360px);
}
.bd-view__title .subject::before {
content: "";
width: 3px;
height: 18px;
background-color: #f65626;
position: absolute;
top: 13px;
left: 0;
}
.bd-view__title .subject.bdnone {
border-bottom-width: 0px;
}
.bd-view__title .subject.bdbcolor {
border-bottom-color: #666;
}
@media (max-width: 767px) {
.bd-view__title .subject {
font-size: 20px;
}
.bd-view__title .subject::before {
top: 6px;
}
}
.bd-view__title .subject::before {
top: 7px;
}
.bd-view__title .subject-sub {
display: inline-block;
width: 350px;
text-align: right;
}
.bd-view__title .subject-sub span {
display: inline-block;
vertical-align: middle;
font-weight: 700;
line-height: 1.5;
letter-spacing: -0.4px;
}
.bd-view__title .subject-sub span + span::before {
content: "";
display: inline-block;
margin: -2px 10px;
width: 1px;
height: 15px;
background-color: rgb(102, 102, 102);
}
@media (max-width: 1199px) {
.bd-view__title {
padding-top: 10px;
padding-bottom: 10px;
border-top-width: 0;
}
.bd-view__title .subject,
.bd-view__title .subject-sub {
display: block;
width: 100%;
}
.bd-view__title .subject {
font-size: 20px;
}
.bd-view__title .subject::before {
top: 6px;
}
.bd-view__title .subject-sub {
margin-top: 10px;
}
}
@media (max-width: 767px) {
.bd-view__title .subject-sub span {
font-size: 15px;
}
.bd-view__title .subject-sub span + span::before {
margin: -2px 5px;
}
}
.bd-view__table-dl .file-list {
display: flex;
flex-direction: row;
}
.bd-view__table-dl .file-list dt {
width: 140px;
background: #f8f8f8;
display: flex;
align-items: center;
justify-content: center;
color: #333333;
font-weight: 700;
}
.bd-view__table-dl .file-list dd {
display: inline-block;
width: calc(100% - 140px);
padding: 30px;
}
.bd-view__table-dl .file-list dd li {
line-height: 1.5;
padding-left: 27px;
text-indent: -15px;
}
.bd-view__table-dl .file-list dd li::before {
content: "\f019";
font-family: "Line Awesome Free";
font-weight: 900;
-webkit-font-smoothing: antialiased;
display: inline-block;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1;
font-size: 25px;
vertical-align: middle;
margin-right: 5px;
}
.bd-view__table-dl .file-list dd li a {
color: #333333;
font-size: 16px;
font-weight: 300;
box-shadow: inset 0 -1px 0 #000;
word-break: break-all;
display: inline;
}
.bd-view__table-dl .file-list dd li + li {
margin-top: 10px;
}
.bd-view__table-dl-list {
padding: 24px;
}
.bd-view__table-dl-list li + li {
margin-top: 5px;
}
.bd-view__table-dl-list .tit {
color: #333333;
font-weight: 700;
font-size: 1.125rem;
margin-right: 20px;
}
.bd-view__table-dl-list .tit::before {
font-family: "Line Awesome Free";
font-weight: 900;
-webkit-font-smoothing: antialiased;
display: inline-block;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1;
vertical-align: middle;
font-size: 25px;
margin-right: 10px;
}
.bd-view__table-dl-list .tit.date::before {
content: "\f017";
}
.bd-view__table-dl-list .tit.win::before {
content: "\f0a1";
}
.bd-view__table-dl-list .tit.way::before {
content: "\f570";
}
.bd-view__table-dl-list .tit + span {
font-weight: 300;
padding-left: 20px;
position: relative;
}
.bd-view__table-dl-list .tit + span::before {
content: "";
position: absolute;
left: 0;
top: 5px;
width: 1px;
height: 15px;
background-color: rgb(102, 102, 102);
}
.bd-view .dbdata {
border-top: 1px solid #dadada;
min-height: 200px;
padding: 50px 40px 20px;
word-break: break-all;
}
.bd-view .box__blue {
margin: 0 40px 60px;
}
@media (max-width: 767px) {
.bd-view__table-dl .file-list dt {
width: 90px;
}
.bd-view__table-dl .file-list dd {
width: calc(100% - 90px);
padding: 20px;
}
.bd-view .dbdata {
padding: 20px 10px;
}
}

.sns-link {
text-align: right;
padding: 20px 0;
}
.sns-link span {
width: 41px;
height: 40px;
border-radius: 100%;
display: inline-block;
}
.sns-link span a {
display: block;
}
.sns-link span.facebook {
background-image: url(/static/commons/img/icon-facebook.png);
}
.sns-link span.twitter {
background-image: url(/static/commons/img/icon-twitter.png);
}
.sns-link span.kakao {
background-image: url(/static/commons/img/icon-kakao.png);
}

.bd-view-nav {
margin: 50px 0;
border-width: 1px 0;
border-style: solid;
border-color: #666666;
display: block;
}
.bd-view-nav:before, .bd-view-nav:after {
content: ".";
display: block;
height: 0;
clear: both;
visibility: hidden;
}
.bd-view-nav dl + dl {
border-top: 1px solid #ddd;
}
.bd-view-nav dl {
padding: 7px 0;
height: 50px;
display: table;
table-layout: fixed;
width: 100%;
}
.bd-view-nav dl > * {
display: table-cell;
vertical-align: top;
line-height: 36px;
}
.bd-view-nav dl dt {
position: relative;
width: 140px;
color: #000;
font-size: 16px;
font-weight: 500;
letter-spacing: -0.24px;
padding-left: 53px;
}
.bd-view-nav dl dt::before {
content: "";
position: absolute;
top: 50%;
margin-top: -3.5px;
left: 34px;
background: url(/static/kogl/img/common/icon-bd-view-nav.png);
width: 10px;
height: 5px;
}
.bd-view-nav dl dd {
letter-spacing: -0.24px;
padding-left: 20px;
}
.bd-view-nav dl dd span {
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
display: inline-block;
width: 100%;
vertical-align: middle;
font-weight: 300;
}
.bd-view-nav dl dd a {
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
display: inline-block;
display: block;
width: 100%;
}
.bd-view-nav dl.next dt::before {
transform: scaleY(-1);
}
@media (max-width: 767px) {
.bd-view-nav dl {
width: 100%;
padding: 16px 0;
}
.bd-view-nav dl dt {
width: 90px;
padding-left: 23px;
border-right: 1px solid #e6e6e6;
}
.bd-view-nav dl dt::before {
left: 6px;
top: 17px;
margin-top: 0;
}
.bd-view-nav dl dd {
padding-left: 15px;
}
.bd-view-nav dl dd span {
white-space: inherit;
line-height: 1.5;
}
.bd-view-nav dl dd a {
white-space: inherit;
line-height: 1.5;
}
.bd-view-nav dl.next {
border-top: 1px solid #dddddd;
}
}

.bd-reg {
border-top: 2px solid #777;
border-top-width: 2px;
margin-bottom: 30px;
}
.bd-reg input[type=text] + span {
line-height: 40px;
color: #888888;
padding-left: 10px;
}
@media (min-width: 768px) {
.bd-reg input[type=text].w-name {
width: 200px;
}
}
.bd-reg input[type=date] {
width: 100%;
}
@media (min-width: 768px) {
.bd-reg input[type=date].date {
width: 140px;
}
}
.bd-reg input[type=date].md {
width: 220px;
}
.bd-reg input[type=number] {
width: 100%;
}
@media (min-width: 768px) {
.bd-reg input[type=number].membercount {
width: 200px;
}
}
@media (min-width: 768px) {
.bd-reg select.location {
min-width: 200px;
}
}
.bd-reg textarea {
height: 80px;
width: 100%;
}
.bd-reg textarea.h {
height: 300px;
}
.bd-reg .subject-sub {
margin: 0 !important;
padding: 12px 0;
color: #000 !important;
font-size: 18px;
font-weight: 400;
letter-spacing: -0.45px;
line-height: 1.3;
text-align: center;
border-bottom: 1px solid #ddd;
}
.bd-reg .subject-sub::before {
display: none;
}
.bd-reg > dl {
border-bottom: 1px solid #ddd;
}
@media (min-width: 768px) {
.bd-reg > dl {
display: table;
width: 100%;
table-layout: fixed;
}
.bd-reg > dl > * {
display: table-cell;
vertical-align: middle;
}
}
.bd-reg > dl.n > dt {
padding-top: 10px;
}
.bd-reg > dl > dt {
position: relative;
text-align: center;
padding: 20px 0 10px;
font-size: 1.125rem;
color: #222222;
font-size: 16px;
font-weight: 500;
letter-spacing: -0.24px;
vertical-align: top;
}
.dt-lg .bd-reg > dl > dt {
width: 210px;
}
@media (min-width: 768px) {
.bd-reg > dl > dt {
width: 160px;
padding: 13px 0;
}
}
.bd-reg > dl > dt label {
font-weight: inherit;
}
@media (max-width: 767px) {
.bd-reg > dl > dt {
width: auto;
text-align: left;
}
}
@media (min-width: 1200px) {
.bd-reg > dl > dt {
background-color: #f0f0f0;
}
}
.bd-reg > dl:first-child {
border-top: 0;
}
.bd-reg > dl > dd {
padding-bottom: 10px;
position: relative;
}
@media (min-width: 768px) {
.bd-reg > dl > dd {
padding: 5px 20px;
}
}
.bd-reg > dl > dd .deco {
font-size: 1.125rem;
font-weight: 700;
letter-spacing: normal;
line-height: 1.44;
text-indent: -15px;
padding-left: 15px;
}
.bd-reg > dl > dd .deco::before {
content: "";
display: inline-block;
margin-right: 10px;
vertical-align: middle;
}
.bd-reg > dl > dd.dd-payment, .bd-reg > dl > dd.dd-payment form {
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
}
@media (max-width: 767px) {
.bd-reg > dl > dd.dd-payment, .bd-reg > dl > dd.dd-payment form {
align-items: flex-start;
}
.bd-reg > dl > dd.dd-payment .radio-group label, .bd-reg > dl > dd.dd-payment form .radio-group label {
display: block;
}
.bd-reg > dl > dd.dd-payment .radio-group label + label, .bd-reg > dl > dd.dd-payment form .radio-group label + label {
margin-left: 0;
margin-top: 5px;
}
}
.bd-reg > dl > dd.dd-dl {
padding: 0;
}
@media (min-width: 768px) {
.bd-reg > dl > dd.dd-dl > dl {
display: table;
width: 100%;
table-layout: fixed;
border-left: 1px solid #f65626;
}
.bd-reg > dl > dd.dd-dl > dl > * {
display: table-cell;
vertical-align: middle;
}
}
.bd-reg > dl > dd.dd-dl > dl dt {
background-color: #f8fbfd;
text-align: left;
padding: 15px 10px 10px;
font-size: 1.125rem;
color: #222222;
font-size: 16px;
font-weight: 500;
letter-spacing: -0.24px;
vertical-align: top;
}
@media (min-width: 768px) {
.bd-reg > dl > dd.dd-dl > dl dt {
width: 130px;
}
}
@media (max-width: 767px) {
.bd-reg > dl > dd.dd-dl > dl dt {
width: auto;
}
}
.bd-reg > dl > dd.dd-dl > dl dd {
padding: 0 20px;
}
.bd-reg > dl > dd.dd-dl > dl + dl {
border-top: 1px solid #ddd;
}
.bd-reg select,
.bd-reg input[type=text],
.bd-reg input[type=password] {
height: 40px;
}
.bd-reg .inp-txt1 {
width: 100%;
max-width: 400px;
}
.bd-reg .deco {
padding: 4px 0;
}
@media (min-width: 768px) {
.bd-reg.w-dt dt {
width: 300px;
}
}
@media (min-width: 768px) {
.bd-reg.w-dt-md dt {
width: 220px;
}
}
.bd-reg.w-dt-md2 dt {
width: 220px;
}
@media (max-width: 1199px) {
.bd-reg.w-dt-md2 dt,
.bd-reg.w-dt-md2 dd {
display: block;
text-align: left;
}
.bd-reg.w-dt-md2 dt {
width: auto;
}
.bd-reg.w-dt-md2 dd {
padding: 0 0 10px;
}
}
.dialogs .bd-reg input.w50p {
width: 50% !important;
}
.dialogs .bd-reg .date-set input[type=date] {
width: calc(50% - 8px);
}
.dialogs .bd-reg .call-set input[type=text] {
width: calc(33.33% - 8px);
}
.bd-reg.box {
padding: 0;
margin-top: 40px;
}
@media (min-width: 768px) {
.bd-reg.dt-r1 dt {
border-right: 1px solid #ddd;
}
}

.help-star {
color: red;
text-indent: -18px;
padding-left: 18px;
}
.help-star::before {
content: "※ ";
}

.inp-group input[type=text] {
border-right-width: 0;
}
@media (max-width: 767px) {
.inp-group input[type=text] {
width: calc(100% - 80px);
}
.inp-group [class^=b-] {
width: 80px;
}
}

.label-list label.etc span {
margin-right: 10px;
}
.label-list label.etc + input[type=text] {
width: 150px;
}
@media (max-width: 767px) {
.label-list {
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: flex-start;
flex-wrap: wrap;
}
.label-list label {
margin: 5px;
white-space: nowrap;
}
}

.post-set {
max-width: 900px;
display: block;
}
.post-set:before, .post-set:after {
content: ".";
display: block;
height: 0;
clear: both;
visibility: hidden;
}
.post-set span {
display: block;
}
.post-set span:nth-child(1) {
margin-bottom: 10px;
display: block;
}
.post-set span:nth-child(1):before, .post-set span:nth-child(1):after {
content: ".";
display: block;
height: 0;
clear: both;
visibility: hidden;
}
.post-set span:nth-child(1) input {
float: left;
margin-right: 10px;
}
@media (max-width: 767px) {
.post-set span:nth-child(1) input {
width: calc(100% - 170px);
}
}
.post-set span:nth-child(2) {
float: left;
width: 44.4444444444%;
}
@media (max-width: 767px) {
.post-set span:nth-child(2) {
width: 100%;
}
}
.post-set span:nth-child(3) {
float: left;
width: 55.5555555556%;
padding-left: 10px;
}
@media (max-width: 767px) {
.post-set span:nth-child(3) {
width: 100%;
padding-left: 0;
margin-top: 10px;
}
}
.post-set .post {
width: 200px;
}
.post-set .post-search {
display: inline-block;
vertical-align: top;
height: 50px;
line-height: 48px;
text-align: center;
border: 1px solid #464646;
background-color: #ffffff;
width: 160px;
background-color: #ffffff;
}
.post-set .post-search .ico-post {
width: 17px;
height: 16px;
display: inline-block;
vertical-align: middle;
margin-right: 9px;
}
.post-set .post-default {
width: 100%;
}
.post-set .post-detail {
width: 100%;
}

.tel-set {
max-width: 450px;
display: block;
}
.tel-set > * {
float: left;
vertical-align: top;
}
.tel-set:before, .tel-set:after {
content: ".";
display: block;
height: 0;
clear: both;
visibility: hidden;
}
.tel-set .tel1[type=text],
.tel-set select {
width: calc(40% - 40px);
}
@media (min-width: 1200px) {
.tel-set .tel1[type=text],
.tel-set select {
width: calc(40% - 52px);
}
}
.tel-set input.tel {
width: 30%;
}
.tel-set i {
text-align: center;
line-height: 38px;
}
@media (max-width: 1199px) {
.tel-set i {
width: 20px;
}
}
@media (min-width: 1200px) {
.tel-set i {
width: 26px;
}
}
.req-infor {
color: #000;
text-align: right;
margin-bottom: 10px;
}

.req {
color: #525d64;
font-size: 15px;
font-weight: 400;
font-size: smaller;
}
.req::before {
content: "＊ ";
color: red;
display: inline-block;
vertical-align: top;
margin-right: 3px;
position: relative;
}
.req span {
position: absolute;
width: 1px;
height: 1px;
margin: -1px;
padding: 0;
overflow: hidden;
clip: rect(0, 0, 0, 0);
border: 0;
}

.agg-set {
border-top: 2px solid #777;
border-bottom: 1px solid #ddd;
background-color: #f6f6f6;
padding: 40px;
}
.agg-set h3 {
margin-bottom: 10px;
}
.agg-set h3::before {
content: "";
width: 3px;
height: 20px;
background-color: #a27b4c;
border-radius: 1px;
display: inline-block;
margin-right: 14px;
vertical-align: middle;
}
.agg-set .ovy {
margin: 15px 0;
border: 0.063rem solid #ccc;
background-color: #fff;
padding: 20px 18px;
max-height: 293px;
}

/* .file-add {
	float: left;
	width: calc(100% - 106px - 20px);
	@include breakpoint($sm) {
		width: calc(100% - 66px - 20px);
	}
	[class^="b-"] {
		height: 40px;
		// line-height: 40px;
	}
	.file-add-plus {
		float: right;
		button {
			width: 80px !important;
		}
	}
	.b-add {
		float: right;
		width: 106px !important;
		min-width: 0;
		padding: 0;
	}
	.item {
		margin-bottom: 5px;
		& + .item {
		}
		@include clearfix();
		input[type="file"] {
			float: left;
			width: calc(100% - 106px - 5px);
			height: 40px;
			border: 1px solid $bd-color;
		}
		.b-del {
			float: right;
			width: 106px !important;
			min-width: 0;
			padding: 0;
		}
	}
} */
.board-view h2 {
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
white-space: unset;
overflow: hidden;
color: #333333;
position: relative;
padding-left: 25px;
padding-bottom: 10px;
font-size: 28px;
font-weight: 700;
letter-spacing: -0.55px;
border-bottom: 2px solid #dddddd;
position: relative;
padding-left: 20px;
padding-bottom: 0;
max-height: 80px;
font-size: 28px;
font-weight: 700;
line-height: 1.43;
letter-spacing: -0.7px;
border-bottom-width: 0px;
}
.board-view h2::before {
content: "";
width: 3px;
height: 18px;
background-color: #f65626;
position: absolute;
top: 13px;
left: 0;
}
.board-view h2.bdnone {
border-bottom-width: 0px;
}
.board-view h2.bdbcolor {
border-bottom-color: #666;
}
@media (max-width: 767px) {
.board-view h2 {
font-size: 20px;
}
.board-view h2::before {
top: 6px;
}
}
.board-view h2::before {
height: 25px;
}
.board-view__inner {
display: flex;
justify-content: space-between;
}
.board-view__inner .swiper {
max-width: 620px;
width: calc(52% - 20px);
height: auto;
margin: 0;
}
.board-view__inner .board-view__info {
width: calc(48% - 14px);
}
.board-view__inner > h2 {
display: none;
}
@media (max-width: 1275px) {
.board-view__inner {
display: block;
}
.board-view__inner .swiper {
width: 100%;
margin: auto;
}
.board-view__inner .board-view__info {
width: 100%;
}
.board-view__inner .board-view__info > h2 {
display: none;
}
.board-view__inner > h2 {
display: block;
height: auto;
font-size: 20px;
margin-bottom: 20px;
}
.board-view__inner > h2::before {
height: 18px;
top: 6px;
}
}
@media (max-width: 1199px) {
.board-view__inner .swiper {
max-width: 100%;
}
}
.board-view .swiper .swiper-slide {
border: 1px solid rgb(221, 221, 221);
display: inline-block;
overflow: hidden;
}
.board-view .swiper .swiper-slide .img {
background-repeat: no-repeat;
background-position: center;
background-size: contain;
}
.board-view .swiper .swiper-slide.swiper-slide-thumb-active {
opacity: 1;
}
.board-view .swiper .swiper-slide:focus {
outline: 1px dashed;
outline-offset: -1px;
}
.board-view__swiper {
position: relative;
}
.board-view__swiper .swiper-slide .img {
position: relative;
overflow: hidden;
}
.board-view__swiper .swiper-slide .img::before {
content: "";
display: block;
height: 0;
padding-top: 100%;
}
.board-view__swiper .enlarge-btn {
width: 50px;
height: 50px;
background: #000 url(/static/kogl_alright/img/sub/icon-enlarge.png) center no-repeat;
z-index: 10;
position: absolute;
bottom: 0;
right: 0;
}
.board-view__swiper .enlarge-btn:focus {
outline-color: #fff;
outline-offset: -3px;
}
.board-view__thumbsSlider {
margin: 30px 30px 0;
overflow: hidden;
}
.board-view__thumbsSlider .swiper-slide {
opacity: 0.6;
}
.board-view__thumbsSlider .swiper-slide .img {
position: relative;
overflow: hidden;
}
.board-view__thumbsSlider .swiper-slide .img::before {
content: "";
display: block;
height: 0;
padding-top: 100%;
}
.board-view__swiper-ctrl {
position: relative;
}
.board-view__swiper-ctrl button {
position: absolute;
bottom: 75px;
width: 17px;
height: 25px;
background-repeat: no-repeat;
background-size: contain;
}
@media (max-width: 767px) {
.board-view__swiper-ctrl button {
bottom: 45px;
}
}
@media (max-width: 360px) {
.board-view__swiper-ctrl button {
bottom: 25px;
}
}
.board-view__swiper-ctrl button:focus {
outline-offset: -1px;
}
.board-view__swiper-ctrl-button-prev {
left: 0;
background-image: url(/static/kogl_alright/img/sub/prev.png);
}
.board-view__swiper-ctrl-button-next {
right: 0;
background-image: url(/static/kogl_alright/img/sub/next.png);
}
.board-view__status {
margin: 10px 0;
}
.board-view__status p + p {
padding-left: 15px;
margin-left: 10px;
}
.board-view__status p + p::before {
content: "";
position: absolute;
top: 5px;
left: 0;
width: 1px;
height: 15px;
background-color: #666;
}
.board-view__status p {
position: relative;
display: inline-block;
}
.board-view__status p span {
color: #222;
font-weight: 500;
line-height: 1.4;
}
.board-view__status p span.tit::after {
content: ":";
padding: 0 5px;
}
.board-view__agency-title {
font-size: 22px;
font-weight: 700;
margin-top: 130px;
}
.board-view__dl {
border-top: 2px solid #ddd;
border-bottom: 1px solid #ddd;
padding: 28px 0 30px;
margin-top: 40px;
margin-bottom: 60px;
}
.board-view__dl dl {
font-size: 1.125rem;
display: table;
table-layout: fixed;
width: 100%;
}
.board-view__dl dl dt {
display: table-cell;
width: 132px;
font-weight: 500;
padding: 5px 0;
border-right: 1px solid rgba(204, 204, 204, 0.3);
}
.board-view__dl dl dd {
display: table-cell;
word-break: break-all;
color: #f65626;
font-weight: 400;
padding: 5px 0 5px 38px;
}
.board-view__download {
width: 100%;
background-color: #222;
color: #fff;
border-radius: 25px;
text-align: center;
font-size: 20px;
font-weight: 700;
padding: 10px 0;
}
.board-view__download:focus {
outline: 1px dashed #fff;
outline-offset: -3px;
}
.board-view .box__gray {
margin-top: 20px;
margin-bottom: 20px;
text-align: center;
}
.board-view .box__gray .tel-info {
font-size: 20px;
padding: 13px 0 12px;
}
.board-view .box__gray .tel-info::before {
content: "\f2a0";
font-family: "Line Awesome Free";
font-weight: 900;
-webkit-font-smoothing: antialiased;
display: inline-block;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1;
vertical-align: middle;
font-size: 30px;
color: #f65626;
padding-right: 10px;
padding-bottom: 3px;
}
.board-view__content-db-detail {
margin-top: 60px;
border: 1px solid #dddddd;
padding: 20px 60px 60px;
}
.board-view__content-db-detail h3[class^=bu-] {
margin-top: 30px;
}
.board-view .btn-set {
text-align: center;
}
@media (max-width: 1275px) {
.board-view__agency-title {
margin-top: 20px;
}
.board-view__status {
border-bottom: 1px solid #ddd;
padding: 20px 0;
}
.board-view__dl {
padding: 25px 0 25px;
margin-top: 20px;
margin-bottom: 20px;
}
.board-view__dl dl dt {
width: 100px;
}
.board-view__dl dl dd {
padding-left: 19px;
}
.board-view__status p:nth-child(3) {
display: block;
padding-left: 0;
margin-left: 0;
margin-top: 10px;
}
.board-view__status p:nth-child(3)::before {
content: none;
}
}
@media (max-width: 1199px) {
.board-view__content-db-detail {
margin-top: 30px;
padding: 10px 20px 20px;
}
.board-view__content-db-detail h3[class^=bu-] {
margin-top: 20px;
}
}
@media (max-width: 767px) {
.board-view__agency-title {
font-size: 20px;
}
.board-view__download {
font-size: 16px;
}
.board-view .box__gray .tel-info {
font-size: 15px;
}
.board-view__content-db-detail .b-down {
width: 100%;
padding: 14px 0 13px;
}
}
@media (min-width: 1200px) {
.board-view {
display: grid;
}
.board-view .btn-set {
text-align: right;
margin-top: 0;
grid-row: 2/2;
}
}

.reg {
padding: 15px 20px;
border-top: 2px solid #666;
border-bottom: 1px solid #dadada;
}
.reg__h2 {
position: relative;
padding-left: 25px;
padding-bottom: 10px;
font-size: 28px;
font-weight: 700;
letter-spacing: -0.55px;
border-bottom: 2px solid #dddddd;
margin-top: 50px;
}
.reg__h2::before {
content: "";
width: 3px;
height: 18px;
background-color: #f65626;
position: absolute;
top: 13px;
left: 0;
}
.reg__h2.bdnone {
border-bottom-width: 0px;
}
.reg__h2.bdbcolor {
border-bottom-color: #666;
}
@media (max-width: 767px) {
.reg__h2 {
font-size: 20px;
}
.reg__h2::before {
top: 6px;
}
}
.reg__h3 {
position: relative;
padding-left: 22px;
padding-bottom: 20px;
color: #000;
font-size: 20px;
font-weight: 700;
margin-top: 50px;
padding-bottom: 10px;
}
.reg__h3::before {
content: "";
width: 5px;
height: 5px;
background-color: #000;
border-radius: 2px;
position: absolute;
top: 12px;
left: 0;
}
.reg dl {
display: table;
table-layout: fixed;
width: 100%;
padding: 15px 0;
}
.reg dt {
display: inline-block;
vertical-align: top;
position: relative;
padding-left: 10px;
}
.reg dt .required {
position: absolute;
left: 0;
top: 0;
}
.reg dt,
.reg dt label {
width: 200px;
color: #333333;
font-size: 1.125rem;
font-weight: 700;
}
.reg dt .weight,
.reg dt label .weight {
font-weight: 400;
}
.reg dd {
display: inline-block;
width: calc(100% - 200px);
}
@media (max-width: 767px) {
.reg {
padding: 15px 0;
}
.reg__h3 {
margin-top: 30px;
padding-left: 12px;
font-size: 18px;
}
.reg dt {
width: 100%;
}
.reg dd {
display: block;
margin-top: 10px;
width: 100%;
}
}
.reg textarea {
border: none;
width: calc(100% - 20px);
padding: 10px;
font-size: 16px;
max-height: 180px;
word-break: break-all;
}
.reg .check-select li,
.reg .radio-select li {
display: inline-block;
margin-right: 25px;
}
.reg .mail-box input[type=text] {
max-width: 180px;
}
.reg .mail-box select {
min-width: 190px;
}
.reg .mail-box .between {
margin: 0 0 0 8px;
}
@media (max-width: 767px) {
.reg .mail-box input[type=text] {
max-width: 100%;
margin-bottom: 5px;
}
.reg .mail-box select {
min-width: 100%;
}
.reg .mail-box .between {
padding: 0 0 0 5px;
}
}
.reg .phone-box input[type=tel] {
max-width: 110px;
}
.reg .phone-box select {
min-width: 110px;
}
.reg .phone-box .between {
margin: 0 8px 0 0;
}
@media (max-width: 767px) {
.reg .phone-box select,
.reg .phone-box input[type=tel] {
min-width: calc(33% - 12px);
}
.reg .phone-box .between {
margin: 0 1px 0 0;
}
}
.reg .inp-box {
display: inline-block;
margin-left: 20px;
}
.reg .inp-box label {
display: inline-block;
font-weight: 500;
}
.reg .inp-box input {
width: 200px;
margin-left: 10px;
}
@media (max-width: 1199px) {
.reg .inp-box {
width: 100%;
margin-top: 5px;
margin-left: 0;
}
.reg .inp-box input {
width: calc(100% - 74px);
}
}
.reg__select {
min-width: 190px;
}
@media (max-width: 767px) {
.reg__select {
min-width: 100%;
}
}
.reg .select-box {
display: inline-block;
}
.reg .select-box .select-box__select {
min-width: 300px;
}
@media (max-width: 1199px) {
.reg .select-box {
width: 100%;
margin-top: 5px;
}
.reg .select-box .select-box__select {
min-width: calc(100% - 77px);
}
.reg .select-box .btn {
min-width: 70px;
}
}
.reg .select-list {
margin-top: 10px;
}
.reg .select-list p {
margin-top: 10px;
}
.reg .editor {
display: block;
width: 100%;
margin-top: 18px;
}
.reg .editor .editor-content {
height: 231px;
border: 1px solid #ddd;
}
.reg__view {
width: 100%;
border-top: 1px solid #000;
border-bottom: 1px solid #ccc;
}
.reg__view col.th {
width: 180px;
}
.reg__view th {
background-color: #f8f8f8;
font-weight: 700;
padding: 23px 18px;
text-align: left;
font-size: 1.125rem;
vertical-align: top;
}
.reg__view td {
font-weight: 400;
padding: 21px 19px;
}
.reg__view tr + tr th,
.reg__view tr + tr td {
border-top: 1px solid #ccc;
}
.reg__view .txt-box {
height: 137px;
overflow-y: scroll;
word-break: break-all;
}
.reg__view .check-select li,
.reg__view .radio-select li {
display: inline-block;
margin-right: 15px;
}
@media (max-width: 767px) {
.reg__view th {
font-size: 16px;
padding: 23px 10px;
}
.reg__view td {
padding: 21px 10px;
}
}

.filedownload {
padding-left: 27px;
text-indent: -15px;
}
.filedownload:hover {
text-decoration: none;
}
.filedownload span {
padding-bottom: 1px;
box-shadow: inset 0 -1px 0 #000;
word-break: break-all;
}
.filedownload::before {
content: "\f019";
font-family: "Line Awesome Free";
font-weight: 900;
-webkit-font-smoothing: antialiased;
display: inline-block;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1;
font-size: 25px;
margin-right: 5px;
vertical-align: middle;
}

.fileview {
padding-left: 27px;
text-indent: -15px;
}
.fileview:hover {
text-decoration: none;
}
.fileview span {
padding-bottom: 1px;
box-shadow: inset 0 -1px 0 #000;
word-break: break-all;
}
.fileview::before {
content: "\f15b  ";
font-family: "Line Awesome Free";
font-weight: 900;
-webkit-font-smoothing: antialiased;
display: inline-block;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1;
font-size: 25px;
margin-right: 5px;
vertical-align: middle;
}

.refer {
color: #666666;
font-weight: 400;
position: relative;
padding-left: 20px;
}
.refer::before {
content: "※";
display: inline-block;
position: absolute;
top: 0;
left: 0;
}

.required-text {
color: #e24d4d;
font-size: 1.125rem;
font-weight: 400;
margin-bottom: 10px;
text-align: right;
margin-top: -38px;
}
@media (max-width: 767px) {
.required-text {
margin-top: 0;
font-size: 14px;
}
}

.required::before {
content: "*";
color: rgb(226, 77, 77);
display: inline-block;
width: 10px;
}

.reg-confirm {
max-width: 628px;
margin: 20px auto 0;
text-align: center;
}
.reg-confirm__inner {
padding-top: 40px;
text-align: left;
display: flex;
justify-content: space-between;
}
.reg-confirm h3 {
font-size: 30px;
font-weight: 500;
margin-bottom: 30px;
letter-spacing: -1.5px;
}
.reg-confirm__input {
display: inline-block;
vertical-align: top;
width: calc(100% - 180px);
}
.reg-confirm__input p + p {
margin-top: 10px;
}
.reg-confirm__input label {
display: inline-block;
width: 142px;
font-size: 1.125rem;
color: #000;
font-weight: 700;
}
.reg-confirm__input input {
text-indent: 10px;
padding: 0;
}
.reg-confirm__input .radio-select,
.reg-confirm__input input.input-lg {
display: inline-block;
width: calc(100% - 148px);
}
.reg-confirm__input .radio-select label {
width: auto !important;
display: inline-block;
margin-right: 10px;
}
.reg-confirm__input select,
.reg-confirm__input .input-sm {
min-width: 80px;
max-width: 82px;
}
.reg-confirm__input .between {
padding: 0px 11px 0 5px;
}
.reg-confirm__btn {
width: 170px;
border-radius: 5px;
background-color: rgb(34, 34, 34);
color: #fff;
font-size: 20px;
}
.reg-confirm__btn:focus {
outline: 1px dashed #fff;
outline-offset: -3px;
}
.reg-confirm .refer {
margin-top: 30px;
text-align: left;
color: #333333;
}
@media (max-width: 767px) {
.reg-confirm__inner {
display: block;
padding-top: 20px;
}
.reg-confirm__input {
width: 100%;
}
.reg-confirm__input label {
margin-bottom: 10px;
}
.reg-confirm__input input.input-lg {
width: 100%;
}
.reg-confirm__btn {
width: 100%;
height: 60px;
margin-top: 20px;
}
}

.filedown dl {
display: flex;
flex-direction: row;
justify-content: space-between;
margin-bottom: 40px;
}
.filedown dl dt,
.filedown dl dt label {
color: rgb(51, 51, 51);
font-size: 1.125rem;
font-weight: 700;
padding-bottom: 0;
}
.filedown dl dd {
word-break: break-all;
}
.filedown dl dt {
position: relative;
padding-left: 25px;
padding-bottom: 10px;
font-size: 28px;
font-weight: 700;
letter-spacing: -0.55px;
border-bottom: 2px solid #dddddd;
width: 120px;
font-size: 20px;
padding-bottom: 0;
padding-left: 15px;
border-bottom-width: 0px;
color: rgb(51, 51, 51);
}
.filedown dl dt::before {
content: "";
width: 3px;
height: 18px;
background-color: #f65626;
position: absolute;
top: 13px;
left: 0;
}
.filedown dl dt.bdnone {
border-bottom-width: 0px;
}
.filedown dl dt.bdbcolor {
border-bottom-color: #666;
}
@media (max-width: 767px) {
.filedown dl dt {
font-size: 20px;
}
.filedown dl dt::before {
top: 6px;
}
}
.filedown dl dd {
width: calc(100% - 120px);
}
.filedown dl.hide {
display: none;
}
@media (max-width: 767px) {
.filedown dl {
display: block;
margin-bottom: 30px;
}
.filedown dl dt,
.filedown dl dd {
width: 100%;
}
.filedown dl dt {
padding-left: 10px;
font-size: 15px;
}
.filedown dl dt::before {
top: 4px;
}
.filedown dl dd {
font-size: 14px;
margin-top: 10px;
}
.filedown dl dd .vm {
display: block;
margin-bottom: 5px;
}
}

.agree-checkbox {
padding: 30px 10px;
text-align: center;
}

.datefile {
padding: 30px 30px 25px;
background-color: rgba(0, 165, 137, 0.05);
}
.datefile__list {
display: inline-block;
width: calc(100% - 200px);
}
.datefile__list p {
margin-bottom: 5px;
}
.datefile__list p i {
margin-top: 3px;
margin-right: 10px;
}
.datefile__list p span.filename {
display: inline-block;
vertical-align: middle;
width: calc(100% - 70px);
}
.datefile__list p span.filename .name {
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
display: inline-block;
vertical-align: bottom;
max-width: calc(100% - 80px);
}
.datefile__list.attachment p {
text-align: right;
margin-top: 5px;
}
.datefile__list.attachment p .filename {
color: #000;
width: 100%;
}
.datefile__list.attachment p .filename span {
display: inline-block;
vertical-align: middle;
font-size: 16px;
font-weight: 400;
}
.datefile__list.attachment p .filename span.name {
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
display: inline-block;
max-width: calc(100% - 80px);
padding-top: 3px;
padding-bottom: 4px;
position: relative;
padding-left: 40px;
}
.datefile__list.attachment p .filename span.name::before {
content: "";
position: absolute;
top: 0;
left: 0;
width: 30px;
height: 30px;
background: url(/static/kogl_alright/img/common/icon-down.png) 0 0 no-repeat;
}
.datefile__button {
display: inline-block;
vertical-align: top;
width: 177px;
padding: 5px 0 6px;
border-radius: 3px;
background-color: #f65626;
color: #fff;
}
.datefile__button::after {
content: "\f019";
font-family: "Line Awesome Free";
font-weight: 900;
-webkit-font-smoothing: antialiased;
display: inline-block;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1;
padding-left: 5px;
font-size: 18px;
}
@media (max-width: 767px) {
.datefile {
padding: 10px 20px 20px;
}
.datefile__list {
display: block;
width: 100%;
margin-top: 10px;
}
.datefile__list p span.filename {
width: calc(100% - 30px);
}
.datefile__list.attachment p {
text-align: left;
}
}

.filebox .upload-name {
display: inline-block;
width: 300px;
height: 40px;
padding: 0 10px;
vertical-align: middle;
border-radius: 3px;
border: 1px solid #9d9d9d;
background-color: #fff;
font-size: 16px;
}
@media (max-width: 767px) {
.filebox .upload-name {
width: calc(100% - 98px);
}
}
.filebox label {
display: inline-block;
vertical-align: middle;
text-align: center;
cursor: pointer;
height: 40px;
line-height: 38px;
padding: 0 17px;
border-radius: 5px;
background-color: #666666;
color: #fff;
}
.filebox input[type=file] {
position: absolute;
width: 0;
height: 0;
padding: 0;
overflow: hidden;
border: 0;
}

/* 접근성 탭 포커스 스타일 */
.file-focus {
outline: 1px dashed #fff;
outline-offset: -3px;
}

.fiie-add {
display: flex;
}
.fiie-add + .fiie-add {
margin-top: 10px;
}
.fiie-add > button {
margin-left: 10px;
}
.fiie-add .b-add {
border: 1px solid #f65626;
background-color: #f65626;
color: #f65626;
padding: 10px 11px;
}
.fiie-add .b-add::after {
content: "\f477";
font-family: "Line Awesome Free";
font-weight: 900;
-webkit-font-smoothing: antialiased;
display: inline-block;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1;
font-size: 20px;
color: #f65626;
}

.pswp[role=dialog] {
transform: translate(0, 0);
z-index: 20000;
}

.photo-list {
display: grid;
grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
justify-items: center;
margin: 30px 0;
gap: 20px;
}
.photo-list.floating {
display: flex;
flex-wrap: wrap;
gap: 20px;
margin-left: 0;
margin-right: 0;
}
.photo-list__item {
width: 240px;
}
.floating .photo-list__item {
width: auto;
opacity: 0;
transition: all 0.5s;
}
.floating .photo-list__item.loadcomplete {
opacity: 1;
}
@media (max-width: 1199px) {
.photo-list__item {
width: 100%;
}
}
.photo-list__img {
position: relative;
width: 100%;
height: 100%;
border: 1px solid #dddddd;
text-align: center;
}
.photo-list__img .img {
display: block;
}
.photo-list__img .img img {
position: absolute;
left: 0;
top: 0;
width: 100%;
vertical-align: top;
}
.photo-list__inner {
position: relative;
}
.photo-list__button {
z-index: 10;
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 50px;
}
.photo-list__button [class^=dialogOpencode] {
position: absolute;
top: 10px;
left: 10px;
}
.photo-list__thumnail {
display: inline-block;
width: 100%;
height: 100%;
border: 1px solid #dddddd;
position: relative;
overflow: hidden;
background-position: center;
background-size: cover;
background-repeat: no-repeat;
}
.photo-list__thumnail::before {
content: "";
display: block;
height: 0;
padding-top: 100%;
}
.photo-list__thumnail.play::after {
content: "";
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 58px;
height: 58px;
background: url(/static/kogl/img/common/bg-videoplay.png) no-repeat;
}
.photo-list__bookmark {
position: absolute;
top: 13px;
right: 11px;
width: 28px;
height: 28px;
background-repeat: no-repeat;
background-image: url(/static/kogl/img/sub/scrap_off-btn.png);
background-size: contain;
}
.photo-list__bookmark.active {
background-image: url(/static/kogl/img/sub/scrap_on-btn.png);
}
.photo-list__layer {
z-index: 20;
display: none;
flex-direction: column;
justify-content: flex-end;
position: absolute;
bottom: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(34, 34, 34, 0.8);
pointer-events: none;
}
.photo-list__layer.on {
display: flex;
}
.photo-list__layer > div {
z-index: 20;
}
.photo-list__layer-top {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -60px);
text-align: center;
padding: 14px 20px;
}
.photo-list__layer-top button,
.photo-list__layer-top a {
display: block;
pointer-events: visible;
min-width: 184px;
height: 40px;
border-radius: 3px;
color: #000;
font-size: 18px;
font-weight: 700;
letter-spacing: -0.45px;
}
.photo-list__layer-top button.b-preview, .photo-list__layer-top button.b-preview2,
.photo-list__layer-top a.b-preview,
.photo-list__layer-top a.b-preview2 {
padding-left: 45px;
background: #fff url(/static/kogl/img/common/icon-preview.png) 45px center no-repeat;
}
.photo-list__layer-top button.b-listen,
.photo-list__layer-top a.b-listen {
padding-left: 45px;
background: #fff url(/static/kogl/img/common/icon-listen.png) 45px center no-repeat;
}
.photo-list__layer-top button.b-detailview,
.photo-list__layer-top a.b-detailview {
margin-top: 10px;
padding-left: 45px;
background: #fff url(/static/kogl/img/common/icon-detailview.png) 35px center no-repeat;
}
.photo-list__layer-top button.b-link,
.photo-list__layer-top a.b-link {
background-color: #fff;
padding-left: 0;
padding-right: 0;
}
.photo-list__layer-top button.b-link::before,
.photo-list__layer-top a.b-link::before {
content: "\f35d";
font-family: "Line Awesome Free";
font-weight: 900;
-webkit-font-smoothing: antialiased;
display: inline-block;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1;
font-size: 22px;
margin-right: 10px;
}
.photo-list__layer-top button.b-link::after,
.photo-list__layer-top a.b-link::after {
content: none;
}
.photo-list__layer-top button:hover, .photo-list__layer-top button:active, .photo-list__layer-top button:focus,
.photo-list__layer-top a:hover,
.photo-list__layer-top a:active,
.photo-list__layer-top a:focus {
color: #000;
}
.photo-list__layer-bottom {
border-top: 1px solid #fff;
text-align: center;
margin: 0 20px;
padding: 14px 0;
}
.photo-list__layer-bottom span {
color: #fff;
margin-left: 7px;
}
.photo-list__layer-bottom .ico-fav {
display: inline-block;
}
.photo-list__layer-bottom .ico-fav.like-btn {
background: url(/static/kogl/img/sub/like-btn-w.png) no-repeat;
width: 23px;
height: 23px;
}
.photo-list__layer-bottom .ico-fav.view-btn {
background: url(/static/kogl/img/sub/view-btn-w.png) no-repeat;
width: 23px;
height: 16px;
}
.photo-list__layer-bottom .ico-fav.donwload-btn {
background: url(/static/kogl/img/sub/donwload-btn-w.png) no-repeat;
width: 22px;
height: 22px;
}
.photo-list__title {
margin: 20px 0;
}
.photo-list__title a {
width: 100%;
color: #333333;
font-size: 16px;
font-weight: 500;
text-align: left;
letter-spacing: -0.4px;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
display: inline-block;
}
.floating .photo-list__title {
display: none;
}

.box {
border: 1px solid #dedede;
background-color: #fff;
border-radius: 5px;
}
.box .inner {
padding: 30px;
}
@media (max-width: 767px) {
.box .inner {
padding: 20px;
}
}
.box.type2 {
border-radius: 10px;
margin: 40px 0 80px;
}
.box.type2 ul li {
line-height: 1.88;
font-weight: 500;
line-height: 1.88;
letter-spacing: -0.8px;
}
@media (max-width: 767px) {
.box.type2 {
margin: 20px 0 40px;
}
}
.box__scroll {
overflow-y: scroll;
margin: 10px;
padding: 7px;
color: #000;
font-size: 16px;
font-weight: 400;
line-height: 1.7;
height: 180px;
}
.box__scroll.min {
height: 76px;
}
.box__gray {
background-color: rgba(0, 0, 0, 0.05);
}
.box__bg {
background-color: #fef7f4;
padding: 30px 40px;
}
.box__bg .title {
margin-top: 10px;
margin-bottom: 5px;
color: #f65626;
font-size: 22px;
font-weight: 700;
}
.box__bg .title + ul {
margin-bottom: 10px;
}
.box__bg .title + ul li {
font-size: 1.125rem;
}
@media (max-width: 767px) {
.box__bg {
padding: 20px 10px;
}
.box__bg .title {
font-size: 18px;
}
.box__bg .title + ul li {
font-size: 16px;
}
}
.box__img {
padding: 80px;
}
@media (max-width: 767px) {
.box__img {
padding: 30px 20px;
}
}
.box__iconbox {
border: 6px solid #f3f3f3;
}
.box__iconbox .inner {
padding: 30px;
}
.box__iconbox + .box__iconbox {
margin-top: 30px;
}
.box__iconbox-icon {
display: inline-block;
vertical-align: middle;
width: 120px;
height: 120px;
background-repeat: no-repeat;
background-position: 0 0;
}
.box__iconbox-icon.type1 {
background-image: url(/static/kogl_alright/img/sub/box-icon01.png);
}
.box__iconbox-icon.type2 {
background-image: url(/static/kogl_alright/img/sub/box-icon02.png);
}
.box__iconbox-content {
display: inline-block;
vertical-align: middle;
width: calc(100% - 130px);
padding-left: 15px;
}
.box__iconbox-content p {
font-size: 20px;
font-weight: 500;
}
.box__iconbox-content .refer {
color: #333;
font-size: 16px;
font-weight: 400;
margin-top: 10px;
}
.box__iconbox .inner {
width: calc(100% - 160px - 160px);
margin: 0 auto;
}
@media (max-width: 1275px) {
.box__iconbox .inner {
width: 100%;
}
}
.box__iconbox .inner .b-btn {
margin-top: 20px;
min-width: 166px;
max-width: 177px;
min-height: 35px;
padding: 8px 10px;
font-size: 16px;
font-weight: 500;
}
.box__iconbox .inner .b-btn::after {
content: "\f105";
font-family: "Line Awesome Free";
font-weight: 900;
-webkit-font-smoothing: antialiased;
display: inline-block;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1;
font-size: 1.125rem;
}
@media (max-width: 767px) {
.box__iconbox .inner .b-btn {
min-width: 100%;
margin-top: 10px;
}
}
@media (max-width: 767px) {
.box__iconbox .inner {
padding: 20px;
}
.box__iconbox + .box__iconbox {
margin-top: 20px;
}
.box__iconbox-icon {
display: none;
}
.box__iconbox-content {
width: 100%;
padding-left: 0;
}
.box__iconbox-content p {
font-size: 18px;
}
}

.table-rt-deco {
text-align: right;
margin-bottom: 11px;
}

dl.table {
border: 1px solid #ddd;
border-top: 2px solid #777;
margin-bottom: 7px;
}
dl.table dt {
background: #f0f0f0;
padding: 10px;
border-bottom: 1px solid #ddd;
font-weight: bold;
color: #000;
}
dl.table dd {
text-align: left;
padding: 10px;
}

table {
word-break: break-all;
width: 100%;
}
table tr.unlock {
background-color: #999;
}
table td {
word-break: keep-all;
}
table th {
font-size: 1.125rem;
}
table td {
font-size: 1rem;
}
@media (max-width: 767px) {
table th {
font-size: 15px;
}
table td {
font-size: 14px;
}
}

.table {
position: relative;
border-top: 2px solid #777;
border-bottom: 1px solid #ddd;
text-align: center;
margin-top: 10px;
margin-bottom: 30px;
}
@media (max-width: 767px) {
.table::before {
content: "";
height: 100%;
position: absolute;
right: 0;
width: 1px;
background-color: #ffffff;
}
.table.ovx::before {
left: 749px;
}
}
.table table {
width: 100%;
}
.table.fontxs td,
.table.fontxs th {
font-size: 14px;
padding: 5px;
}
.table.bd-style tr th,
.table.bd-style tr td {
border: 1px solid #ddd;
border-width: 1px 1px 0 0;
}
@media (max-width: 767px) {
.table.bd-style tr th,
.table.bd-style tr td {
word-break: initial;
}
}
.table tr th:last-child {
border-right-width: 0;
}
.table tr td:last-child {
border-right-width: 0;
}
.table tr td:last-child[rowspan] {
border-left-width: 1px;
}
.table th {
font-weight: 600;
line-height: 24px;
font-size: 1.125rem;
padding: 13px 0;
text-align: center;
color: #222;
background: #f0f0f0;
border: 0;
}
.table th.bgnone {
background: transparent;
}
.table tbody tr {
/* &:hover,
&:active,
&:focus {
	th,
	td {
		background-color: $th-bg-color;
	}
} */
}
.table tbody th,
.table tbody td {
border-width: 1px 0 0 0;
border-color: #ddd;
border-style: solid;
padding: 5px;
}
@media (min-width: 1200px) {
.table tbody th,
.table tbody td {
padding: 17px 10px;
}
}
.table tbody th {
background: #f0f0f0;
}
@media (max-width: 767px) {
.table.ovx {
overflow-x: auto;
position: relative;
}
.table.ovx::before {
content: "";
position: absolute;
right: 0;
width: 1px;
height: 100%;
background-color: #fff;
}
.table.ovx table {
width: 750px;
}
}
.table.fix table {
table-layout: fixed;
}
.table td.nodata {
padding: 60px 0;
}
.table td.title {
text-align: left;
}
.table .table-result-deco {
padding: 20px 0;
border-top: 1px solid #ddd;
text-align: center;
}
.table .table-result-deco .date {
font-size: 14px;
}
.table .table-result-deco .name {
color: #000;
font-size: 20px;
}
.table.style {
border-top-width: 0;
border-bottom-width: 0;
}
@media (min-width: 1276px) {
.table.style {
margin-bottom: 80px;
}
}
.table.style th[scope=row] {
background-color: #f65626;
color: #fff;
font-size: 22px;
width: 126px;
border-top: 1px solid #f65626;
}
@media (max-width: 767px) {
.table.style th[scope=row] {
font-size: 18px;
width: 90px;
padding: 10px 0;
}
}
.table.style th[scope=col] {
border-top: 1px solid #f65626;
border-bottom: 1px solid #f65626;
background-color: #fef7f3;
color: #f65626;
font-size: 22px;
}
.table.style th[scope=col]:last-child {
border-right: 1px solid #f65626;
}
.table.style th[scope=col] + th[scope=col] {
position: relative;
}
.table.style th[scope=col] + th[scope=col]::before {
content: "";
position: absolute;
top: 15px;
left: 0;
width: 1px;
height: 20px;
background-color: #f65626;
}
@media (max-width: 767px) {
.table.style th[scope=col] {
font-size: 18px;
padding: 10px 0;
}
}
.table.style tbody th {
border-width: 0;
}
.table.style tbody th[scope=row] {
position: relative;
}
.table.style tbody th[scope=row]::before {
content: "";
position: absolute;
top: 0;
left: 50%;
transform: translateX(-50%);
width: calc(100% - 18px - 18px);
height: 1px;
background-color: #fff;
}
.table.style tbody td {
border-width: 0 1px 1px 0;
font-size: 16px;
padding-left: 30px;
}
.table.style tbody td p {
font-size: 16px;
}
.table.style tbody td p.bu2 {
padding-left: 15px;
}
.table.style tbody td p.bu2::before {
top: 10px;
}
@media (max-width: 767px) {
.table.style tbody td {
padding-left: 10px;
}
}
@media (max-width: 767px) {
.table th {
font-size: 15px;
padding: 5px 0;
}
}

.table-row .row-th {
border-right: 1px solid #ddd;
}
@media (max-width: 767px) {
.table-row .row-th {
display: block;
}
}
.table-row th {
width: 16.67%;
vertical-align: middle;
background-color: #f0f0f0;
border-bottom: 1px solid #ddd;
}
.table-row td {
padding: 15px 10px 15px 20px;
height: 55px;
border-bottom: 1px solid #ddd;
}
@media (max-width: 767px) {
.table-row td {
padding: 5px;
}
}

.table.type-th-multi thead tr + tr th {
border-top: 1px solid #ddd;
}

.table.td-vat td {
vertical-align: top;
}

.table.padding-sm td {
padding: 10px 5px;
}

.td-tal table tbody td {
text-align: left;
}

.td-tac table tbody td {
text-align: center;
}

.td-vt table tbody td {
vertical-align: top;
}

td.bdl-none {
border-left: 0 !important;
}

td.bdr-none {
border-right: 0 !important;
}

.dl-list dl {
display: flex;
flex-direction: row;
justify-content: space-between;
}
.dl-list dl dt,
.dl-list dl dt label {
color: rgb(51, 51, 51);
font-size: 1.125rem;
font-weight: 700;
padding-bottom: 0;
}
.dl-list dl dd {
word-break: break-all;
}

hr {
border-bottom: 2px solid #dddddd;
border-top-width: 0;
}

.precautions {
margin-top: 30px;
font-size: 1.125rem;
}
.precautions h3 {
margin-bottom: 8px;
}
.precautions p {
line-height: 1.67;
color: #333333;
font-weight: 400;
letter-spacing: normal;
}
.precautions .title {
display: block;
margin: 14px 0 4px 0;
font-weight: 700;
letter-spacing: -0.45px;
}
.precautions .subTitle {
margin-top: 19px;
}

.help-warning {
margin-top: 5px;
font-size: 1.125rem;
color: #222222;
letter-spacing: -0.56px;
text-indent: -18px;
padding-left: 18px;
}
.help-warning.light {
font-weight: 300;
font-size: 0.875rem;
text-indent: 0;
padding-left: 0;
line-height: 1.79;
}
.help-warning::before {
content: "※ ";
}

.fwb {
font-weight: 700 !important;
}

.radio-agree {
margin-top: 20px;
}

.tit-point {
font-size: 20px;
color: #f65626;
margin-top: 30px;
margin-bottom: 10px;
}
.tit-point + .box__bg {
margin-top: 20px;
}

.rulebox .box__bg {
text-align: center;
font-size: 1.125rem;
letter-spacing: -0.45px;
line-height: 1.67;
}
.rulebox .box__bg + .table {
margin-top: 20px;
}
@media (max-width: 767px) {
.rulebox .box__bg {
font-size: 16px;
}
}
.rulebox__list li {
padding-left: 50px;
position: relative;
margin-top: 15px;
line-height: 1.6;
}
.rulebox__list li span {
letter-spacing: -0.8px;
position: absolute;
left: 0px;
top: 0px;
}

.txt {
font-size: 20px;
}
@media (max-width: 767px) {
.txt {
font-size: 18px;
}
}

.txt2 {
font-size: 22px;
}
@media (max-width: 767px) {
.txt2 {
font-size: 20px;
}
}

.alright-range {
margin-top: 30px;
display: flex;
justify-content: space-around;
flex-wrap: wrap;
}
.alright-range > span {
text-align: center;
}
.alright-range > span .title {
font-size: 25px;
font-weight: 700;
}
.alright-range > span span {
display: block;
font-size: 1.125rem;
font-weight: 500;
line-height: 1.67;
letter-spacing: -0.45px;
}
.alright-range.process {
justify-content: center;
margin-bottom: 30px;
}
.alright-range.process > span {
flex-basis: 33%;
position: relative;
}
.alright-range.process > span + span::before {
content: "";
display: inline-block;
position: absolute;
top: 50px;
left: 0;
width: 15px;
height: 26px;
background: url(/static/kogl_alright/img/sub/icon-arr.png) 0 0 no-repeat;
}
@media (min-width: 1276px) {
.alright-range.process {
justify-content: space-around;
margin-bottom: 80px;
}
.alright-range.process > span {
flex-basis: 20%;
}
}
@media (max-width: 1199px) {
.alright-range.process > span + span::before {
top: 37px;
width: 10px;
background-size: contain;
}
}
@media (max-width: 500px) {
.alright-range.process {
border: 1px solid #ddd;
padding: 20px 36px;
}
.alright-range.process > span {
flex-basis: 100%;
}
.alright-range.process > span + span {
padding-top: 40px;
}
.alright-range.process > span + span::before {
width: 25px;
left: 50%;
margin-left: -10px;
top: 0;
transform: rotate(90deg);
}
.alright-range.process img {
display: inline-block;
vertical-align: top;
}
.alright-range.process img + span {
text-align: left;
width: calc(100% - 103px);
padding-left: 20px;
display: inline-block;
}
}
@media (max-width: 375px) {
.alright-range.process {
padding: 20px 10px;
}
.alright-range.process img + span {
padding-left: 10px;
}
}
@media (max-width: 1199px) {
.alright-range {
margin-top: 0;
justify-content: left;
}
.alright-range > span {
flex-basis: 25%;
margin-bottom: 10px;
}
.alright-range > span img {
width: 83px;
}
.alright-range > span .title {
font-size: 18px;
}
.alright-range > span span {
font-size: 16px;
}
}
@media (max-width: 500px) {
.alright-range > span {
flex-basis: 33%;
}
.alright-range > span .title {
font-size: 16px;
}
.alright-range > span span {
font-size: 14px;
}
}
@media (max-width: 360px) {
.alright-range > span {
flex-basis: 50%;
}
}

@media (max-width: 500px) {
.alright-range-swiper {
position: relative;
margin: 30px 0;
}
}
.alright-range-swiper .alright-range__button-next, .alright-range-swiper .alright-range__button-prev, .alright-range-swiper .alright-range__pagination {
display: none;
}
@media (max-width: 1199px) {
.alright-range-swiper .alright-range > span {
flex-basis: 33%;
}
.alright-range-swiper .alright-range > span .title {
font-size: 25px;
}
.alright-range-swiper .alright-range > span span {
font-size: 18px;
}
}
@media (max-width: 500px) {
.alright-range-swiper .alright-range {
flex-wrap: nowrap;
}
.alright-range-swiper .alright-range > span {
flex-basis: 100%;
}
.alright-range-swiper .alright-range__pagination {
display: block;
margin-top: 20px;
text-align: center;
}
.alright-range-swiper .alright-range__pagination .swiper-pagination-bullet {
display: inline-block;
vertical-align: middle;
width: 22px;
height: 22px;
border: 6px solid #222;
background: #fff;
opacity: 1;
}
.alright-range-swiper .alright-range__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
width: 26px;
height: 26px;
background-color: #222222;
}
.alright-range-swiper .alright-range__button-next, .alright-range-swiper .alright-range__button-prev {
display: block;
position: absolute;
top: 50%;
margin-top: -65px;
z-index: 10;
}
.alright-range-swiper .alright-range__button-next::before, .alright-range-swiper .alright-range__button-prev::before {
content: "\f105";
font-family: "Line Awesome Free";
font-weight: 900;
-webkit-font-smoothing: antialiased;
display: inline-block;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1;
color: #222;
font-size: 50px;
}
.alright-range-swiper .alright-range__button-next {
right: 0;
}
.alright-range-swiper .alright-range__button-prev {
left: 0;
transform: scaleX(-1);
}
}

.alright-effect .box__bg {
padding: 30px 10px;
text-align: center;
}
.alright-effect .box__bg span {
display: block;
margin-top: 15px;
font-size: 1.125rem;
font-weight: 400;
line-height: 1.67;
letter-spacing: -0.45px;
}
.alright-effect .box__bg span.point {
font-size: 22px;
font-weight: 700;
line-height: 1.36;
letter-spacing: -0.55px;
}
.alright-effect .box__bg span.sub {
font-size: 20px;
font-weight: 700;
line-height: 1.5;
letter-spacing: -0.5px;
}
@media (max-width: 1440px) {
.alright-effect .box__bg span {
font-size: 16px;
margin-top: 0;
}
.alright-effect .box__bg span.point {
font-size: 18px;
}
.alright-effect .box__bg span.sub {
margin-top: 5px;
font-size: 16px;
height: 48px;
}
}
@media (max-width: 740px) {
.alright-effect .box__bg span {
margin-top: 15px;
}
.alright-effect .box__bg span.point {
font-size: 20px;
}
.alright-effect .box__bg span.sub {
font-size: 18px;
height: auto;
}
}
@media (max-width: 1199px) {
.alright-effect .box__bg {
padding: 20px 10px;
}
.alright-effect .box__bg img {
width: 95px;
}
.alright-effect .box__bg span br {
display: none;
}
}
@media (max-width: 740px) {
.alright-effect .box__bg span br {
display: block;
}
}
.alright-effect__pagination {
display: none;
}
@media (max-width: 740px) {
.alright-effect .col-sm-4 {
padding-left: 0;
padding-right: 0;
}
.alright-effect__pagination {
display: block;
text-align: center;
margin-top: 20px;
}
.alright-effect__pagination .swiper-pagination-bullet {
display: inline-block;
vertical-align: middle;
width: 22px;
height: 22px;
border: 6px solid #f65626;
background: #fff;
opacity: 1;
box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.35);
}
.alright-effect__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
width: 26px;
height: 26px;
background-color: #f65626;
border-width: 0;
}
}

.bi-box {
display: flex;
align-items: center;
}
.bi-box > div:nth-child(2) {
border-left: 1px solid #ddd;
padding-left: 30px;
}
.bi-box__img {
text-align: center;
}
.bi-box__inner .bu2 {
color: #f65626;
font-size: 22px;
}
.bi-box__inner .bu2::before {
top: 15px;
background-color: #f65626;
}
.bi-box__inner .txt {
font-size: 18px;
}
@media (max-width: 767px) {
.bi-box__inner .bu2 {
font-size: 18px;
padding-bottom: 10px;
}
.bi-box__inner .bu2::before {
top: 12px;
}
.bi-box__inner .txt {
font-size: 16px;
}
}
@media (max-width: 1199px) {
.bi-box {
display: block;
}
.bi-box .col-sm-6 {
width: 100%;
}
.bi-box .col-sm-6:nth-child(2) {
border-left-width: 0;
padding-left: 0;
}
}

.colorsystem {
margin-top: 20px;
}
.colorsystem tr th {
vertical-align: middle;
padding: 8px 5px;
font-weight: 600;
color: #f65626;
font-size: 13px;
}
.colorsystem tr th strong {
width: 100%;
display: inline-block;
font-weight: 400;
padding: 2px 0px;
color: #fff;
}
.colorsystem tr th strong.org {
background: #e65a1d;
}
.colorsystem tr th strong.gray {
background: #9a9da0;
}
.colorsystem tr th strong.silver {
background: #acacac;
}
.colorsystem tr th strong.gold {
background: #b79f57;
}
.colorsystem tr th strong.black {
background: #040301;
}
.colorsystem tr th:last-child {
border-right: none;
}
.colorsystem tr td {
vertical-align: middle;
border-bottom: dotted 1px #999999;
border-right: dotted 1px #999999;
padding: 8px 10px;
text-align: center;
font-size: 13px;
width: 110px;
}
.colorsystem tr td:last-child {
border-right: none;
}
.colorsystem thead tr th {
vertical-align: middle;
border-bottom: dotted 1px #999999;
border-right: dotted 1px #999999;
padding: 8px 10px;
text-align: center;
font-size: 13px;
width: 110px;
}
.colorsystem thead tr th:first-child {
vertical-align: middle;
padding: 8px 5px;
font-weight: 600;
color: #f65626;
font-size: 13px;
text-align: left;
border-bottom: 0;
border-right: 0;
}
.colorsystem thead tr th strong {
font-weight: 600;
color: #000;
}
@media (max-width: 1400px) {
.colorsystem {
display: none;
}
}
.colorsystem-mview {
display: none;
}
@media (max-width: 1400px) {
.colorsystem-mview {
display: block;
margin-bottom: 20px;
}
.colorsystem-mview h4 {
color: #f65626;
margin-bottom: 20px;
}
.colorsystem-mview tr th {
vertical-align: middle;
padding: 8px 5px;
line-height: 18px;
font-weight: 600;
text-align: center;
color: #222222;
font-size: 13px;
border-right: dashed 1px #999999;
border-bottom: dashed 1px #999999;
}
.colorsystem-mview tr th:last-child {
border-right: none;
}
.colorsystem-mview tr td {
vertical-align: middle;
border-bottom: dashed 1px #999999;
border-right: dashed 1px #999999;
padding: 3px 3px;
text-align: center;
font-size: 13px;
width: auto;
}
.colorsystem-mview tr td:last-child {
border-right: none;
}
.colorsystem-mview strong {
display: inline-block;
width: 100%;
color: #fff;
font-weight: 400;
padding: 1px 0px;
font-size: 15px;
text-align: center;
}
.colorsystem-mview strong.org {
background: #e65a1d;
}
.colorsystem-mview strong.gray {
background: #9a9da0;
}
.colorsystem-mview strong.silver {
background: #acacac;
}
.colorsystem-mview strong.gold {
background: #b79f57;
}
.colorsystem-mview strong.black {
background: #040301;
}
}

.ci-imglogo {
margin-top: 20px;
}
.ci-imglogo strong {
color: #f65626;
font-size: 13px;
display: block;
margin-bottom: 30px;
}
.ci-imglogo p {
float: left;
color: #222222;
font-size: 13px;
font-weight: 600;
margin-right: 50px;
}

@media (min-width: 1276px) {
.alright-process-detail {
margin-bottom: 80px;
}
}
.alright-process-detail__item {
display: flex;
padding-bottom: 20px;
}
.alright-process-detail__item + .alright-process-detail__item {
padding-top: 37px;
position: relative;
}
.alright-process-detail__item + .alright-process-detail__item::before {
content: "";
position: absolute;
top: 0;
left: 150px;
width: 25px;
height: 15px;
background: url(/static/kogl_alright/img/sub/icon-arr2.png) 0 0 no-repeat;
}
.alright-process-detail__item dl {
border-radius: 10px;
}
.alright-process-detail__item dt {
border-radius: 10px 10px 0 0;
font-size: 22px;
font-weight: 500;
height: 50px;
padding: 8px 0;
}
.alright-process-detail__item .col-process1 {
width: 360px;
float: left;
display: grid;
padding-right: 20px;
}
.alright-process-detail__item .col-process1 dl {
display: grid;
border: 1px solid #f65626;
text-align: center;
}
.alright-process-detail__item .col-process1 dt {
background-color: #f65626;
color: #fff;
}
.alright-process-detail__item .col-process1 dd {
font-size: 20px;
letter-spacing: -0.5px;
}
.alright-process-detail__item .col-process2 {
width: calc(100% - 360px);
float: left;
padding-left: 20px;
}
.alright-process-detail__item .col-process2 dl {
border: 1px solid #ddd;
}
.alright-process-detail__item .col-process2 dt {
text-align: center;
background-color: #fef7f3;
color: #f65626;
}
.alright-process-detail__item .col-process2 dd {
width: 60%;
margin: 25px auto;
}
.alright-process-detail__item .col-process2 dd ul {
text-align: left;
}
.alright-process-detail__item .col-process2 dd li {
font-size: 16px;
}
@media (max-width: 1275px) {
.alright-process-detail__item .col-process1 {
width: 30%;
padding-right: 10px;
}
.alright-process-detail__item .col-process2 {
width: 70%;
padding-left: 10px;
}
.alright-process-detail__item .col-process2 dd {
width: 90%;
margin: 15px auto;
}
}
@media (max-width: 767px) {
.alright-process-detail__item {
flex-wrap: wrap;
padding-bottom: 15px;
margin-left: 0;
margin-right: 0;
}
.alright-process-detail__item + .alright-process-detail__item {
padding-top: 27px;
}
.alright-process-detail__item + .alright-process-detail__item::before {
left: 50%;
transform: translateX(-50%);
}
.alright-process-detail__item .col-process1 {
width: 100%;
padding-right: 0;
padding-bottom: 10px;
}
.alright-process-detail__item .col-process1 dd {
padding: 10px 0;
}
.alright-process-detail__item .col-process2 {
width: 100%;
padding-left: 0;
}
.alright-process-detail__item .col-process2 dt {
font-size: 1.125rem;
}
}

.box-list ul {
display: block;
}
.box-list ul:before, .box-list ul:after {
content: ".";
display: block;
height: 0;
clear: both;
visibility: hidden;
}
.box-list li {
float: left;
width: 25%;
height: 200px;
text-align: center;
margin-bottom: 30px;
}
.box-list li a,
.box-list li span {
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: calc(100% - 40px);
height: 100%;
border: 1px solid #ddd;
padding: 0 10px;
}
@media (max-width: 1199px) {
.box-list li {
width: 33.3333333333%;
height: 150px;
}
.box-list li a,
.box-list li span {
width: calc(100% - 20px);
}
}
@media (max-width: 767px) {
.box-list li {
width: 50%;
height: 120px;
}
}
@media (max-width: 360px) {
.box-list li {
width: 100%;
margin-bottom: 10px;
}
.box-list li a,
.box-list li span {
width: 100%;
}
}

.process-box ul {
display: flex;
justify-content: center;
flex-wrap: wrap;
}
@media (min-width: 1276px) {
.process-box ul {
justify-content: space-between;
flex-wrap: nowrap;
}
}
.process-box li {
flex-basis: calc(33.3333333333% - 40px);
margin-bottom: 40px;
}
@media (min-width: 1276px) {
.process-box li {
flex-basis: 20%;
}
}
.process-box li + li {
margin-left: 40px;
position: relative;
}
.process-box li + li::before {
content: "";
width: 26px;
height: 27px;
background: url(/static/kogl_alright/img/sub/icon-circle-arrow.png) 0 0 no-repeat;
position: absolute;
left: -34px;
top: 50%;
margin-top: -10px;
}
.process-box li > span {
display: block;
text-align: center;
height: 180px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
border: 1px solid #f65626;
border-radius: 0 0 10px 10px;
}
.process-box li > span.title {
border-radius: 10px 10px 0 0;
background-color: #f65626;
color: #fff;
height: 100px;
font-size: 20px;
letter-spacing: -0.55px;
font-weight: 500;
}
@media (min-width: 1276px) {
.process-box li > span.title {
font-size: 22px;
}
}
.process-box li > span .p {
margin-top: 20px;
font-size: 18px;
font-weight: 700;
line-height: 1.5;
letter-spacing: -0.5px;
}
@media (min-width: 1276px) {
.process-box li > span .p {
font-size: 20px;
}
}
@media (max-width: 767px) {
.process-box li {
flex-basis: 100%;
}
.process-box li + li {
margin-left: 0;
}
.process-box li + li::before {
top: -34px;
margin-top: 0;
left: 50%;
margin-left: -15px;
transform: rotate(90deg);
}
.process-box li > span {
height: 150px;
}
.process-box li > span.title {
height: 50px;
}
.process-box li > span.title br {
display: none;
}
}

.ul-txt li {
font-size: 20px;
}
@media (max-width: 767px) {
.ul-txt li {
font-size: 18px;
}
}
.ul-txt > li {
padding-left: 20px;
text-indent: -20px;
margin: 5px 0;
word-break: break-all;
}
.ul-txt > li > ul {
margin-left: 20px;
}
.ul-txt .info-view {
padding-left: 0;
text-indent: 0;
margin: 10px 0;
}

.info-view {
border-top: solid 2px #000000;
}
.info-view dl {
border-bottom: 1px solid #ddd;
display: table;
table-layout: fixed;
width: 100%;
font-size: 18px;
}
.info-view dt {
display: inline-block;
width: 30%;
padding: 10px 0 10px 40px;
}
.info-view dd {
display: inline-block;
vertical-align: top;
width: 70%;
color: #f65626;
padding: 10px 0 10px 20px;
}
@media (max-width: 767px) {
.info-view dl {
font-size: 16px;
}
.info-view dt,
.info-view dd {
padding: 5px;
width: 100%;
}
}

.erro {
margin: 80px auto;
text-align: center;
background: url(/static/commons/img/icon_nopage.png) no-repeat center top;
padding-top: 100px;
}
.erro .text1 {
font-size: 22px;
font-weight: 500;
}
.erro .text2 {
margin-top: 20px;
color: #767676;
font-size: 18px;
margin-top: 20px;
}

/* 추가 스타일 */
.skip a {
width: 30%;
}