// 이용약관 동의 다운로드
.filedown{
	dl{
		@include dlList;
		margin-bottom:40px;
		dt{
			@include bu1;
			width: 120px;
			font-size: 20px;
			padding-bottom: 0;
			padding-left: 15px;
			border-bottom-width: 0px;
			color: rgb(51, 51, 51);
		}
		dd{
			width: calc(100% - 120px);
		}
		&.hide{display: none;}
		@include breakpoint($sm){
			display: block;
			margin-bottom: 30px;
			dt,
			dd {
				width: 100%;
			}
			dt {
				padding-left: 10px;
				font-size: 15px;
				&::before{
					top: 4px;
				}
			}
			dd{
				font-size: 14px;
				margin-top: 10px;
				//이용조건
				.vm{
					display: block;
					margin-bottom: 5px;
				}
			}
		}
	}
}
// 동의 체크
.agree-checkbox{
	padding: 30px 10px;
	text-align: center;
}
// 통합검색 파일 다운로드
.datefile {
	padding: 30px 30px 25px;
	background-color: rgba(0, 165, 137, 0.05);
	&__list {
		display: inline-block;
		width: calc(100% - 200px);
		p {
			margin-bottom: 5px;
			i {
				margin-top: 3px;
				margin-right: 10px;
			}
			span.filename {
				display: inline-block;
				vertical-align: middle;
				width: calc(100% - 70px);
				.name {
					@include ellipsis;
					vertical-align: bottom;
					max-width: calc(100% - 80px);
				}
			}
		}
		// 첨부파일
		&.attachment {
			p {
				text-align: right;
				margin-top: 5px;
				.filename {
					color: $black;
					width: 100%;
					span{
						display: inline-block;
						vertical-align: middle;
						font-size: 16px;
						font-weight: 400;
						&.name {
							@include ellipsis;
							max-width: calc(100% - 80px);
							padding-top: 3px;
							padding-bottom: 4px;
							position: relative;
							padding-left: 40px;
							&::before {
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								width: 30px;
								height: 30px;
								background: url(/static/kogl_alright/img/common/icon-down.png) 0 0 no-repeat;
							}
						}
					}
					
				}
			}
		}
	}
	&__button {
		display: inline-block;
		vertical-align: top;
		width: 177px;
		padding: 5px 0 6px;
		border-radius: 3px;
		background-color: $point;
		color: $white;
		&::after {
			content: '';
			@include la;
			padding-left: 5px;
			font-size: 18px;
		}
	}
	@include breakpoint($sm) {
		padding: 10px 20px 20px;
		&__list {
			display: block;
			width: 100%;
			margin-top: 10px;
			p {
				span.filename {
					width: calc(100% - 30px);
				}
			}
			&.attachment {
				p {
					text-align: left;
				}
			}
		}
	}
}
// reg 첨부파일
.filebox {
	.upload-name {
		display: inline-block;
		width: 300px;
		height: 40px;
		padding: 0 10px;
		vertical-align: middle;
		border-radius: 3px;
		border: 1px solid #9d9d9d;
		background-color: #fff;
		font-size: 16px;
		@include breakpoint($sm){width: calc(100% - 98px);}
	}
	label {
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		cursor: pointer;
		height: 40px;
		line-height: 38px;
		padding: 0 17px;
		border-radius: 5px;
		background-color: #666666;
		color: #fff;
	}
	input[type="file"] {
		position: absolute;
		width: 0;
		height: 0;
		padding: 0;
		overflow: hidden;
		border: 0;
	}
}
/* 접근성 탭 포커스 스타일 */
.file-focus {
	outline: 1px dashed #fff;
	outline-offset: -3px;
}
//reg 첨부파일 추가
.fiie-add{
	display: flex;
	+.fiie-add {margin-top: 10px;}
	>button{margin-left: 10px;}
	.b-add{
		border: 1px solid $point;
		background-color: $point;
		color: $point;
		padding: 10px 11px;
		&::after{
			content: '';
			@include la;
			font-size: 20px;
			color: $point;
		}
	}
}