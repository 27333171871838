colgroup {
	col.no {
		width: 100px;
	}
	col.count {
		width: 120px;
	}
	col.files,
	col.names {
		width: 120px;
	}
	col.depts,
	col.day {
		width: 110px;
	}
	col.days {
		width: 190px;
	}
	col.processing {
		width: 120px;
	} //처리
	col.public {
		width: 120px;
	} //공개
	col.btns {
		width: 130px;
	} //btns
	@include breakpoint($sm) {
		display: none;
	}
}
.bd-list {
	&__title{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-top: 50px;
		&+.link-list {
			margin-top: 20px;
			border-top: 2px solid $bd-top-color;
		}
		@include breakpoint($sm){
			margin-top: 27px;
			align-items: flex-start;
			flex-direction: column-reverse;
		}
	}
	// 게시물 카운트
	&__total{
		color: $black;
		font-size: $html-font-size;
		letter-spacing: -0.4px;
		text-align: right;
		&.bdbtm{
			padding-top: 40px;
			padding-bottom: 20px;
			border-bottom: 2px solid rgb(102, 102, 102);
		}
		&.page-total{
			margin-top: 40px
		}
		@include breakpoint($sm) {
			@include bu1;
			border-bottom-width: 0;
			font-weight: 500;
			text-align: left;
			&.page-total {
				margin-top: 30px;
				padding-bottom: 0;
			}
		}
	}
	// 검색
	&__sh{
		@include breakpoint($sm) {
			.sh__select-input {
				input {
					width: calc(100% - 169px);
				}
			}
			.b-sh {
				min-width: 56px;
				padding: 0 15px;
				&::before {
					content: none;
				}
			}
		}
	}
	margin-top: 20px;
	border-top: 2px solid $bd-top-color;
	border-bottom: 1px solid $bd-color;
	font-weight: 300;
	a {
		&:focus,
		&:hover,
		&:active {
			color: $point;
		}
	}
	& + .nodata {
		margin-top: -1px;
	}
	table {
		width: 100%;
		text-align: center;
		table-layout: fixed;
		@include breakpoint($sm) {
			text-align: left;
			display: block;
		}
		thead {
			
			@include breakpoint($sm) {
				display: none;
			}
		}
		tr {
			height: 59px;
			font-size: 16px;
		}
		th {
			background-color: $white;
			color: $html-font-color;
			font-size: $body-font-size;
			font-weight: 700;
		}
		tbody {
			color: $black;
			@include breakpoint($sm) {
				display: block;
			}
			tr {
				@include breakpoint($sm) {
					position: relative;
					padding: 16px 0;
					height: auto;
					display: block;
					border-top: 1px solid $bd-color;
					&:first-child {
						border-top: none;
					}
					&.nodata {
						display: block;
						text-align: center;
						td {
							display: inline-block;
						}
					}
				}
				&.notice {
					background-color: rgb(248, 248, 248);
					.no i {
						width: 20px;
						height: 18px;
						background: url(/static/kogl_alright/img/common/icon-notice.png) 0 0 no-repeat;
					}
					.title a{color: $point;}
				}
			}
			td {
				padding-bottom: 3px;
				@include breakpoint($sm) {
					&.title2 {
						@include ellipsis;
						width: 100%;
					}
				}
				&.title{
					text-align: left;
					a,
					p {
						display: block;
						margin: 0;
						text-align: left;
						font-size: 16px;
						font-weight: 400;
						line-height: normal;
						&:hover,
						&:focus,
						&:active {
							color: $body-font-color;
							text-decoration: underline;
						}
						@include breakpoint($lg) {
							padding-left: 20px;
							overflow: hidden;
							white-space: nowrap;
							// max-width: 500px;
							text-overflow: ellipsis;
						}
						@include breakpoint($mdw) {
							&.md-tac {
								max-width: 280px;
								padding-left: 0;
								text-align: center;
							}
						}
						@include breakpoint($sm) {
							line-height: 1.2;
							font-size: 14px;
						}
						.round-box {
							vertical-align: middle;
							height: 34px;
							line-height: 34px;
						}
					}
					.blidetxt {
						display: block;
						margin: 0;
						text-align: left;
						color: $link-color;
						font-size: 1rem;
						font-weight: 400;
						letter-spacing: -0.033rem;
						line-height: normal;
						@include breakpoint($lg) {
							padding-left: 20px;
						}
					}
				}
				&.bd-l {
					@include breakpoint($lg) {
						padding-left: 10px;
						border-left: 1px solid $bd-color;
					}
				}
				@include breakpoint($mdw) {
					border-top: 1px solid $bd-color;
				}
				@include breakpoint($sm) {
					padding-left: 5px;
					padding-right: 5px;
					padding-bottom: 5px;
					display: block;
					&.no,
					&.num {
						display: none;
					}
					&.td-dib {
						display: inline-block;
						&-last {
							display: inline-block;
							width: 50%;
						}
					}
				}
			}
			.file {
				display: inline-block;
				margin-top: 8px;
				width: 30px;
				height: 30px;
				background: url(/static/kogl_alright/img/common/icon-down.png) 0 0 no-repeat;
				background-size: contain;
				@include breakpoint($sm){
					margin-top: 0;
					width: 20px;
					height: 18px;
					vertical-align: middle;
				}
			}
		}
	}
	.status{
		font-weight: 500;
		&.submit{
			color: #446da5;
		}
		&.ing {
			color: #00b194;
		}
		&.use {
			color: #000;
		}
		&.return {
			color: #e24d4d;
		}
	}
	.sub-type {
		display: inline-block;
		text-align: center;
		padding: 0 0 1px;
		min-width: 64px;
		color: #ffffff;
		border-radius: 3px;
		margin-right: 10px;
		&.type1 {
			background-color: #222222;
		}
		&.type2 {
			background-color: $point;
		}
		&.type3 {
			background-color: $green;
		}
	}
	.title {
		text-align: left;
		a,
		p {
			position: relative;
			vertical-align: middle;
			@include ellipsis;
			@include breakpoint($sm) {
				width: 100%;
			}
			.new {
				position: absolute;
				display: inline-block;
				background-color: $red;
				border-radius: 3px;
				width: 6px;
				height: 6px;
				line-height: 19px;
				vertical-align: top;
				margin-left: 5px;
			}
			&.nomal {
				@include breakpoint($mdw) {
					overflow: visible;
					width: auto;
					white-space: normal;
					padding-right: 0;
				}
				.new {
					@include breakpoint($mdw) {
						position: static;
						margin: 0;
						margin-left: 5px;
						vertical-align: middle;
						display: inline-block;
					}
				}
			}
		}
		.new {
			position: absolute;
			display: inline-block;
			background-color: $red;
			border-radius: 3px;
			width: 6px;
			height: 6px;
			line-height: 19px;
			vertical-align: top;
			margin-left: 5px;
		}
	}
	&__item {
		display: flex;
		width: 100%;
		border-bottom: 1px solid #ddd;
		padding: 30px 0;
		a {
			&:hover,
			&:active,
			&:focus {
				text-decoration: underline;
				color: $black;
			}
		}
	}
	&__thumbnail {
		position: relative;
		display: inline-block;
		vertical-align: top;
		min-width: 240px;
		@include aspectRatio(240, 240);
		margin-right: 30px;
		border: 1px solid #dddddd;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		&.on {
			.bd-list__layer {
				display: inline-block;
			}
		}
		&:focus {
			outline: 1px dashed #000;
			outline-offset: 1px;
		}
	}
	&__layer {
		z-index: 20;
		display: none;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(34, 34, 34, 0.8);
		button {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			&.b-preview {
				min-width: 184px;
				height: 40px;
				border-radius: 3px;
				color: #000;
				font-size: 18px;
				font-weight: 700;
				letter-spacing: -0.45px;
				padding-left: 45px;
				padding-top: 6px;
				background: #fff url(/static/kogl/img/common/icon-preview.png) 45px center no-repeat;
			}
			&:focus {
				outline: 1px dashed #000;
				outline-offset: -3px;
			}
		}
	}
	&__info {
		width: calc(100% - 240px - 70px);
		display: inline-flex;
		flex-direction: column;
		.date{
			margin-top: auto;
			text-align: right;
		}
	}
	&__agency{
		span{
			display: inline-block;
			background-color: rgba(246, 86, 38, 0.1);
			color: #f65626;
			font-weight: 700;
			text-align: center;
			padding: 3px 20px;
		}
	}
	&__tit {
		margin: 30px 0 0;
		min-height: 60px;
		a {
			color: $black;
			font-size: 20px;
			font-weight: 700;
			letter-spacing: -0.5px;
			width: 100%;
			@include ellipsis;
		}
		&.icon {
			position: relative;
			padding-left: 55px;
			padding-top: 10px;
			padding-bottom: 9px;
			background: url(/static/kogl/img/common/icon-linktitle.png) 0 center no-repeat;
		}
	}
	&__text {
		@include clamp;
		font-size: 18px;
		font-weight: 400;
		line-height: 1.67;
		height: 56px;
		letter-spacing: -0.45px;
		margin-top: 10px;
	}
	@include breakpoint($sm){
		&__item{
			display: block;
		} 
		&__thumbnail{
			width: 100%;
			margin-right: 0;
		} 	
		&__info{
			width: 100%;
			padding-top: 20px;
			.date {
				text-align: left;
				margin-top: 20px;
			}
		}
		&__tit {
			margin-top: 15px;
		}
		&__text {
			margin-top: 0;
		}
	}
	//신탁기관 안내
	&__url{
		color: $black;
		font-size: $body-font-size;
		font-weight: 500;
		a{
			color: $black;
			font-size: $body-font-size;
			font-weight: 500;
			display: inline;
		}
	}
	&#agencyList{
		.agency{
			&__item {
				&.open{
					.agency__btn{
						background-color: #e65a1d;
						&::before {
							content: '';
							line-height: 1.5;
						}
					}
					.agency__content {
						display: block;
					}
				}
				.agency__content{
					display: none;
				}
			}
			&__thunmblist{
				position: relative;
			}
			&__btn{
				position: absolute;
				right: 0;
				top: -35px;
				border-radius: 3px;
				background-color: #666;
				width: 72px;
				height: 35px;
				&::before{
					content: '';
					@include fa;
					color: $white;
				}
			}
			&__content{
				background-color: rgba(230, 90, 29, 0.05);
				border-bottom: 1px solid #dadada;
				padding: 20px;
				@media (min-width: 1500px){
					padding: 50px 60px;
				}
				@include breakpoint($xs){
					padding: 20px 10px;
				}
			}
			&__swiper{
				overflow: hidden;
				max-height: 200px;
				margin-left: 20px;
				margin-right: 20px;
				text-align: center;
				@media (min-width: 1500px) {
					margin-left: 50px;
					margin-right: 50px;
				}
				&-button-prev,
				&-button-next{
					position: absolute;
					top: 50%;
					margin-top: -15px;
					width: 15px;
					height: 25px;
					background: url(/static/kogl_alright/img/sub/agency-swiper-btn.png) 0 0 no-repeat;
				}
				&-button-prev{
					left: 0;
				}
				&-button-next{
					right: 0;
					transform: scaleX(-1);
				}
				.bd-list__item{
					display: block;
					padding: 0;
					border-bottom-width: 0
				}
				.bd-list__thumbnail{
					@include aspectRatio(240, 160);
					margin-right: 0;
					background-color: $white;
					background-size: contain;
					@include breakpoint($sm){
						width: auto;
						height: auto;
						min-width: 200px;
					}
				}
				.bd-list__tit{
					text-align: center;
					margin-top: 10px;
					a{
						color: #333;
						font-size: $body-font-size;
						font-weight: 500;
					}
				}
			}
		}
		.bd-list__thumbnail{
			background-size: contain;
		}
		.bd-list__info{
			padding: 10px 0;
		}
		.bd-list__agency{
			a{
				color: $black;
				font-size: 22px;
				font-weight: 700;
			}
		}
		.bd-list__infotext{
			color: $black;
			font-size: $body-font-size;
			font-weight: 400;
		}
		.bd-list__url{
			margin-top: auto;
		}
		@include breakpoint($sm) {
			.bd-list__thumbnail {
				height: 200px;
			}
			.bd-list__info {
				padding: 20px 0;
			}
			.bd-list__infotext{
				padding: 20px 0;
			}
			.bd-list__url{
				.url-link{word-break: break-all;}
			}
		}
	}
	// 자주묻는 질문
	&#faqList {
		.q {
			.question {
				position: relative;
				padding-right: 66px;
				word-break: break-word;
				text-align: left;
				font-weight: 300;
				cursor: pointer;
				&::after {
					content: '';
					@include la;
					color: $black;
					font-size: $body-font-size;
					position: absolute;
					top: 20px;
					right: 40px;
				}
				&:focus{
					outline: 1px dashed #000;
					outline-offset: -2px;
				}
			}
			&.open {
				td {
					color: $point;
				}
				.question {
					font-weight: 500;
					&::after {
						content: '';
						color: $point;
					}
				}
			}
		}
		.a {
			display: none;
			&.open {
				display: table-row;
				background-color: rgb(248, 248, 248);
			}
			td {
				padding: 40px 5% 40px 8%;
				text-align: left;
				&:focus {
					outline: 1px dashed #000;
					outline-offset: -3px;
				}
			}
		}
		@include breakpoint($sm){
			.only-m {
				display: none;
			}						
			tr {
				display: block;						
				td {
					display: inline-block;
				}
			}
			.q{
				td{
					width: 30px;
					display: inline-block;
				}
				.question{
					width: calc(100% - 40px);
					padding-right: 30px;
					&::after{
						top: 50%;
						margin-top: -10px;
						right: 0;
					}
				}
			}
			.a {
				&.open {display: block}
			}
		}
	}
}
//연계 저작물
.link-list{
	&__item{
		display: flex;
		width: 100%;
		border-bottom: 1px solid #ddd;
		padding: 30px 0;
		a{
			&:hover,
			&:active,
			&:focus {
				text-decoration: underline;
			}
		}
	}
	&__info {
		width: 100%;
	}
	&__thumbnail{
		position: relative;
		display: inline-block;
		vertical-align: top;		
		min-width: 240px;
		@include aspectRatio(240, 200);
		margin-right: 30px;
		border: 1px solid #dddddd;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		& + .link-list__name{
			width: calc(100% - 240px - 40px);
			padding: 20px 10px 0 0;
		}
		&.on{
			.link-list__layer{display: inline-block;}
		}
	}
	&__layer{
		z-index: 20;
		display: none;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(34, 34, 34, 0.8);
		button{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			&.b-preview{
				min-width: 184px;
				height: 40px;
				border-radius: 3px;
				color: #000;
				font-size: 18px;
				font-weight: 700;
				letter-spacing: -0.45px;
				padding-left: 45px;
				background: #fff url(/static/kogl/img/common/icon-preview.png) 45px center no-repeat;
				&:focus {
					outline: 1px dashed #000;
					outline-offset: -3px;
				}
			}
		}
	}
	&__name{
		display: inline-flex;
		flex-direction: column;
		padding: 10px 10px 10px 0;
		height: 100%;
		min-height: 192px;
		width: 100%;
	}
	&__title{
		a{
			color: $black;
			font-size: 20px;
			font-weight: 700;
			letter-spacing: -0.5px;
			width: 100% ;
			@include ellipsis;
		}
		&.icon{
			position: relative;
			padding-left: 55px;
			padding-top: 10px;
			padding-bottom: 9px;
			background: url(/static/kogl/img/common/icon-linktitle.png) 0 center no-repeat;
		}
	}
	&__text {
		@include clamp;
		font-size: 18px;
		font-weight: 400;
		line-height: 1.67;
		height: 56px;
		letter-spacing: -0.45px;
		margin-top: 10px;
	}
	&__tag{
		margin-top: auto;
		span{
			display: inline-block;
			font-size: $html-font-size;
			&.tag{
				margin-top: 5px;
				min-width: calc(100% / 4 - 10px);
				width: auto;
				.dt{
					background-color: rgba(246, 86, 38, 0.1);
					color: #f65626;
					font-weight: 700;
					text-align: center;
					padding: 3px 20px;
					&+.dd{
						margin-left: 30px;
						margin-right: 30px;
					}
				}
				.dd{
					color: $black;
					font-weight: 500;
				}
			}
		}
	}
	@media (max-width: 991px) {
		&__item {
			display: block;
			padding: 20px 0;
		}
		&__info {
			width: 100%;
		}
		&__subinfo {
			display: block;
			width: 100%;
			min-height: auto;
			padding: 20px 0 0;
			margin: 0;
			&::before {
				height: 2px;
				width: 100%;
				background: url(/static/kogl/img/common/dot-line2.png) 0 0 repeat-x;
			}
		}
		&__name {
			padding: 0 0 20px;
		}
		&__thumbnail {
			height: 320px;
			min-width: 100%;
			margin-right: 0;
			&+.link-list__name {
				width: 100%;
				padding: 20px 0 20px 0;
			}
		}
		&__title {
			/* a {
				display: block;
				white-space: inherit;
			} */
		}
	}
}
// 연계 게시글
.board-list{
	&__item{
		border-bottom: 1px solid #ddd;
		padding: 30px 20px;
	}
	&__title{
		color: $black;
		font-size: 20px;
		font-weight: 700;
		.board-type{
			display: inline-block;
			vertical-align: top;
			font-size: 20px;
			font-weight: 700;
		}
		a{
			@include ellipsis;
			color: $black;
			@include breakpoint($md) {
				display: block;
			}
		}
	}
	&__text{
		@include line-clamp(1, 18px);
		color: #333333;
		font-size: 18px;
		font-weight: 400;
		line-height: 1.67;
		letter-spacing: -0.45px;
		margin-top: 5px;
	}
	&__bottom{
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		.date{margin-bottom: 5px;}
	}
	@include breakpoint($sm) {
		&__item {
			padding: 20px 0;
		}
		&__bottom {
			display: block;
		}
		// 우수활용사례
		&.type2 {
			.board-list__item {
				a {
					display: flex;
					flex-wrap: wrap;
				}
			}
			.board-list__thumnail {
				min-width: 100%;
				margin-right: 0;
				&+.board-list__name {
					width: 100%;
					padding: 8px 10px 6px 0;
				}
			}
		}
	}
}
.date{
	font-size: 16px;
	font-weight: 500;
	color: $black;
	&::before{
		content: '';
		@include la;
		font-size: 25px;				
		margin-right: 5px;
		margin-bottom: 3px;
		vertical-align: middle;
	}
}
// 썸네일 리스트
.bd-thumbnail{
	padding-top: 38px;
	margin-top: 20px;
	border-top: 2px solid $bd-top-color;
	&__list{
		margin: 0 -20px;
		li{
			display: inline-block;
			width: calc(100% / 3 - 40px);
			margin: 0 17px 40px;
			position: relative;
			border: 1px solid $bd-color;
		}
	}
	&__status{
		position: absolute;
		top:0;
		left: 0;
		z-index: 10;
		min-width: 77px;
		padding-top: 1px;
		padding-left: 14px;
		padding-right: 14px;
		height: 30px;
		color: rgb(255, 255, 255);
		font-size: $body-font-size;
		font-weight: 500;
		background-color: $point;
		text-align: center;
		.end & {background-color: $black}
	}
	&__img{
		display: block;
		#eventList &{
			@include aspect-ratio(400, 170);
		}
		#promotionList &{
			min-width: 400px;
			@include aspect-ratio(400, 350);
		}
		#tipList &{
			min-width: 400px;
			@include aspect-ratio(400, 350);
		}
		.end &{
			-webkit-filter: grayscale(100%) brightness(50%);
			filter: grayscale(100%) brightness(50%);
			filter: grayscale(100%);
			filter: gray;
			/* IE6-9 */
			-webkit-filter: grayscale(1);
		}
	}
	&__subject{
		padding: 0 30px 30px;
	}
	&__date{
		display: inline-block;
		width: 100%;
		text-align: center;
		background-color: rgba(0, 165, 137, 0.1);
		padding: 20px 0;
		margin-top: 30px;
		.tit {
			display: inline-block;
			padding-left: 32px;
			color: $point;
			font-weight: 700;
			background: url(/static/kogl/img/common/icon-clock-color.png) 0 center no-repeat;
		}	
		.date {
			display: block;
		}
		.end & {
			background-color: #f6f6f6;
			.tit {
				color: $black;
				background-image: url(/static/kogl/img/sub/icon-clock.png);
			}
		}
	}
	&__title{
		margin-top: 20px;
		span {
			@include clamp;
			height: 50px;
			font-size: $body-font-size;
		}
	}
}
// 테이블 모바일
.only-m {
	display: none;
	@include breakpoint($sm) {
		display: inline-block;
		vertical-align: top;
		padding-right: 8px;
		position: relative;
		color: #767676;
		&:after {
			content: '';
			width: 1px;
			height: 10px;
			background-color: #ddd;
			position: absolute;
			right: 4px;
			top: 6px;
		}
	}
}