.reg{
    padding: 15px 20px;
    border-top: 2px solid #666;
    border-bottom: 1px solid #dadada;
    &__h2{
        @include bu1;
        margin-top: 50px;
    }
    &__h3{
        @include bu2;
        margin-top: 50px;
        padding-bottom: 10px;
    }
    dl {
        display: table;
        table-layout: fixed;
        width: 100%;
        padding: 15px 0;
    }
    dt{
        display: inline-block;
        vertical-align: top;
        position: relative;
        padding-left: 10px;
        .required{
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    dt,
    dt label {
        width: 200px;
        color: #333333;
        font-size: $body-font-size;
        font-weight: 700;
        .weight{font-weight: 400;}
    }
    dd {
        display: inline-block;
        width: calc(100% - 200px);
    }
    @include breakpoint($sm){
        padding: 15px 0;
        &__h3 {
            margin-top: 30px;
            padding-left: 12px;
            font-size: 18px;
        }
        dt {
            width: 100%;
        }
        dd {
            display: block;
            margin-top: 10px;
            width: 100%;
        }
    }
    textarea {
        border: none;
        width: calc(100% - 20px);
        padding: 10px;
        font-size: 16px;
        max-height: 180px;
        word-break: break-all;
    }
    .check-select li,
    .radio-select li {
        display: inline-block;
        margin-right: 25px;
    }
    .mail-box{
        input[type='text']{
            max-width: 180px;
        }
        select{min-width: 190px;}
        .between {
            margin: 0 0 0 8px;
        }
        @include breakpoint($sm){
            input[type='text']{
                max-width: 100%;
                margin-bottom: 5px;
            }
            select{min-width: 100%;}
            .between {
                padding: 0 0 0 5px;
            }
        }
    }
    .phone-box{
        input[type='tel']{max-width: 110px;}
        select {min-width: 110px;}
        .between {
            margin: 0 8px 0 0;
        }
        @include breakpoint($sm) {
            select,
            input[type='tel'] {
                min-width: calc(33% - 12px);
            }
            .between {
                margin: 0 1px 0 0;
            }
        }
    }
    .inp-box {
        display: inline-block;
        margin-left: 20px;
        label{
            display: inline-block;
            font-weight: 500;
        }
        input {
            width: 200px;
            margin-left: 10px;
        }
        @include breakpoint($md) {
            width: 100%;
            margin-top: 5px;
            margin-left: 0;
            input {
                width: calc(100% - 74px);
            }
        }
    }
    &__select{
        min-width: 190px;
        @include breakpoint($sm){
            min-width: 100%;
        }
    }
    .select-box{
        display: inline-block;
        .select-box__select{
            min-width: 300px;
        }
        @include breakpoint($md) {
            width: 100%;
            margin-top: 5px;
            .select-box__select{
                min-width: calc(100% - 77px);
            }
            .btn{min-width: 70px;}
        }
    }
    .select-list{
        margin-top: 10px;
        p{margin-top: 10px;}
    }
    .editor{
        display: block;
        width: 100%;
        margin-top: 18px;
        .editor-content{
            height: 231px;
            border: 1px solid $bd-color;
        }
    }
    &__view{
        width: 100%;
        border-top: 1px solid #000;
        border-bottom: 1px solid #ccc; 
        col.th {
            width: 180px
        }
        th {
            background-color: #f8f8f8;
            font-weight: 700;
            padding: 23px 18px;
            text-align: left;
            font-size: $body-font-size;
            vertical-align: top;
        }
        td {
            font-weight: 400;
            padding: 21px 19px;
        }
        tr+tr th,
        tr+tr td {
            border-top: 1px solid #ccc;
        }
        .txt-box {
            height: 137px;
            overflow-y: scroll;
            word-break: break-all;
        }
        .check-select li,
        .radio-select li {
            display: inline-block;
            margin-right: 15px;
        } 
        @include breakpoint($sm) {
            th {
                font-size: $html-font-size;
                padding: 23px 10px;
            }    
            td {
                padding: 21px 10px;
            }
        }
    }
}
.filedownload {
    padding-left: 27px;
    text-indent: -15px;
    &:hover {
        text-decoration: none;
    }
    span {
        padding-bottom: 1px;
        box-shadow: inset 0 -1px 0 $black;
        word-break: break-all;
    }
    &::before {
        content: '';
        @include la;
        font-size: 25px;
        margin-right: 5px;
        vertical-align: middle;
    }
}
.fileview {
    padding-left: 27px;
    text-indent: -15px;
    &:hover {
        text-decoration: none;
    }
    span {
        padding-bottom: 1px;
        box-shadow: inset 0 -1px 0 $black;
        word-break: break-all;
    }
    &::before {
        content: ' ';
        @include la;
        font-size: 25px;
        margin-right: 5px;
        vertical-align: middle;
    }
}
.refer {
    color: #666666;
    font-weight: 400;
    position: relative;
    padding-left: 20px;

    &::before {
        content: '※';
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
    }
}
// 필수입력
.required-text {
    color: #e24d4d;
    font-size: $body-font-size;
    font-weight: 400;
    margin-bottom: 10px;
    text-align: right;
    margin-top: -38px;
    @include breakpoint($sm){
        margin-top: 0;
        font-size: 14px;
    }
}
.required::before {
    content: '*';
    color: rgb(226, 77, 77);
    display: inline-block;
    width: 10px;
}
//접수확인
.reg-confirm{
    max-width: 628px;
    margin: 20px auto 0;    
    text-align: center;
    &__inner{
        padding-top: 40px;
        text-align: left;
        display: flex;
        justify-content: space-between;
    }
    h3 {
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 30px;
        letter-spacing: -1.5px;
    }
    &__input {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 180px);
        p+p {
            margin-top: 10px;
        }
        label {
            display: inline-block;
            width: 142px;
            font-size: $body-font-size;
            color: $black;
            font-weight: 700;
        }
        input {
            text-indent: 10px;
            padding: 0;
        }
        .radio-select,
        input.input-lg {
            display: inline-block;
            width: calc(100% - 148px);
        }
        .radio-select label {
            width: auto !important;
            display: inline-block;
            margin-right: 10px;
        }
        select,
        .input-sm {
            min-width: 80px;
            max-width: 82px;
        }
        .between {
            padding:0px 11px 0 5px;
        }
    }
    &__btn {
        width: 170px;
        border-radius: 5px;
        background-color: rgb(34, 34, 34);
        color: #fff;
        font-size: 20px;
        &:focus {
            outline: 1px dashed #fff;
            outline-offset: -3px;
        }
    }
    .refer{
        margin-top: 30px;
        text-align: left;
        color: #333333;
    }
    @include breakpoint($sm){
        &__inner {
            display: block;
            padding-top: 20px;
        }
        &__input {
            width: 100%;
            label {
                margin-bottom: 10px;
            }
            input.input-lg {
                width: 100%;
            }
        }
        &__btn{
            width: 100%;
            height: 60px;
            margin-top: 20px;
        }
    }
}