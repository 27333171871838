.tab-list{
	position: relative;
	margin-bottom: 40px;
	margin-top: -2px;
	ul{
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		li{
			flex:auto;
			a,
			button{
				width: 100%;
				position: relative;
				color: #666666;
				font-size: 16px;
				font-weight: 400;
				line-height: 1.3;
				padding: 20px 0px;
				text-align: center;
				&::before{
					content: '';
					width: 100%;
					height: 100%;
					border: 1px solid #666666;
					position: absolute;
					top: 0;
					left: 0;
				}
				&.on,
				&[aria-selected="true"]{
					color: #222222;
					font-weight: 500;
					&::before {
						border-bottom: none;
						border-width: 2px;
						border-color: #222222;
					}
					&::after {
						content: '';
						position: absolute;
						top: 2px;
						left: 50%;
						transform: translateX(-50%);
						border-top: 7px solid #222;
						border-left: 5px solid transparent;
						border-right: 5px solid transparent;
					}
					span{
						display: inline-block;
						position: relative;
						&::after {
							content: '';
							position: absolute;
							bottom: -7px;
							left: 0;
							height: 1px;
							width: 100%;
							background-color: #222;
						}
					}
				}
			}
		}
	}
	&.select {
		display: none;
	}
	@include breakpoint($sm){
		display: none;
		&.select{
			display: block;
			margin-top: 20px;
			margin-bottom: 30px;
			select {
				width: 100%;
				height: 52px;
				font-size: 18px;
				padding-left: 20px;
				padding-right: 40px;
				background-size: auto 8px;
				background-position: right 20px center;
			}
		}
	}
}
.tab-list2{
	margin: 30px 15%;
	&.lg{
		margin: 30px 5%;
	}
	ul{
		flex-wrap: wrap;
		justify-content: center;
		text-align: center;
		li{
			margin: 5px 10px;
			a,
			button{
				display: block;
				width: 216px;
				border-radius: 25px;
				border: 1px solid rgb(218, 218, 218);
				background-color: $white;
				padding: 10px 0 11px;
				span{
					color: rgb(34, 34, 34);
					font-size: $body-font-size;
					font-weight: 400;
				}
				&.on,
				&[aria-selected="true"],
				&:hover,
				&:active,
				&:focus {
					border: 1px solid rgb(0, 165, 137);
					span {
						font-weight: 500;
						color: $point;
						position: relative;
						padding-bottom: 2px;
						&::after{
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							height: 1px;
							background-color: $point;
							content: '';
						}
					}
				}
			}
		}
	}
	&.select {
		display: none;
	}
	@include breakpoint($sm) {
		display: none;
		&.select {
			display: block;
			margin-top: 20px;
			margin-bottom: 30px;
			margin-left: 0;
			margin-right: 0;
			select {
				width: 100%;
			}
		}
	}
}
// 5뎁스
.tab-nav2{
	margin: 30px 0 36px;
	a{
		text-align: center;
		padding: 15px 0;
		font-size: 16px;
	}
	ul{
		display: flex;
		flex-flow: row wrap;
		justify-content: left;
		border:1px solid $bd-color;
		li{
			text-align: center;
			flex:1;
			margin-right: -1px;
			&.on>a::after{
				content:'';
				display: inline-block;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-4px, 0);
				@include arr-down(8px);
				color: $point;
			}
			a{
				position: relative;
				display:block;
				text-align: center;
				line-height: 19px;
				background-position: 100% 50%;
				@include hover-line(100%, -6px, 0, 2px);
				@include breakpoint($xl){
					font-size: 1rem;
					padding: 5px 10px 6px;
				}
				@include breakpoint($mdf){
					font-size: 0.875rem;
					padding: 5px 10px 6px;
				}
				@include breakpoint($sm){
					display: block;
				}
			}
		}
	}
}
// tablist
.tablist {
	position: relative;
	margin-bottom: 40px;
	&.mt {
		margin-top: -33px;
	}
	ul {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		li {
			flex: auto;
			position: relative;
			background-color: $white;
			&::before {
				content: '';
				width: 100%;
				height: 100%;
				border: 1px solid #666666;
				position: absolute;
				top: 0;
				left: 0;
			}
			a {
				position: relative;
				width: 100%;
				color: #666666;
				font-size: 16px;
				font-weight: 400;
				line-height: 1.3;
				padding: 20px 0px;
				text-align: center;
			}
			&.on {
				&::before {
					border-bottom: none;
					border-width: 2px;
					border-color: #222222;
				}
				&::after {
					content: '';
					position: absolute;
					top: 2px;
					left: 50%;
					transform: translateX(-50%);
					border-top: 7px solid #222;
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
				}
				a {
					color: #222222;
					font-weight: 500;
					span {
						display: inline-block;
						position: relative;
						&::after {
							content: '';
							position: absolute;
							bottom: -7px;
							left: 0;
							height: 1px;
							width: 100%;
							background-color: #222;
						}
					}
				}
			}
		}
	}
	span.selected {
		display: none;
	}
	@include breakpoint($sm) {
		span.selected {
			@include ellipsis;
			display: block;
			height: 52px;
			padding: 12px 40px 12px 15px;
			font-size: $body-font-size;
		}
		display: inline-block;
		position: relative;
		z-index: 10;
		width: 100%;
		height: 52px;
		margin-bottom: 30px;
		border-radius: 5px;
		border: 1px solid #ccc;
		background-size: auto 8px;
		background-position: right 20px center;
		background-color: #fff;
		background-repeat: no-repeat;
		background-image: url(/static/kogl/img/common/select-arr.png);
		ul {
			background-color: #fff;
			border: 1px solid #000;
			z-index: 30;
			position: absolute;
			top: 52px;
			left: 0;
			display: none;
			width: 100%;
			padding-left: 5px;
			padding-right: 5px;
			&.show {
				display: block;
			}
			li {
				&::before {
					content: none;
				}
				&.on,
				&[aria-selected="true"] {
					display: block;
					&::before,
					&::after {
						content: none;
					}
					a {
						span {
							&::after {
								content: none;
							}
						}
					}
				}
				span {
					text-align: left;
					font-size: 15px;
				}
				a {
					text-align: left;
					padding: 4px 0;
				}
			}
		}
	}
}