@import url('/static/commons/css/common.css');
@import url('/static/kogl_alright/css/default.css');
// 플러그인
@import url('/static/jslibrary/bootstrap-datepicker-1.9.0-dist/css/bootstrap-datepicker3.standalone.css');
@import '../../commons/scss/breakpoint/breakpoint'; // 플러그인//반응형 플러그인
@import 'util'; //mixins
@import './mixins/layerfixed'; //mixins
@import './base/color'; // colors
@import './base/variables'; //환경 설정
@import './base/heading'; //해딩스타일
@import './base/lnb'; //서브 좌측 메뉴
@import './components/tab'; //탭메뉴
@import './components/ico'; // icons
@import './components/sh'; //검색
@import './components/bdlist';//프로그램  리스트 공통
@import './components/bdview';//프로그램 뷰,수정
@import './components/bdreg';//쓰기
@import './components/filelist';//파일목록
@import './components/photo';//겔러리 타입
@import './components/box'; //panel Box 
@import './components/table'; //테이블 스타일
@import './components/content'; //콘텐츠

/* 추가 스타일 */
.skip a {
    width: 30%;
}