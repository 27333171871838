.dl-list {
	dl {
		@include dlList;
	}
}
hr{
	border-bottom: 2px solid #dddddd;
	border-top-width: 0;
}
// 유의사항
.precautions {
	margin-top: 30px;
	font-size: $body-font-size;
	h3 {
		margin-bottom: 8px;
	}
	p {
		line-height: 1.67;
		color: #333333;
		font-weight: 400;
		letter-spacing: normal;
	}
	.title {
		display: block;
		margin: 14px 0 4px 0;
		font-weight: 700;
		letter-spacing: -0.45px;
	}
	.subTitle {
		margin-top: 19px;
	}
}
.help-warning {
	margin-top: 5px;
	font-size: $body-font-size;
	color: #222222;
	letter-spacing: -0.56px;
	text-indent: -18px;
	padding-left: 18px;
	&.light {
		font-weight: 300;
		font-size: 0.875rem;
		text-indent: 0;
		padding-left: 0;
		line-height: 1.79;
	}
	&::before {
		content: '※ ';
	}
}
.fwb {
	font-weight: 700 !important;
}
.radio-agree{
	margin-top: 20px;
}
//신탁저작물 이용신청>이용안내
.tit-point{
	font-size: 20px;
	color: $point;
	margin-top: 30px;
	margin-bottom: 10px;
	&+.box__bg{margin-top: 20px;}
}
.rulebox{
	.box__bg{
		text-align: center;
		font-size: $body-font-size;
		letter-spacing: -0.45px;
		line-height: 1.67;
		&+.table {
			margin-top: 20px;
		}
		@include breakpoint($sm){
			font-size: 16px;
		}
	}
	&__list{
		li{
			padding-left: 50px;
			position: relative;
			margin-top: 15px;
			line-height: 1.6;
			span{
				letter-spacing: -0.8px;
				position: absolute;
				left: 0px;
				top: 0px;
			}
		}
	}
}
// 신탁관리제도 소개
.txt {
	font-size: 20px;
	@include breakpoint($sm){
		font-size: 18px;
	}
}
.txt2 {
	font-size: 22px;
	@include breakpoint($sm){
		font-size: 20px;
	}
}
.alright-range{
	margin-top: 30px;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	>span{
		text-align: center;
		.title{
			font-size: 25px;
			font-weight: 700;
		}
		span{
			display: block;
			font-size: $body-font-size;
			font-weight: 500;
			line-height: 1.67;
			letter-spacing: -0.45px;
		}
	}
	//공공저작권 신탁계약 절차
	&.process{
		justify-content: center;
		margin-bottom: 30px;
		>span{
			flex-basis: 33%;
			position: relative;
			&+span{
				&::before{
					content: '';
					display: inline-block;
					position: absolute;
					top: 50px;
					left: 0;
					width: 15px;
					height: 26px;
					background: url(/static/kogl_alright/img/sub/icon-arr.png) 0 0 no-repeat;
				}
			}
		}
		@include breakpoint($mw){
			justify-content: space-around;
			margin-bottom: 80px;
			>span {
				flex-basis: 20%;
			}
		}
		@include breakpoint($md){
			>span{
				&+span {
					&::before {
						top: 37px;
						width: 10px;
						background-size: contain;
					}
				}
			}
		}
		@media (max-width: 500px) {
			border: 1px solid #ddd;
			padding: 20px 36px;
			>span {
				flex-basis:100%;
				&+span {
					padding-top: 40px;
					&::before {
						width: 25px;
						left: 50%;
						margin-left: -10px;
						top: 0;
						transform: rotate(90deg);
					}
				}
			}
			img{
				display: inline-block;
				vertical-align: top;
				& + span{
					text-align: left;
					width: calc(100% - 103px);
					padding-left: 20px;
					display: inline-block;
				}
			}
		}
		@media (max-width: 375px) {
			padding: 20px 10px;
			img{
				&+span {padding-left: 10px;}
			}
		}
	}
	@include breakpoint($md){
		margin-top: 0;
		justify-content: left;
		>span{
			flex-basis: 25%;
			margin-bottom: 10px;
			img{width: 83px;}
			.title{
				font-size: 18px;
			}
			span{font-size: 16px;}
		}
	}
	@media (max-width: 500px) {
		>span{
			flex-basis: 33%;
			.title {
				font-size: 16px;
			}
			span {
				font-size: 14px;
			}
		}
	}
	@include breakpoint($xs) {
		>span{
			flex-basis: 50%;
		}
	}
}
.alright-range-swiper{
	@media (max-width: 500px) {
		position: relative;
		margin: 30px 0;
	}
	.alright-range {
		&__button-next,
		&__button-prev,
		&__pagination {display: none;}
		@include breakpoint($md){
			>span{
				flex-basis: 33%;
				.title {
					font-size: 25px;
				}
				span {
					font-size: 18px;
				}
			}
		}
		@media (max-width: 500px){
			flex-wrap: nowrap;
			>span{
				flex-basis: 100%;
			}
			&__pagination{
				display: block;
				margin-top: 20px;
				text-align: center;
				.swiper-pagination-bullet {
					display: inline-block;
					vertical-align: middle;
					width: 22px;
					height: 22px;
					border: 6px solid #222;
					background: #fff;
					opacity: 1;
					&.swiper-pagination-bullet-active {
						width: 26px;
						height: 26px;
						background-color: #222222;
					}
				}
			}
			&__button-next,
			&__button-prev{
				display: block;
				position: absolute;
				top: 50%;
				margin-top: -65px;
				z-index: 10;
				&::before {
					content: '';
					@include la;
					color: #222;
					font-size: 50px;
				}
			}
			&__button-next{
				right: 0;
			}
			&__button-prev{
				left: 0;
				transform: scaleX(-1);
			}

		}
	}
}
.alright-effect{
	.box__bg{
		padding: 30px 10px;
		text-align: center;
		span{
			display: block;
			margin-top: 15px;
			font-size: $body-font-size;
			font-weight: 400;
			line-height: 1.67;
			letter-spacing: -0.45px;
			&.point{
				font-size: 22px;
				font-weight: 700;
				line-height: 1.36;
				letter-spacing: -0.55px;
			}
			&.sub{
				font-size: 20px;
				font-weight: 700;
				line-height: 1.5;
				letter-spacing: -0.5px;
			}
			@media (max-width: 1440px){
				font-size: $html-font-size;
				margin-top: 0;
				&.point{font-size: 18px;}
				&.sub{
					margin-top: 5px;
					font-size: 16px;
					height: 48px;
				}
			}
			@media (max-width: 740px) {
				margin-top: 15px;
				&.point {
					font-size: 20px;
				}
				&.sub{
					font-size: 18px;
					height: auto;
				}
			}
		}
		@include breakpoint($md){
			padding: 20px 10px;
			img{width: 95px;}
			span{
				br{
					display: none;
				}
			}
		}
		@media (max-width: 740px) {
			span {
				br {
					display: block;
				}
			}
		}
	}
	&__pagination{
		display: none;
	}
	@media (max-width: 740px){
		.col-sm-4{
			padding-left: 0;
			padding-right: 0;
		}
		&__pagination {
			display: block;
			text-align: center;
			margin-top: 20px;
			.swiper-pagination-bullet {
				display: inline-block;
				vertical-align: middle;
				width: 22px;
				height: 22px;
				border: 6px solid $point;
				background: #fff;
				opacity: 1;
				box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.35);
				&.swiper-pagination-bullet-active {
					width: 26px;
					height: 26px;
					background-color: $point;
					border-width: 0;
				}
			}
		}
	}
}
//ALRIGHT BI
.bi-box{
	display: flex;
	align-items: center;
	>div:nth-child(2){
		border-left: 1px solid #ddd;
		padding-left: 30px;
	}
	&__img{
		text-align: center;
	}
	&__inner{
		.bu2{
			color: $point;
			font-size: 22px;
			&::before{
				top: 15px;
				background-color: $point;
			}
		}
		.txt{font-size: 18px;}
		@include breakpoint($sm){
			.bu2{
				font-size: 18px;
				padding-bottom: 10px;
				&::before{top: 12px;}
			}
			.txt{font-size: 16px;}
		}
	}
	@include breakpoint($md) {
		display: block;
		.col-sm-6{
			width: 100%;
			&:nth-child(2) {
				border-left-width: 0;
				padding-left: 0;
			}
		}
	}
}
.colorsystem {
	margin-top: 20px;
	tr{
		th{
			vertical-align: middle;
			padding: 8px 5px;
			font-weight: 600;
			color: $point;
			font-size: 13px;
			strong{
				width: 100%;
				display: inline-block;
				font-weight: 400;
				padding: 2px 0px;
				color: #fff;
				&.org{
					background: #e65a1d;
				}
				&.gray{
					background: #9a9da0;
				}
				&.silver{
					background: #acacac;
				}
				&.gold{
					background: #b79f57;
				}
				&.black{
					background: #040301;
				}
			}
			&:last-child {
				border-right: none;
			}
		}
		td{
			vertical-align: middle;
			border-bottom: dotted 1px #999999;
			border-right: dotted 1px #999999;
			padding: 8px 10px;
			text-align: center;
			font-size: 13px;
			width: 110px;
			&:last-child {
				border-right: none;
			}
		}
	}
	thead{
		tr{
			th{
				vertical-align: middle;
				border-bottom: dotted 1px #999999;
				border-right: dotted 1px #999999;
				padding: 8px 10px;
				text-align: center;
				font-size: 13px;
				width: 110px;
				&:first-child {
					vertical-align: middle;
					padding: 8px 5px;
					font-weight: 600;
					color: $point;
					font-size: 13px;
					text-align: left;
					border-bottom: 0;
					border-right: 0;
				}
				strong {
					font-weight: 600;
					color: #000;
				}
			}
		}
	}
	@media (max-width:1400px) {
		display: none;
	}
	&-mview {
		display: none;
		@media (max-width:1400px) {
			display: block;
			margin-bottom: 20px;
			h4{
				color: $point;
				margin-bottom: 20px
			}
			tr{
				th{
					vertical-align: middle;
					padding: 8px 5px;
					line-height: 18px;
					font-weight: 600;
					text-align: center;
					color: #222222;
					font-size: 13px;
					border-right: dashed 1px #999999;
					border-bottom: dashed 1px #999999;
					&:last-child {
						border-right: none;
					}
				}
				td{
					vertical-align: middle;
					border-bottom: dashed 1px #999999;
					border-right: dashed 1px #999999;
					padding: 3px 3px;
					text-align: center;
					font-size: 13px;
					width: auto;
					&:last-child {
						border-right: none;
					}
				}
			}
			strong{
				display: inline-block;
				width: 100%;
				color: #fff;
				font-weight: 400;
				padding: 1px 0px;
				font-size: 15px;
				text-align: center;
				&.org{
					background: #e65a1d;
				}
				&.gray{background: #9a9da0;}
				&.silver{background: #acacac;}
				&.gold{background: #b79f57;}
				&.black{background: #040301;}
			}
		}
	}
}
.ci-imglogo {
	margin-top: 20px;
	strong {
		color: $point;
		font-size: 13px;
		display: block;
		margin-bottom: 30px;
	}
	p {
		float: left;
		color: #222222;
		font-size: 13px;
		font-weight: 600;
		margin-right: 50px;
	}
}
// 기관가입 안내
.alright-process-detail{
	@include breakpoint($mw) {
		margin-bottom: 80px;
	}
	&__item{
		display: flex;
		padding-bottom: 20px;
		&+.alright-process-detail__item{
			padding-top: 37px;
			position: relative;
			&::before{
				content: '';
				position: absolute;
				top: 0;
				left: 150px;
				width: 25px;
				height: 15px;
				background: url(/static/kogl_alright/img/sub/icon-arr2.png) 0 0 no-repeat;
			}
		}
		dl{
			border-radius: 10px;
		}
		dt{
			border-radius: 10px 10px 0 0;
			font-size: 22px;
			font-weight: 500;
			height: 50px;
			padding: 8px 0;
		}
		.col-process1{
			width: 360px;
			float: left;
			display: grid;
			padding-right: 20px;
			dl{
				display: grid;
				border: 1px solid $point;
				text-align: center;
			}
			dt{
				background-color: $point;
				color: $white;
			}
			dd{
				font-size: 20px;
				letter-spacing: -0.5px;
			}
		}
		.col-process2{
			width: calc(100% - 360px);
			float: left;
			padding-left: 20px;
			dl {
				border: 1px solid #ddd;
			}
			dt{
				text-align: center;
				background-color: #fef7f3;
				color: $point;
			}
			dd{
				width: 60%;
				margin: 25px auto;
				ul{
					text-align: left;
				}
				li{
					font-size: $html-font-size;
				}
			}
		}
		@include breakpoint($xl){
			.col-process1{
				width: 30%;
				padding-right: 10px;
			}
			.col-process2{
				width: 70%;
				padding-left: 10px;
				dd{
					width: 90%;
					margin: 15px auto;
				}
			}
		}
		@include breakpoint($sm){
			flex-wrap: wrap;
			padding-bottom: 15px;
			margin-left: 0;
			margin-right: 0;
			&+.alright-process-detail__item {
				padding-top: 27px;
				&::before {
					left: 50%;
					transform: translateX(-50%);
				}
			}
			.col-process1{
				width: 100%;
				padding-right: 0;
				padding-bottom: 10px;
				dd{padding: 10px 0;}
			}
			.col-process2{
				width: 100%;
				padding-left: 0;
				dt{
					font-size: $body-font-size;
				}
			}
		}
	}
}
.box-list{
	ul{
		@include clearfix;
	}
	li{
		float: left;
		width: calc(100% / 4);
		height: 200px;
		text-align: center;
		margin-bottom: 30px;
		a,
		span{
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: calc(100% - 40px);
			height: 100%;
			border: 1px solid #ddd;
			padding: 0 10px;
		}
		@include breakpoint($md){
			width: calc(100% / 3);
			height: 150px;
			a,
			span{
				width: calc(100% - 20px);
			}
		}
		@include breakpoint($sm){
			width: calc(100% / 2);
			height: 120px;
		}
		@include breakpoint($xs){
			width:100%;
			margin-bottom: 10px;
			a,
			span{
				width: 100%;
			}
		}
	}
}
//신탁저작물 이용 절차
.process-box{
	ul{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		@include breakpoint($mw){
			justify-content: space-between;
			flex-wrap: nowrap;
		}
	}
	li{
		flex-basis: calc(100% / 3 - 40px);
		margin-bottom: 40px;
		@include breakpoint($mw) {
			flex-basis: 20%;
		}
		& + li{
			margin-left: 40px;
			position: relative;
			&::before{
				content: '';
				width: 26px;
				height: 27px;
				background: url(/static/kogl_alright/img/sub/icon-circle-arrow.png) 0 0 no-repeat;
				position: absolute;
				left: -34px;
				top: 50%;
				margin-top: -10px;
			}
		}
		>span{
			display: block;
			text-align: center;
			height: 180px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			border: 1px solid $point;
			border-radius: 0 0 10px 10px;
			&.title{
				border-radius: 10px 10px 0 0;
				background-color: $point;
				color: $white;
				height: 100px;
				font-size: 20px;
				letter-spacing: -0.55px;
				font-weight: 500;
				@include breakpoint($mw) {
					font-size: 22px;
				}
			}
			.p{
				margin-top: 20px;
				font-size: 18px;
				font-weight: 700;
				line-height: 1.5;
				letter-spacing: -0.5px;
				@include breakpoint($mw) {
					font-size: 20px;
				}
			}
		}
		@include breakpoint($sm){
			flex-basis:100%;
			&+li {
				margin-left: 0;
				&::before {
					top: -34px;
					margin-top: 0;
					left: 50%;
					margin-left: -15px;
					transform: rotate(90deg);
				}
			}
			>span{
				height: 150px;
				&.title{
					height: 50px;
					br{display: none;}
				}
			}
		}
	}
}
.ul-txt{
	li{
		font-size: 20px;
		@include breakpoint($sm) {
			font-size: 18px;
		}
	}
	>li{
		padding-left: 20px;
		text-indent: -20px;
		margin: 5px 0;
		word-break: break-all;
		>ul {
			margin-left: 20px;
		}
	}
	.info-view{
		padding-left: 0;
		text-indent: 0;
		margin: 10px 0;
	}
}
.info-view{
	border-top: solid 2px #000000;
	dl{
		border-bottom: 1px solid #ddd;
		display: table;
		table-layout: fixed;
		width: 100%;
		font-size: 18px;
	}
	dt{
		display: inline-block;
		width: 30%;
		padding: 10px 0 10px 40px;
	}
	dd{
		display: inline-block;
		vertical-align: top;
		width: 70%;
		color: $point;
		padding: 10px 0 10px 20px;
	}
	@include breakpoint($sm){
		dl{
			font-size: 16px;
		}
		dt,
		dd{
			padding: 5px;
			width: 100%;
		}
	}
}
.erro {
	margin: 80px auto;
	text-align: center;
	background: url(/static/commons/img/icon_nopage.png) no-repeat center top;
	padding-top: 100px;
	.text1 {
		font-size: 22px;
		font-weight: 500;
	}
	.text2 {
		margin-top: 20px;
		color: #767676;
		font-size: 18px;
		margin-top: 20px;
	}
}