@mixin bu1() {
	position: relative;
	padding-left: 25px;
	padding-bottom: 10px;
	font-size: 28px;
	font-weight: 700;
	letter-spacing: -0.55px;
	border-bottom: 2px solid #dddddd;
	&::before {
		content: '';
		width: 3px;
		height: 18px;
		background-color: $point;
		position: absolute;
		top: 13px;
		left: 0;
	}
	&.bdnone{
		border-bottom-width: 0px
	}
	&.bdbcolor{
		border-bottom-color: #666;
	}
	@include breakpoint($sm){
		font-size: 20px;
		&::before {top: 6px;}
	}
}
@mixin bu2() {
	position: relative;
	padding-left: 22px;
	padding-bottom: 20px;
	color: $black;
	font-size: 20px;
	font-weight: 700;
	&::before {
		content: '';
		width: 5px;
		height: 5px;
		background-color: $black;
		border-radius: 2px;
		position: absolute;
		top: 12px;
		left: 0;
	}
}
@mixin bu3() {
	position: relative;
	padding-left: 10px;
	font-size: 16px;
	font-weight: 400;
	&::before {
		position: absolute;
		top: 0;
		left: 0;
		content: "- ";
	}
}
// 콘텐츠 불릿
#content > h2,
.hx > h2,
.bu1 {
	@include bu1;
	margin-bottom: 10px;
	> span {
		font-size: $html-font-size;
		font-weight: normal;
		font-family: $font-set;
	}
	> a {
		font-weight: normal;
	}
	& + h3 {
		// margin-top: 14px;
	}
	&.no-bull::before {
		display: none;
	}
}
/* 2 */
#content > h3,
.hx > h3,
.bu2 {
	@include bu2;
	.row & {
		margin-top: 0;
	}
	> span {
		font-size: $html-font-size;
		font-weight: normal;
		font-family: $font-set;
	}
	&.point {
		color: $point;
	}
	&.bu-icon{
		&::before{content: none;}
		font-size: 28px;
		font-weight: 500;
		padding-left: 24px;
		margin-top: 60px;
		@include breakpoint($sm) {
			font-size: 20px;
			background-position: 0 8px;
			margin-top: 30px;
		}
	}
}
.bu-icon {
	background-image: url(/static/kogl_alright/img/sub/bu-icon.png);
	background-repeat: no-repeat;
	background-position: 0 15px;
	line-height: 1.43;
	letter-spacing: -0.7px;
	font-size: 28px;
	font-weight: 500;
	padding-left: 24px;
	margin-top: 60px;
	margin-bottom: 20px;
	@include breakpoint($sm) {
		font-size: 22px;
		background-position: 0 10px;
		margin-top: 30px;
		margin-bottom: 10px;
	}
}
p.bu2{
	color: #333;
	font-weight: 400;
	font-size: $body-font-size;
}
/* 3 */
#content > h4,
.hx > h4,
.bu3 {
	@include bu3;
	& + .table {
		margin-top: 0;
	}
}
/* 4 */
/* #content h5,
.bu4 {
	@include bu4;
} */
/* 5 */
#content h6 {
	font-size: 1em;
	padding-left: 38px;
}
// 리스트타입
.list {
	> li {
		@include bu2;
		font-weight: 400;
		padding-bottom: 0;
		font-size: 20px;
		/* > strong {
			color: $point;
		} */
		&.mt{margin-top: 40px;}
		@include breakpoint($sm){
			font-size: 18px;
			&.mt{margin-top: 10px;}
		}
		>em{font-weight: 700;}
		> ul {
			margin-top: 5px;
			> li {
				@include bu3;
				margin-left: 0;
				font-size: $body-font-size;
				@include breakpoint($sm) {
					font-size: $html-font-size;
				}
				> ul,
				> ol {
					li {	
						text-indent: -12px;
						padding-left: 12px;
						&:before {
							content: "· ";
							display: inline;
						}
					}
				}
			}
			&.none {
				li {
					&:before {
						display: none;
					}
				}
			}
			&.number {
				li {
					text-indent: -20px;
					padding-left: 20px;
					&:before {
						display: none;
					}
				}
			}
		}
	}
	> li + li {
		margin-top: 5px;
	}
	&.sm{
		margin-bottom: 40px;
		@include breakpoint($mw) {
			margin-bottom: 80px;
		}
		>li{
			font-size: 16px;
			&::before{
				top: 10px;
			}
			@include breakpoint($sm) {
				font-size: 16px;
			}
		}
	}
}
.list-bu3 > li {
	@include bu3;
}
$bu: '[class^=bu-]';
#{$bu} {
	&::before {
		@include la;
		font-size: 30px;
		vertical-align: middle;
		padding-bottom: 3px;
		padding-right: 10px;
	}
	&.bu-precaution {
		border-bottom: 1px solid #ddd;
		font-size: 20px;
		padding-bottom: 5px;
		margin-bottom: 10px;
		color: $point;
		&::before {
			content: '';
			color: $point;
		}
	}
	&.bu-way {
		border-bottom: 1px solid #ddd;
		font-size: 20px;
		padding-bottom: 5px;
		margin-bottom: 10px;
		&::before {
			content: '';
		}
	}
	&.bu-info {
		border-bottom: 1px solid #ddd;
		font-size: 20px;
		padding-bottom: 5px;
		margin-bottom: 10px;
		&::before {
			content: '';
		}
	}
}