.table-rt-deco {
	text-align: right;
	margin-bottom: 11px;
}
dl.table {
	border: 1px solid $bd-color;
	border-top: 2px solid $bd-top-color;
	margin-bottom: 7px;
	dt {
		background: $th-bg-color;
		padding: 10px;
		border-bottom: 1px solid $bd-color;
		font-weight: bold;
		color: #000;
	}
	dd {
		text-align: left;
		padding: 10px;
	}
}
table {
	word-break: break-all;
	width: 100%;
	tr.unlock {
		background-color: $gray;
	}
	td {
		word-break: keep-all;
	}
	th {
		font-size: $body-font-size;
	}
	td {
		font-size: 1rem;
	}
	@include breakpoint($sm) {
		th {
			font-size: 15px;
		}
		td {
			font-size: 14px;
		}
	}
}
.table {
	position: relative;
	border-top: 2px solid $bd-top-color;
	border-bottom: 1px solid $bd-color;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 30px;
	@include breakpoint($sm) {
		&::before {
			content: "";
			height: 100%;
			position: absolute;
			right: 0;
			width: 1px;
			background-color: #ffffff;
		}
		&.ovx::before {
			left: 749px;
		}
	}
	table {
		width: 100%;
	}
	&.fontxs {
		td,
		th {
			font-size: 14px;
			padding: 5px;
		}
	}
	&.bd-style{
		tr{
			th,
			td {
				border: 1px solid $bd-color;
				border-width: 1px 1px 0 0;
				@include breakpoint($sm){
					word-break: initial;
				}
			}
		}
	}
	tr {
		th:last-child {
			border-right-width: 0;
		}
		td:last-child {
			border-right-width: 0;
			&[rowspan] {
				border-left-width: 1px;
			}
		}
	}
	th {
		font-weight: 600;
		line-height: 24px;
		font-size: $body-font-size;
		padding: 13px 0;
		text-align: center;
		color: #222;
		background: $th-bg-color;
		border: 0;
		&.bgnone {
			background: transparent;
		}
	}
	tbody {
		tr {
			/* &:hover,
			&:active,
			&:focus {
				th,
				td {
					background-color: $th-bg-color;
				}
			} */
		}
		th,
		td {
			border-width: 1px 0 0 0;
			border-color: $bd-color;
			border-style: solid;
			&:last-child[rowspan] {
				// border-left-width: 1px;
			}
			padding: 5px;
			@include breakpoint($lg) {
				padding: 17px 10px;
			}
		}
		th {
			background: $th-bg-color;
		}
	}
	&.ovx {
		@include breakpoint($sm) {
			overflow-x: auto;
			position: relative;
			&::before {
				content: "";
				position: absolute;
				right: 0;
				width: 1px;
				height: 100%;
				background-color: #fff;
			}
			table {
				width: 750px;
			}
		}
	}
	&.fix table {
		table-layout: fixed;
	}
	td.nodata {
		padding: 60px 0;
	}
	td.title {
		text-align: left;
	}
	.table-result-deco {
		padding: 20px 0;
		border-top: 1px solid $bd-color;
		text-align: center;
		.date {
			font-size: 14px;
		}
		.name {
			color: #000;
			font-size: 20px;
		}
	}
	&.style {
		@include breakpoint($mw) {
			margin-bottom: 80px;
		}
		border-top-width: 0;
		border-bottom-width: 0;
		th[scope="row"]{
			background-color: $point;
			color: $white;
			font-size: 22px;
			width: 126px;
			border-top: 1px solid $point;
			@include breakpoint($sm){
				font-size: 18px;
				width: 90px;
				padding:10px 0;
			}
		}
		th[scope="col"]{
			border-top: 1px solid $point;
			border-bottom: 1px solid $point;
			background-color: #fef7f3;
			color: $point;
			font-size: 22px;
			&:last-child{
				border-right: 1px solid $point;
			}
			& +th[scope="col"]{
				position: relative;
				&::before{
					content: '';
					position: absolute;
					top: 15px;
					left: 0;
					width: 1px;
					height: 20px;
					background-color: $point;
				}
			}
			@include breakpoint($sm){
				font-size: 18px;
				padding:10px 0;
			}
		}
		tbody{
			th{
				border-width: 0;
				&[scope="row"]{
					position: relative;
					&::before {
						content: '';
						position: absolute;
						top: 0;
						left: 50%;
						transform: translateX(-50%);
						width: calc(100% - 18px  - 18px);
						height: 1px;
						background-color: $white;
					}
				}
			}
			td{
				border-width: 0 1px 1px 0;
				font-size: 16px;
				padding-left:30px;
				p{
					font-size: 16px;
					&.bu2{
						padding-left: 15px;
						&::before{
							top: 10px;
						}
					}
				}
				@include breakpoint($sm){
					padding-left:10px;
				}
			}
		}
	}
	@include breakpoint($sm) {
		th {
			font-size: 15px;
			padding: 5px 0;
		}
	}
}
.table-row {
	.row-th {
		border-right: 1px solid $bd-color;
		@include breakpoint($sm) {
			display: block;
		}
	}
	th {
		width: 16.67%;
		vertical-align: middle;
		background-color: $th-bg-color;
		border-bottom: 1px solid $bd-color;
	}
	td {
		padding: 15px 10px 15px 20px;
		height: 55px;
		border-bottom: 1px solid $bd-color;
		@include breakpoint($sm) {
			padding: 5px;
		}
	}
}
.table.type-th-multi {
	thead {
		tr + tr th {
			border-top: 1px solid $bd-color;
		}
	}
}
.table.td-vat {
	td {
		vertical-align: top;
	}
}
.table.padding-sm {
	td {
		padding: 10px 5px;
	}
}
.td-tal table tbody td {
	text-align: left;
}
.td-tac table tbody td {
	text-align: center;
}
.td-vt table tbody td {
	vertical-align: top;
}
td.bdl-none {
	border-left: 0 !important;
}
td.bdr-none {
	border-right: 0 !important;
}
