[class^='icon-'],
.icon {
	display: inline-block;
	vertical-align: middle;
}
.icon-complete{
	width: 120px;
	height: 120px;
	background: url(/static/kogl/img/sub/bg-confirm.png) center 0 no-repeat;
}
.icon-hwp{
	width: 26px;height: 26px;
	background: url(/static/commons/img/icon-hwp.png) 0 0 no-repeat;
}
.icon-down{
	width: 30px;
	height: 30px;
	background: url(/static/kogl_alright/img/common/icon-down.png) 0 0 no-repeat;
}
