.pswp[role="dialog"] {
	transform: translate(0, 0);
	z-index: 20000;
}
.photo-list {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
	justify-items: center;
	margin: 30px 0;
	gap: 20px;
	&.floating{
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		margin-left: 0;
		margin-right: 0;
	}
	&__item{
		width: 240px;
		.floating &{
			width: auto;
			opacity: 0;
			transition: all 0.5s;
			&.loadcomplete{opacity: 1;}
		}
		@include breakpoint($md){
			width: 100%;
		}
	}
	// floating
	&__img {
		position: relative;
		// overflow: hidden;
		width: 100%;
		height: 100%;
		border: 1px solid #dddddd;
		text-align: center;
		.img {
			display: block;
			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				vertical-align: top;
			}
		}
	}
	&__inner{
		position: relative;
		// height: 240px;
	}
	&__button{
		z-index: 10;
		position: absolute;
		left: 0; top:0;
		width: 100%;
		height: 50px;
		[class^=dialogOpencode] {
			position: absolute;
			top: 10px;
			left: 10px;
		}
	}
	&__thumnail{
		display: inline-block;
		width: 100%;
		height: 100%;
		border: 1px solid #dddddd;
		@include aspectRatio(240, 240);
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		// a{display:block}
		// 재생 아이콘
		&.play{
			&::after{
				content: '';
				position: absolute;
				top: 50%;left: 50%;
				transform: translate(-50%, -50%);
				width: 58px;
				height: 58px;
				background: url(/static/kogl/img/common/bg-videoplay.png) no-repeat;
			}
		}
	}
	&__bookmark{
		position: absolute;
		top: 13px;right: 11px;
		width: 28px;
		height: 28px;
		background-repeat: no-repeat;
		background-image: url(/static/kogl/img/sub/scrap_off-btn.png);
		background-size: contain;
		&.active {
			background-image: url(/static/kogl/img/sub/scrap_on-btn.png);
		}
	}
	&__layer{
		z-index: 20;
		display: none;
		flex-direction: column;
		justify-content: flex-end;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(34, 34, 34, 0.8);
		pointer-events: none;
		&.on{display: flex;}
		>div{z-index: 20;}
		&-top{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -60px);
			text-align: center;
			padding: 14px 20px;
			button,
			a{
				display: block;
				pointer-events: visible;
				min-width: 184px;
				height: 40px;
				border-radius: 3px;
				color: $black;				
				font-size: 18px;
				font-weight: 700;
				letter-spacing: -0.45px;
				&.b-preview,
				&.b-preview2 {
					padding-left: 45px;
					background: $white url(/static/kogl/img/common/icon-preview.png) 45px center no-repeat;
				}
				&.b-listen {
					padding-left: 45px;
					background: $white url(/static/kogl/img/common/icon-listen.png) 45px center no-repeat;
				}
				&.b-detailview {
					margin-top: 10px;
					padding-left: 45px;
					background: $white url(/static/kogl/img/common/icon-detailview.png) 35px center no-repeat;
				}
				&.b-link {
					background-color: $white;
					padding-left: 0;
					padding-right: 0;
					&::before {
						content: '';
						@include la;
						font-size: 22px;
						margin-right: 10px;
					}
					&::after{content: none;}
				}
				&:hover,&:active,&:focus{color: $black;}
			}
		}
		&-bottom{			
			border-top: 1px solid $white;
			text-align:center;
			margin: 0 20px;
			padding: 14px 0;
			span{
				color: $white;
				margin-left: 7px;
			}
			.ico-fav {
				display: inline-block;
				&.like-btn {
					background: url(/static/kogl/img/sub/like-btn-w.png) no-repeat;
					width: 23px;
					height: 23px;
				}
				&.view-btn {
					background: url(/static/kogl/img/sub/view-btn-w.png) no-repeat;
					width: 23px;
					height: 16px;
				}
				&.donwload-btn {
					background: url(/static/kogl/img/sub/donwload-btn-w.png) no-repeat;
					width: 22px;
					height: 22px;
				}
			}
		}
	}
	&__title{
		margin: 20px 0;
		a{
			width: 100%;
			color: #333333;
			font-size: 16px;
			font-weight: 500;
			text-align: left;
			letter-spacing: -0.4px;
			@include ellipsis;
		}
		.floating &{display: none;}	
	}
}
.photo-list-view {
	
}