
.box{
	border: 1px solid #dedede;
	background-color: $white;
	border-radius: 5px;
	.inner{
		padding: 30px;
		@include breakpoint($sm){
			padding: 20px;
		}
	}
	&.type2{
		border-radius: 10px;
		margin: 40px 0 80px;
		ul{
			li{
				line-height: 1.88;
				font-weight: 500;
				line-height: 1.88;
				letter-spacing: -0.8px;
			}
		}
		@include breakpoint($sm){
			margin: 20px 0 40px;
		}
	}
	&__scroll {
		overflow-y: scroll;
		margin: 10px;
		padding: 7px;
		color: $black;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.7;
		height: 180px;
		&.min{
			height: 76px;
		}
	}
	&__gray{
		background-color: rgba(0, 0, 0, 0.05);
	}
	&__bg{
		background-color: #fef7f4;
		padding: 30px 40px;
		.title{
			margin-top: 10px;
			margin-bottom: 5px;
			color: $point;
			font-size: 22px;
			font-weight: 700;
			& + ul{
				margin-bottom: 10px;
				li {
					font-size: $body-font-size;
				}
			} 
		}
		@include breakpoint($sm){
			padding: 20px 10px;
			.title{
				font-size: 18px;
				&+ul li {font-size: 16px;}
			}
		}
	}
	&__img{
		padding: 80px;
		@include breakpoint($sm){
			padding: 30px 20px;
		}
	}
	&__iconbox{
		border: 6px solid #f3f3f3;
		.inner{padding: 30px;}
		& + .box__iconbox{margin-top: 30px;}
		&-icon{
			display: inline-block;
			vertical-align: middle;
			width: 120px;
			height: 120px;
			background-repeat: no-repeat;
			background-position: 0 0;
			&.type1 {
				background-image: url(/static/kogl_alright/img/sub/box-icon01.png);
			}
			&.type2 {
				background-image: url(/static/kogl_alright/img/sub/box-icon02.png);
			}
		}
		&-content {
			display: inline-block;
			vertical-align: middle;
			width: calc(100% - 130px);
			padding-left: 15px;
			p{
				font-size: 20px;
				font-weight: 500;
			}
			.refer{
				color: #333;
				font-size: $html-font-size;
				font-weight: 400;
				margin-top: 10px;
			}
		}
		.inner{
			width: calc(100% - 160px - 160px);
			margin: 0 auto;
			@include breakpoint($xl) {
				width: 100%;
			}
			.b-btn{
				margin-top: 20px;
				min-width: 166px;
				max-width: 177px;
				min-height: 35px;
				padding: 8px 10px;
				font-size: $html-font-size;
				font-weight: 500;
				&::after{
					content: '';
					@include la;
					font-size: $body-font-size;
				}
				@include breakpoint($sm){
					min-width: 100%;
					margin-top: 10px;
				}
			}
		}
		@include breakpoint($sm){
			.inner {
				padding: 20px;
			}
			&+.box__iconbox {
				margin-top: 20px;
			}
			&-icon {display: none;}
			&-content {
				width: 100%;
				padding-left: 0;
				p {font-size: 18px;}
			}
		}
	}
}
